import { useForm } from '@pankod/refine-react-hook-form';
import axiosInstance from 'axiosInstance';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import FileUpload from 'components/FileUpload';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import InputErrorMessage from 'components/InputErrorMessage';
import MutationMessage from 'components/Messages/MutationMessage';
import { useEffect, useMemo, useRef, useState } from 'react';
import CategoryPill from 'components/CategoryPill';
import { CrossIcon , LoadingIcon } from 'components/icons';
import ItemsContainer from 'components/ItemsContainer';
import { useNavigation, useSelect } from '@pankod/refine-core';
import { AudioBook, AudioBookTopic, Guide, GuideModule, WebinarCategory } from 'interfaces/apiTypes';
import ThumbnailUpload from 'components/ThumbnailUpload';


export const CreateGuideModule: React.FC = () => {

  const { options } = useSelect({
    resource: 'guides',
    optionLabel: 'title',
    fetchSize: 100000000000000,
  });

  const {
    refineCore: { formLoading },
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();


  const [selectedGuideId, setSelectedGuideId] = useState<any>();
  
  const guideslistQuery = useQuery(['guides'], () =>
  axiosInstance.get<Guide[]>(`/v1/guides`)
  );
  const availableGuides = guideslistQuery?.data?.data;

  const guideModuleslistQuery = useQuery([selectedGuideId,'guideModules'], () =>
    axiosInstance.get<GuideModule[]>(`/v1/guideModules/${selectedGuideId}`), {
      enabled: !!selectedGuideId
    }
    );
    const availableGuideModules = guideModuleslistQuery.data?.data;
  
    let seqNums:Array<any> = [];
    availableGuideModules?.map((guideModule)=>{
      seqNums.push(guideModule?.seqNumber)
      return seqNums
    })


    let maxSequenceNumber= 0;

    for (let i=0; i<seqNums.length; i++){
        if (seqNums[i]>maxSequenceNumber) {
          maxSequenceNumber=seqNums[i];
        }
    }
 
  const AddNewGuideTopic = useRef<HTMLInputElement>(null);

  const url = window.location.href.split('/');
  const guideId = url?.[url.length - 1];

  const navigation = useNavigation();
  const formValues = watch();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [uploadedVideo, setUploadedVideo] = useState<any>();
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [showAddNewTopicsInput, setshowAddNewTopicsInput] = useState<any>(false);

  // To get AudioBook Data with Id

  const guidesQuery = useQuery(
    ['Guides', guideId],
    () =>
      axiosInstance.get<AudioBook>(
        `/v1/guides/${guideId}`
      ),
    {
      enabled: !!guideId,
    }
  );
  const guide = guidesQuery?.data?.data[0];

  // create a new Guide
  const createGuideMutation = useMutation(
    () => {
      const { ModuleTitle } = formValues;
      return axiosInstance.post(`/v1/guideModules`, {
        title:ModuleTitle,
        guideId:selectedGuideId,
        seqNumber:maxSequenceNumber+1
      });
    },
    {
      onSuccess: async (data) => {
        const guideModules = data.data;
        navigation.push(`/guideModules/show/${guideModules.id}`);
      },
      onError: async()=>{
        // window.location.reload();
        // navigation.push(`/AudioBooks/create`);
      }
    },
  );

  // Update an existing AudioBook
  const updateGuideMutation = useMutation(
    () => {
      const { name, fbqPixelId , preHeader } = formValues;
      let webinarMetaDataContent:any;
      return axiosInstance.post(`/v1/webinars`, {
        name,
        preHeader:preHeader,
        fbqPixelId: fbqPixelId || undefined,
        videoDuration: uploadedVideo.video.durationInMilliseconds || undefined,
        videoUuid: uploadedVideo.video.uuid,
        webinarMetadata: webinarMetaDataContent,
      });
    },
    {
      onSuccess: async (data) => {
        const webinar = data.data;
        navigation.push(`/webinars/show/${webinar.id}`);
      },
      onError: async()=>{
        window.location.reload();
        navigation.push(`/webinars/create`);
      }
    },
  );


  
  return (
    <OuterContainer>
      <form autoComplete='off'
        onSubmit={handleSubmit(async (data) => {
          if (guide) {
            updateGuideMutation.mutate();
          } else {
            createGuideMutation.mutate();
          }
        })}
      >
        <ItemsContainer>
        <div className="mt-8 space-y-4">
          <div className="mt-8 space-y-4">
          <div>
            <label htmlFor="couponCode" className="my-label">
              Select Guide
            </label>
            <select
              required
              className="my-input mt-[12px]"
              placeholder=""
              onChange={(e) => {
                setSelectedGuideId(e.target.value);
              }}
              defaultValue={'DEFAULT'}
            >
              <option value="DEFAULT" disabled>
                Select Guide
              </option>
              {availableGuides?.map((guide) => (
                <option key={guide.id} value={guide.id}>
                 {guide.title}
                </option>
              ))} 
            </select>
          </div>
           </div>

          <div>
            <label htmlFor="moduleTitle" className="my-label">Module Name</label>
            <input
              {...register('ModuleTitle', { required: true })}
              className="my-input"  type="text" id="title" placeholder=''
            />
          </div>
        </div>
        <br/>
          <SaveButton type="submit">
            {formLoading && LoadingIcon}
            <span>Create Module</span>
          </SaveButton>
      </ItemsContainer>
      </form>
      <MutationMessage mutation={createGuideMutation} />
      <MutationMessage mutation={updateGuideMutation} />
    </OuterContainer>
  );
};
