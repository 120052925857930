import { Refine } from '@pankod/refine-core';
import routerProvider from '@pankod/refine-react-router-v6';

import axios from 'axios';
import { GlobalContext, GlobalContextProvider } from 'contexts/GlobalContext';
import customDataProvider from 'customDataProvider';
import { getApp as _getApp, getApps, initializeApp } from 'firebase/app';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { enableIndexedDbPersistence } from 'firebase/firestore';
import {
  AudioBookAuthorList,
  CreateAudioBookAuthor,
} from 'pages/audioBookAuthor';
import { CreateBulkAudioBooks } from 'pages/audioBookAuthor/createBulkAudioBooks';
import { ShowAudioBookAuthor } from 'pages/audioBookAuthor/show';
import {
  AudioBookList,
  CreateAudioBook,
  ShowAudioBook,
} from 'pages/audioBooks';
import { BulkUploadList } from 'pages/bulkUploads';
import { CloneWebinarList } from 'pages/cloneWebinar';
import {
  CreateFeaturedWorkshop,
  FeaturedWorkshops,
} from 'pages/featuredWorkshops';
import { GuideModuleShow, GuideModulesList } from 'pages/guideModules';
import { CreateGuideModule } from 'pages/guideModules/create';
import { GuideResourceShow, GuideResourcesList } from 'pages/GuideResource';
import { CreateGuideResource } from 'pages/GuideResource/create';
import { CreateGuide, GuideList, GuideShow } from 'pages/guides';
import { CreateHost, HostList, HostShow } from 'pages/host';
import LoginPage from 'pages/login';
import { Payments, PaymentsList, SubscriptionTierShow } from 'pages/payments';
import { Coupons, CouponsList, ShowCoupons } from 'pages/payments/coupons';
import { CreateTopWorkshop, TopWorkshops } from 'pages/top-workshops';
import { CreateBulkTopWorkshops } from 'pages/top-workshops/createBulkTopworkshops';
import {
  RemoveUserOnboarding,
  RemoveUserSubscription,
  ResetAll,
  UsersList,
} from 'pages/users';
import webinarActivitiesResource from 'pages/webinarActivities';
import {
  CreateWebinarAdminChat,
  ShowWebinarChat,
  WebinarAdminChatList,
} from 'pages/webinarChat';
import { WebinarCreate, WebinarList, WebinarShow } from 'pages/webinars';
import { WebinarThumbnailsBulkCreate } from 'pages/webinars/bulkUploadThumbnails';
import { CloneWebinar } from 'pages/webinars/cloneWebinar';
import {
  CreateAutoSchedule,
  WebinarScheduleCreate,
  WebinarScheduleList,
} from 'pages/webinars/webinarSchedules';
import webinarSignupsResource from 'pages/webinars/webinarSignups';
import { CreateBulkResources } from 'pages/webinarSlotCreation/createBulkResources';
import {
  CreateWebinarTopic,
  EditWebinarTopic,
  HostCorrectionScript,
  WebinarTopicList,
} from 'pages/webinarTopics';
import { useContext, useEffect } from 'react';

import { Layout } from 'components/Layout';

import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import './index.css';
import { CreateWebinarSlot, WebinarSlot } from 'pages/webinarSlotCreation';
import { CreateSeries, SeriesList } from 'pages/series';
import { CreateBite } from 'pages/bites/create';
import { RemoveSeriesFromCollection } from 'pages/series/edit';
import { BitesList } from 'pages/bites';
import { RemoveBitesFromSeries } from 'pages/bites/edit';


const firebaseConfig = {
  // ...
  // The value of `databaseURL` depends on the location of the database
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  // databaseURL: process.env.REACT_APP_FIRBASE_DATABASE_URL,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
console.log(
  'firebaseConfig',
  firebase,
  process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
);
// Initialize Firebase
// if (!firebase.apps.length) {
//   firebase.initializeApp({});
// } else {
//   firebase.app(); // if already initialized, use that one
// }
// console.log('bro', firebase.apps.length, firebase.apps);
// initializeApp(firebaseConfig);
// export const app = !firebase.apps.length
//   ? initializeApp(firebaseConfig, 'webinarChat')
//   : firebase.app();
// export const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
// export const db = getDatabase(app);

const firebaseIsRunning = () => !!getApps().length;

export function getApp() {
  if (!firebaseIsRunning()) {
    return firebase.initializeApp(firebaseConfig);
  }
  console.log('getApp', _getApp());
  return _getApp();
}
export const app = getApp();
export function getFirestore() {
  const isRunning = firebaseIsRunning();
  if (!isRunning) getApp();

  //@ts-ignore
  const db = app.firestore();

  if (!isRunning)
    if (typeof window !== undefined) enableIndexedDbPersistence(db);

  return db;
}
function App() {
  return (
    <GlobalContextProvider>
      <RefineCode />
    </GlobalContextProvider>
  );
}

export default App;

const RefineCode = () => {
  const { currentUser } = useContext(GlobalContext);
  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_OUTSKILL_SERVER}/v1/users/generate-firebase-token`,
        {
          data: {
            userEmail: 'abhinav.vishwakarma@growthschool.io',
          },
        }
      )
      .then(function (response) {
        try {
          const auth = getAuth();
          signInWithCustomToken(auth, response.data.token)
            .then((userCredential) => {
              // Signed in
              console.log('userCredential', userCredential);
              const user = userCredential.user;
              // ...
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              // ...
            });
        } catch (e) {
          console.log('error is bro', e);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  // const getTokenForFirebaseAuth = useMutation(
  //   ({ userEmail }: { userEmail: string }) =>
  //     axios.post('http://localhost:4001/v1/users/generate-firebase-token', {
  //       userEmail,
  //     }),
  //   {
  //     onSuccess: (data: any) => {
  //       const customToken = data.data.token;
  //       try {
  //         const auth = getAuth();
  //         signInWithCustomToken(auth, customToken)
  //           .then((userCredential) => {
  //             // Signed in
  //             console.log('userCredential is', userCredential);
  //             const user = userCredential.user;
  //             // ...
  //           })
  //           .catch((error) => {
  //             console.log('error is', error.code, error.message);
  //             const errorCode = error.code;
  //             const errorMessage = error.message;
  //             // ...
  //           });
  //       } catch (e) {
  //         console.log('error is', e);
  //       }
  //       queryClient.invalidateQueries(['webinars']);

  //       // // Authenticate with Firebase using the custom token
  //       // firebase
  //       //   //@ts-ignore
  //       //   .auth()
  //       //   //@ts-ignore
  //       //   .signInWithCustomToken(customToken)
  //       //   .then(() => {
  //       //     console.log('Successfully authenticated with custom token');
  //       //     //@ts-ignore
  //       //     console.log('user', firebase.auth().currentUser, db);
  //       //     // Continue with Firestore operations
  //       //   })
  //       //   .catch((error: any) => {
  //       //     console.error('Error authenticating with custom token:', error);
  //       //   });
  //     },
  //   }
  // );
  // useEffect(() => {
  //   if (currentUser.emails[0]) {
  //     getTokenForFirebaseAuth.mutate({ userEmail: currentUser.emails[0] });
  //   }
  // }, [currentUser.emails]);
  return (
    <>
      <Refine
        routerProvider={{
          ...routerProvider,
          routes: [
            {
              element: <LoginPage />,
              path: '/login',
              layout: true,
            },
          ],
        }}
        dataProvider={{
          default: customDataProvider(process.env.REACT_APP_SERVER),
          // default: dataProvider('https://api.finefoods.refine.dev'),
        }}
        Layout={Layout}
        resources={[
          {
            name: 'webinars',
            list: WebinarList,
            show: WebinarShow,
            create: () => <WebinarCreate />,
            edit: () => <WebinarCreate />,
          },
          {
            name: 'hosts',
            list: HostList,
            show: HostShow,
            create: () => <CreateHost />,
            edit: () => <CreateHost />,
          },
          {
            name: 'webinarSchedules',
            list: WebinarScheduleList,
            create: WebinarScheduleCreate,
            edit: CreateAutoSchedule
          },
          {
            name: 'subscriptionTiers',
            create: () => <Payments />,
            edit: () => <Payments />,
            list: PaymentsList,
            show: SubscriptionTierShow,
          },
          {
            name: 'Coupons',
            create: () => <Coupons />,
            edit: () => <Coupons />,
            list: CouponsList,
            show: ShowCoupons,
          },
          {
            name: 'WebinarSlots',
            create: () => <CreateWebinarSlot />,
            // edit: () => <CreateBulkGuides />,
            list: WebinarSlot,
          },
          {
            name: 'audio-books',
            create: () => <CreateAudioBook />,
            edit: () => <CreateAudioBook />,
            list: AudioBookList,
            show: ShowAudioBook,
          },

          {
            name: 'authors',
            create: () => <CreateAudioBookAuthor />,
            show: () => <ShowAudioBookAuthor />,
            list: AudioBookAuthorList,
          },
          {
            name: 'guides',
            create: () => <CreateGuide />,
            edit: () => <CreateGuide />,
            list: GuideList,
            show: GuideShow,
          },
          {
            name: 'guideModules',
            create: () => <CreateGuideModule />,
            show: () => <GuideModuleShow />,
            //edit: () => <GuideModulesedit/>,
            list: GuideModulesList,
          },
          {
            name: 'guideResources',
            create: () => <CreateGuideResource />,
            //edit:()=><CreateGuideResource/>,
            list: GuideResourcesList,
            show: GuideResourceShow,
          },
          {
            name: 'BulkUploads',
            create: () => <CreateBulkResources />,
            edit: () => <CreateBulkAudioBooks />,
            list: BulkUploadList,
          },
          // Not Needed As of Now
          // {
          //   name: 'BulkMessageUpload',
          //   create: () => <BulkMessageUpload />,
          // },
          {
            name: 'UserManagement',
            create: () => <RemoveUserSubscription />,
            edit: () => <RemoveUserOnboarding />,
            show: () => <ResetAll />,
            list: UsersList,
          },
          {
            name: 'webinarTopics',
            create: () => <CreateWebinarTopic />,
            edit: () => <EditWebinarTopic />,
            show: () => <HostCorrectionScript />,
            list: WebinarTopicList,
          },
          {
            name: 'featuredWorkshops',
            create: () => <CreateFeaturedWorkshop />,
            edit: () => <WebinarThumbnailsBulkCreate />,
            list: () => <FeaturedWorkshops />,
          },
          {
            name: 'top-workshops',
            create: () => <CreateTopWorkshop />,
            list: () => <TopWorkshops />,
            edit: () => <CreateBulkTopWorkshops />,
          },
          {
            name: 'cloneWebinar',
            create: () => <CloneWebinar />,
            list: () => <CloneWebinarList />,
          },
          {
            name: 'webinarChat',
            create: () => <CreateWebinarAdminChat />,
            show: () => <ShowWebinarChat />,
            list: () => <WebinarAdminChatList />,
          },
          {
            name: 'series',
            create: () => <CreateSeries />,
            show: () => <ShowWebinarChat />,
            list: () => <SeriesList />,
            edit: () => <RemoveSeriesFromCollection />,
          },
          {
            name: 'bites',
            create: () => <CreateBite />,
            list: () => <BitesList />,
            edit: () => <RemoveBitesFromSeries />,
          }
        ]}
      />
    </>
  );
};
