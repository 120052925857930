import axiosInstance from 'axiosInstance';
import useRefresh from 'hooks/useRefresh';
import { Webinar } from 'interfaces/apiTypes';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import foreignKeys from 'constants/foreignKeys';
import { capitalize } from 'utils/generalUtils';
import webinarUtils from 'utils/webinarUtils';

import FilledButton from './FilledButton';
import BottomBar from './GenericGraph/Children/BottomBar';
import MutationMessage from './Messages/MutationMessage';

interface IWebinarFileUploadProps {
  webinar: Webinar;
  file: 'messages';
  numPreviousResources: number;
  replacable?: boolean;
}
const WebinarFileUpload: React.FC<IWebinarFileUploadProps> = ({
  webinar,
  file,
  numPreviousResources,
  replacable = true,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [replace, setReplace] = useState(false);
  const queryClient = useQueryClient();
  const [offset, setOffset] = useState(0);
  const refresh = useRefresh();

  const uploadMutation = useMutation(
    (formData: FormData) => {
      return axiosInstance.post(
        `/v1/webinars/${webinar.id}/${file}?replace=${replace}&offset=${offset}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
    },
    {
      onSuccess: async (response) => {
        console.log(response);
        // await replaceAndAddMessageToFirebase(response.data);
        // TODO: ['webinars', webinar.id, 'resources'] custom hook react query
        queryClient.invalidateQueries(['webinars', webinar.id, 'resources']);
        queryClient.invalidateQueries(['webinars', webinar.id, 'rawResource']);
      },
    }
  );

  const handleUpload = useCallback(async () => {
    if (fileInputRef.current && fileInputRef.current.files?.[0]) {
      const formData = new FormData();
      console.log({
        textFileToUpload: fileInputRef.current.files[0],
      });
      formData.append(file, fileInputRef.current.files[0]);
      //@ts-ignore
      // await sendBulkMessageToFirebase(formData.entries());
      uploadMutation.mutate(formData);
    }
  }, [uploadMutation, file]);
  return (
    <div className="overflow-auto">
      <label className="my-label">{capitalize(file)} File:</label>
      <input
        className="my-input"
        ref={fileInputRef}
        type="file"
        onChange={refresh}
      />
      <div className="mt-2 flex space-x-2">
        {!!numPreviousResources && (
          <FilledButton>
            <Link to={`/${file}?${foreignKeys.webinarId}=${webinar.id}`}>
              View ({numPreviousResources})
            </Link>
          </FilledButton>
        )}
        <FilledButton
          onClick={handleUpload}
          disabled={(fileInputRef.current?.files.length ?? 0) === 0}
        >
          {uploadMutation.status === 'idle'
            ? 'Upload'
            : uploadMutation.status === 'loading'
            ? 'Uploading'
            : uploadMutation.status === 'success'
            ? 'Uploaded'
            : 'Upload Failed'}
        </FilledButton>

        {!!numPreviousResources && replacable && (
          <div className="flex space-x-2 items-center">
            <input
              type="checkbox"
              checked={replace}
              onChange={(e) => {
                setReplace((prev) => !prev);
              }}
            />
            <span className="text-sm">
              Replace&nbsp;&nbsp;
              {replace && (
                <span>
                  <strong>Note</strong>:&nbsp;
                  <span className={'text-red-600'}>
                    <strong>{numPreviousResources}</strong> {file} will be
                    deleted!
                  </span>
                </span>
              )}
            </span>
          </div>
        )}
      </div>
      {file === 'messages' && (
        <MessagesOffset
          webinar={webinar}
          offset={offset}
          setOffset={setOffset}
        />
      )}
      <MutationMessage mutation={uploadMutation} />
    </div>
  );
};
export default WebinarFileUpload;
interface IMessagesOffsetProps {
  webinar: Webinar;
  offset: number;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
}
export const MessagesOffset = ({
  webinar,
  offset,
  setOffset,
}: IMessagesOffsetProps) => {
  const [inputFocused, setInputFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const reset = useCallback(() => {
    setOffset(0);
    inputRef.current.value = webinarUtils.getFormattedTimer(0);
  }, [setOffset]);
  useEffect(() => {
    reset();
  }, [reset]);
  return (
    <div className="mt-4">
      <div className="mb-3 flex gap-2 items-center">
        <label>Offset: </label>
        <input
          ref={inputRef}
          className="my-input w-min"
          placeholder="offset"
          onFocus={() => {
            setInputFocused(true);
          }}
          onBlur={() => {
            setInputFocused(false);
          }}
          onChange={(e) => {
            setOffset(webinarUtils.timeStampToMilliseconds(e.target.value));
          }}
        />
        <FilledButton onClick={reset}>Reset</FilledButton>
      </div>
      <BottomBar
        value={offset}
        xmin={-webinar.videoDuration}
        roundedXMin={-webinar.videoDuration}
        roundedXmax={webinar.videoDuration}
        xmax={webinar.videoDuration}
        onChange={(v) => {
          if (!inputFocused) {
            setOffset(v);
            inputRef.current.value = webinarUtils.getFormattedTimer(v);
          }
        }}
      />
    </div>
  );
};
/*
uploadMutation.error.response.data

{
    "_original": {
        "time": "00:00:10",
        "expireAt": "00:02:45",
        "type": "resource",
        "title": "This book is free",
        "description": "some description",
        "resourceLink": ""
    },
    "details": [
        {
            "message": "\"resourceLink\" is not allowed to be empty",
            "path": [
                "resourceLink"
            ],
            "type": "string.empty",
            "context": {
                "label": "resourceLink",
                "value": "",
                "key": "resourceLink"
            }
        }
    ]
}
*/
