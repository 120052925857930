interface ILeftTickProps {
  value: number;
  offsetTop: number;
  showTick?: boolean;
  showValue?: boolean;
  showGrid?: boolean;
  width: number;
}

const LeftTick: React.FC<ILeftTickProps> = ({
  value,
  offsetTop,
  showTick = true,
  showValue = true,
  showGrid = false,
  width,
}) => {
  return (
    <div className={`absolute`} style={{ top: offsetTop }}>
      {showValue && (
        <div className="absolute top-0 -translate-y-1/2 -left-3 -translate-x-full">
          {value}
        </div>
      )}
      {showTick && (
        <div className="absolute -translate-x-1/2 w-3 h-[1px] bg-black"></div>
      )}
      {showGrid && (
        <div
          className="absolute h-[1px] bg-gray-300"
          style={{ width: width }}
        ></div>
      )}
    </div>
  );
};
export default LeftTick;
