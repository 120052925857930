import axiosInstance from 'axiosInstance';
import { Line } from 'rc-progress';
import { useReducer, useRef, useState } from 'react';
import ReactHlsPlayer from 'react-hls-player/dist';

interface IFileUpload {
  acceptedFileType: 'video' | 'image';
  finalFileUrl?: string;
  setFinalFileUrl: (presignedPost: any) => void;
  thumbnailType?: string;
}

const FileUpload: React.FC<IFileUpload> = ({
  acceptedFileType,
  finalFileUrl,
  setFinalFileUrl,
  thumbnailType
}) => {
  const {
    fileError,
    fileName,
    fileContents,
    fileType,
    fileDispatch,
    handleFileChange,
  } = useFileChange(acceptedFileType);
  const [uploadProgress, setUploadProgress] = useState(0);
  const playerRef = useRef<HTMLVideoElement>(null);
  const [uploadAttemptedDuringProgress, setUploadAttemptedDuringProgress] =
    useState(false);
  const [noFileSelectedError, setNoFileSelectedError] = useState(false);

  const uploadToS3 = async ({
    fileType,
    fileContents,
  }: {
    fileType: string;
    fileContents: File;
  }) => {
    const presignedPost = await getPresignedPostUrl(fileType, thumbnailType);
    const formData = new FormData();
    formData.append('Content-Type', fileType);
    Object.entries(presignedPost.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append('file', fileContents); // The file has be the last element
    // Authorization: AWS AWSAccessKeyId:Signature

    const response = await axiosInstance.post(presignedPost.url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (e) => {
        console.log(`process percentage: ${(e.loaded / e.total) * 100}%`);
        setUploadProgress((e.loaded / e.total) * 100);
      },
    });
    setUploadAttemptedDuringProgress(false);
    console.log(presignedPost)
    return presignedPost;
  };
  const handleSubmit = async () => {
    try {
      if (uploadProgress > 0 && uploadProgress < 100) {
        setUploadAttemptedDuringProgress(true);
        return;
      }
      if (fileType && fileContents) {
        setFinalFileUrl(null);
        const presignedPost = await uploadToS3({ fileType, fileContents });

        setFinalFileUrl(presignedPost);
        fileDispatch({ type: 'RESET_FILE_STATE' });
      } else {
        setNoFileSelectedError(true);
      }
    } catch (err) {
      console.log('error is', err);
    }
  };

  return (
    <>
      <div className="w-full">
        <div>
          {fileError && (
            <h1 className="max-w-3xl text-3xl text-red-600">{fileError}</h1>
          )}
          <div className="flex flex-col items-center mt-2 space-y-3 mb-2">
            <label className="px-5 py-1 mt-6 bg-white border rounded-lg shadow cursor-pointer hover:bg-purple-600 hover:text-white">
              <span className="mt-2 text-base leading-normal">
                {fileName || 'File Input'}
              </span>
              <input
                type="file"
                accept={`${acceptedFileType}/*`}
                className="hidden"
                onChange={(e) => {
                  setNoFileSelectedError(false);
                  handleFileChange(e);
                }}
              />
            </label>
            <button
              type="button"
              onClick={handleSubmit}
              className="px-1 py-2 border-2 border-green-400 rounded-md hover:bg-purple-200"
            >
              Upload
            </button>
            {uploadAttemptedDuringProgress && (
              <p className="text-red-600">
                Please wait for existing file upload to complete first
              </p>
            )}
            {noFileSelectedError && (
              <p className="text-red-600">Please select file to upload</p>
            )}
          </div>
          <div className="w-full mb-4">
            <div className="w-full">
              <Line percent={uploadProgress}></Line>
              {/* <Circle percent={uploadProgress} /> */}
            </div>
            <h1 className="mt-4 max-w-xl text-xl text-center">
              {Math.floor(uploadProgress)}% Uploaded
            </h1>
          </div>
          {finalFileUrl && (
            <span className="inline-block max-w-96 max-h-96 overflow-hidden">
              {acceptedFileType === 'image' ? (
                <img src={finalFileUrl} alt="" />
              ) : (
                <ReactHlsPlayer
                  playerRef={playerRef}
                  src={finalFileUrl}
                  controls
                />
              )}
            </span>
          )}{' '}
        </div>
      </div>
    </>
  );
};

type PresignedPostUrlResponse = {
  url: string;
  fields: Record<string, string>;
};

async function getPresignedPostUrl(fileType: string, thumbnailType: string) {
  const { data: presignedPostUrl } =
    await axiosInstance.get<PresignedPostUrlResponse>(
      `v1/upload-thumbnail/aws-presigned-url?fileType=${fileType}&thumbnailtype=${thumbnailType}`
    );

  return presignedPostUrl;
}

const initialFileState: FileState = {
  fileError: null,
  fileName: null,
  fileSize: null,
  fileType: null,
  fileContents: null,
};

function bytesToMb(bytes: number) {
  const mb = bytes / 1000000;

  return mb;
}

export function useFileChange(
  acceptedFileType: string,
  maxFileSizeAllowed: number = Infinity
) {
  const [
    { fileError, fileContents, fileName, fileSize, fileType },
    fileDispatch,
  ] = useReducer(fileChangeReducer, initialFileState);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    console.log('fileObj is', fileObj);

    const [type] = fileObj.type.split('/');
    if (!type || type !== acceptedFileType) {
      fileDispatch({
        type: 'FILE_CHANGE_FAILURE',
        fileError: `You can only upload ${acceptedFileType} files.`,
      });
      return;
    }

    if (fileObj.size > maxFileSizeAllowed) {
      fileDispatch({
        type: 'FILE_CHANGE_FAILURE',
        fileError: `File is too large, file size is ${bytesToMb(
          fileObj.size
        ).toFixed(2)} MB, maximum allowed size - ${Math.floor(
          bytesToMb(maxFileSizeAllowed)
        )} MB.`,
      });
      return;
    }

    // eslint-disable-next-line no-param-reassign
    event.target.value = '';

    fileDispatch({
      type: 'FILE_CHANGE_SUCCESS',
      fileName: fileObj.name,
      fileSize: fileObj.size,
      fileType: fileObj.type,
      fileContents: fileObj,
    });
  };

  return {
    fileError,
    fileContents,
    fileName,
    fileType,
    fileSize,
    handleFileChange,
    fileDispatch,
  };
}

type FileState = {
  fileError: string | null;
  fileName: string | null;
  fileSize: number | null;
  fileType: string | null;
  fileContents: File | null;
};

type FileChangeAction =
  | {
      type: 'FILE_CHANGE_SUCCESS';
      fileName: string;
      fileSize: number;
      fileType: string;
      fileContents: File;
    }
  | { type: 'FILE_CHANGE_FAILURE'; fileError: string }
  | { type: 'RESET_FILE_STATE' };

export function fileChangeReducer(
  _state: FileState,
  action: FileChangeAction
): FileState {
  switch (action.type) {
    case 'FILE_CHANGE_SUCCESS': {
      return {
        fileError: null,
        fileName: action.fileName,
        fileSize: action.fileSize,
        fileType: action.fileType,
        fileContents: action.fileContents,
      };
    }
    case 'FILE_CHANGE_FAILURE': {
      return {
        ...initialFileState,
        fileError: action.fileError,
      };
    }
    case 'RESET_FILE_STATE': {
      return initialFileState;
    }
    default: {
      throw new Error(`Unsupported action type: ${JSON.stringify(action)}`);
    }
  }
}
export default FileUpload;
