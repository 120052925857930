import { useNavigation, useOne, useSelect } from '@pankod/refine-core';
import { useForm } from '@pankod/refine-react-hook-form';

import { Webinar, webinarScheduleType } from 'interfaces/apiTypes';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import FieldRequiredErrorMessage from 'components/FieldRequiredErrorMessage';
import BottomBar from 'components/GenericGraph/Children/BottomBar';
import { LoadingIcon } from 'components/icons';
import ItemsContainer from 'components/ItemsContainer';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import endpoints from 'constants/endpoints';
import foreignKeys from 'constants/foreignKeys';
import { DEFAULT_MILLISECONDS_AFTER_WHICH_WEBINAR_SCHEDULE_WILL_EXPIRE_AFTER_WEBINAR_STARTS } from 'constants/generalConstants';
import webinarUtils from 'utils/webinarUtils';

export const WebinarScheduleCreate: React.FC = () => {
  const {
    refineCore: { onFinish, formLoading },
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const formValues = watch();

  const { push } = useNavigation(); // Use useNavigation for redirecting

  const { options } = useSelect({
    resource: 'webinars',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });

  const webinarQuery = useOne<Webinar>({
    resource: endpoints.webinars,
    id: formValues[foreignKeys.webinarId],
    queryOptions: {
      enabled: !!formValues[foreignKeys.webinarId],
    },
  });

  const webinar = webinarQuery.data?.data;

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const it = searchParams.entries();
    let pair = it.next();
    while (!pair.done) {
      const [key, value] = pair.value;
      switch (key) {
        case foreignKeys.webinarId: {
          setValue(foreignKeys.webinarId, +value);
        }
      }
      pair = it.next();
    }
  }, [searchParams, setValue, options]);

  const [barValue, setBarValue] = useState(
    DEFAULT_MILLISECONDS_AFTER_WHICH_WEBINAR_SCHEDULE_WILL_EXPIRE_AFTER_WEBINAR_STARTS
  );

  useEffect(() => {
    setValue('expireAt', webinarUtils.getFormattedTimer(barValue));
  }, [barValue, setValue]);

  return (
    <OuterContainer>
      <form
        onSubmit={handleSubmit(async (data) => {
          await onFinish({
            ...data,
          });
          push('/webinarSchedules'); 
        })}
      >
        <ItemsContainer>
          <div>
            <label htmlFor="startTime" className="my-label">
              Start Time
            </label>
            <input
              type="datetime-local"
              className="my-input"
              id="startTime"
              {...register('startTime', {
                required: true,
                setValueAs: (v) => {
                  if (v) {
                    return new Date(v).toISOString();
                  }
                },
              })}
            />
            {errors.startTime && <FieldRequiredErrorMessage />}
          </div>

          <div>
            <label htmlFor="type" className="my-label">
              Webinar Schedule Type 
            </label>
            <select
              id="type"
              defaultValue={''}
              {...register('type', { required: true })}
              className="my-input"
            >
              <option value={''} disabled>
                Please select
              </option>
              <option value={webinarScheduleType.particularTime}>
                {webinarScheduleType.particularTime}
              </option>
            </select>
          </div>
          {errors.type && <FieldRequiredErrorMessage />}

          <div>
            <label htmlFor="webinarId" className="my-label">
              Webinar
            </label>
            <input
              id="webinarId"
              list="webinars"
              name="webinarId"
              className="my-input"
              defaultValue={''}
              {...register('webinarId', { required: true })}
            />
            <datalist id="webinars">
              <option value={''} disabled>
                Please select
              </option>
              {options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </datalist>
            {errors.webinarId && <FieldRequiredErrorMessage />}
          </div>
          
          {webinar && (
            <div>
              <div>
                <label className="my-label" htmlFor="name">
                  Webinar Type
                </label>
                <input id="name" value={webinar.webinarType} disabled className="my-input" />
                </div><br></br>
              <div>
                <label htmlFor="expireAt" className="my-label">
                  Expire At
                </label>
                <input
                  type="text"
                  className="my-input"
                  id="expireAt"
                  {...register('expireAt', {})}
                />
                <div className="mt-4">
                  <BottomBar
                    value={barValue}
                    onChange={(v) => setBarValue(v)}
                    xmax={webinar?.duration ?? 0}
                    roundedXmax={webinar?.duration ?? 0}
                  />
                </div>
              </div>
            </div>
          )}

          <SaveButton type="submit">
            {formLoading && LoadingIcon}
            <span>Save</span>
          </SaveButton>
        </ItemsContainer>
      </form>
    </OuterContainer>
  );
};
