import { useForm } from '@pankod/refine-react-hook-form';
import moment from 'moment';
import { useEffect, useState , useRef  } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { LoadingIcon } from 'components/icons';
import ItemsContainer from 'components/ItemsContainer';
import MutationMessage from 'components/Messages/MutationMessage';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import {CSVLink} from 'react-csv'
import Papa from "papaparse";
import axiosInstance from 'axiosInstance';
import { Guide, GuideModule, Webinar, WebinarCategory } from 'interfaces/apiTypes';
import webinarUtils from 'utils/webinarUtils';
import endpoints from 'constants/endpoints';
import FilledButton from 'components/FilledButton';

// Allowed extensions for input file
const allowedExtensions = ["csv"];

export const CreateBulkResources: React.FC = () => {

  const guidesQuery = useQuery(
    ['Guides'],
    () =>
      axiosInstance.get<Guide>(
        `/v1/guides`
      ),
  );
  const guide = guidesQuery?.data?.data;

  console.log("-----------------Guides -----------------",guide )

  const guideModulesQuery = useQuery(
    ['GuideModules'],
    () =>
      axiosInstance.get<GuideModule>(
        `/v1/guideModules`
      ),
  );
  const guideModules = guideModulesQuery?.data?.data;

  let AvailableGuideModules = []
  
  guideModules?.map((guideModule)=>{
    AvailableGuideModules.push(guideModule?.id)
  })

  let AvailableGuides = []

  guide?.map((guide)=>{
    AvailableGuides.push(guide?.id)
  })

  console.log("-----------------Guides Modules -----------------",guideModules)

  const formRef = useRef(null);

  const headers = [
    { label: "Date", key: "date" },
    { label: "Time", key: "time" },
  ];
   
  const {
    refineCore: { formLoading },
    handleSubmit,setValue
  } = useForm();


  const [dates, setDates] = useState<any>(null);
  const [times, setTimes] = useState<any>(null);

  const [tempDate,setTempDate]= useState(null)
  const [tempTime,setTempTime]= useState(null)

  const [timesArr, setTimesArr] = useState([]);
  const [webinarSlotDataCsv, setWebinarSlotDataCsv] = useState([]);

  const [slotCreationStatus, setSlotCreationStatus] = useState("");

// This state will store the parsed data
const [data, setData] = useState([]);
     
// It state will contain the error when
// correct file extension is not used
const [error, setError] = useState("");
 
// It will store the file uploaded by the user
const [file, setFile] = useState();

  const handleFileChange = (e) => {
    setError("");
     
    // Check if user has entered the file
    if (e.target.files.length) {
        const inputFile = e.target.files[0];
         
        // Check the file extensions, if it not
        // included in the allowed extensions
        // we show the error
        const fileExtension = inputFile?.type.split("/")[1];
        if (!allowedExtensions.includes(fileExtension)) {
            setError("Please input a csv file");
            return;
        }

        // If input type is correct set the state
        setFile(inputFile);
    }
};
const HandleParse = () => {
     
    // If user clicks the parse button without a file we show a error
    if (!file) return setError("Enter a valid file");

    // Initialize a reader which allows user to read any file or blob.
    const reader = new FileReader();
     
    // Event listener on reader when the file loads, we parse it and set the data.

    reader.onload = async ({ target }) => {
        const csv = Papa.parse(target.result, { header: true });
        const parsedData = csv?.data;
        setData(parsedData);

        let newGuidesData = []
        for(let i=0; i<parsedData.length; i++){
          newGuidesData.push(Number(parsedData[i].GuideId))
        }

        let newGuideModulesData = []
        for(let i=0; i<parsedData.length; i++){
          newGuideModulesData.push(Number(parsedData[i].GuideModuleId))
        }

        console.log("--------" , newGuideModulesData , newGuidesData)

        const guidesValidityCheck = newGuidesData.every(val => AvailableGuides.includes(val));

        const guideModulesValidityCheck = newGuideModulesData.every(val => AvailableGuideModules.includes(val));

        console.log("-------------------" , guidesValidityCheck , guideModulesValidityCheck)

        if(guidesValidityCheck == true && guideModulesValidityCheck == true){
          createScheduleMutation.mutate(parsedData)
        }
        else{
          if(guidesValidityCheck == false){
            setSlotCreationStatus("Guides creation Failed Check Guide Id's")  
          }
          if(guideModulesValidityCheck == false){
            setSlotCreationStatus("Guides creation Failed Check Guide Modules Id's")  
          }
          else{
            setSlotCreationStatus("Guides creation Failed Check Guide Id's & Guide Modules Id's")  
          }
          
        }

        
    };
    reader.readAsText(file);


};

const createScheduleMutation = useMutation(
  (parsedData) => {
    console.log("i came in",parsedData)
    return axiosInstance.post("/v1/guideResources/bulk", {
      parsedData
    });
  },
  {
    onSuccess: (response) => {
      // queryClient.invalidateQueries([
      //   endpoints.webinarSchedules,
      //   'webinarId=',
      //   webinarId,
      // ]);
      setSlotCreationStatus("SuccessFully Created Guide Resources")
    },
    onError: async()=>{
      setSlotCreationStatus("Guide Resources creation Failed Check Data once")      
    }
  }
);





    const startTimeRef = useRef(null);
    const endTimeRef = useRef(null);
    const dateRef = useRef(null);

    const storeDates = (selectedStartTime , selectedEndTime , selectedSlotDate) => {
      let startTime = selectedStartTime
      let endTime = selectedEndTime
      let slotdate = selectedSlotDate


      startTime = moment(startTime , 'HH:mm:ss[Z]').utc()
      endTime = moment(endTime , 'HH:mm:ss[Z]').utc()
      let now = startTime.clone();
      while (now.isSameOrBefore(endTime)) {
        timesArr.push(now.format('HH:mm:ss[Z]'));
          now.add(1, 'hour');
      }
      setTimes(timesArr)

        for(let i=0;i<timesArr.length;i++){
           webinarSlotDataCsv.push(...[{"date":selectedSlotDate , "time":timesArr[i]}])
         }

         setTimesArr([])
    }

    

    return (
    <OuterContainer>
          <div>
            <label htmlFor="csvInput" style={{ display: "block" }}>
                Upload CSV File - 
            </label>
            <br/>
            <input
                onChange={handleFileChange}
                id="csvInput"
                name="file"
                type="File"
            />
            <br/><br/>
            <div>
                {/* <button onClick={()=>{
                  createScheduleMutation.mutate();
                } }>Parse</button> */}
                <SaveButton onClick={HandleParse}>Create New Resources</SaveButton>
            </div>
            <br/>
            <h3 className='text-[25px]'>{slotCreationStatus}</h3>
        </div>
    </OuterContainer>
  );
};