import axiosInstance from 'axiosInstance';
import {
  ImmediateSlot,
  ImmediateSlotSelectionMethod,
  immediateSlotSelectionMethod,
  Webinar,
} from 'interfaces/apiTypes';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import endpoints from 'constants/endpoints';
import { millisecondsInOne } from 'constants/generalConstants';
import webinarUtils from 'utils/webinarUtils';

import FilledButton from './FilledButton';
import BottomBar from './GenericGraph/Children/BottomBar';
import ItemsContainer from './ItemsContainer';
import MutationMessage from './Messages/MutationMessage';

interface ISetupImmediateSlot {
  webinar: Webinar;
}
const immediateSlotActiveMethodPropertyMap: Record<
  ImmediateSlotSelectionMethod,
  'time' | 'range' | 'values'
> = {
  particularTime: 'time',
  ramdomFromValues: 'values',
  randomBetweenRange: 'range',
};
const SetupImmediateSlot: React.FC<ISetupImmediateSlot> = ({ webinar }) => {
  const [activeMethod, setActiveMethod] =
    useState<ImmediateSlotSelectionMethod>();

  const immediateSlotQuery = useQuery(
    [endpoints.immediateSlots, webinar.immediateSlotId],
    () => {
      return axiosInstance.get(
        `${endpoints.immediateSlots}/${webinar.immediateSlotId}`
      );
    },
    {
      enabled: !!webinar.immediateSlotId,
    }
  );
  const immediateSlot =
    webinar.immediateSlotId &&
    (immediateSlotQuery?.data?.data as ImmediateSlot | undefined);

  return (
    <ItemsContainer>
      <p className="text-xl">Setup Immediate Slot</p>

      <ItemsContainer>
        {immediateSlot && (
          <ExistingImmediateSlot immediateSlot={immediateSlot} />
        )}
        <div className="border border-1"> </div>
        <p>Create Immediate Slot</p>
        <div>
          <label htmlFor="activeMethod" className="my-label">
            Active Method
          </label>
          <select
            className="my-input"
            value={activeMethod}
            defaultValue=""
            onChange={(e) => {
              setActiveMethod(e.target.value as ImmediateSlotSelectionMethod);
            }}
          >
            <option value={''} disabled>
              Please select
            </option>
            {Object.keys(immediateSlotSelectionMethod).map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div>
          {activeMethod === 'randomBetweenRange' ? (
            <SetupImmediateSlotWithRange webinar={webinar} />
          ) : activeMethod === 'particularTime' ? (
            <SetupImmediateSlotAtParticularTime webinar={webinar} />
          ) : activeMethod === 'ramdomFromValues' ? (
            <SetupImmediatSlotBySpecifyingSpecificTimestamps
              webinar={webinar}
            />
          ) : null}
        </div>
      </ItemsContainer>
    </ItemsContainer>
  );
};
export default SetupImmediateSlot;
interface IExistingImmediateSlot {
  immediateSlot: ImmediateSlot;
}
const ExistingImmediateSlot: React.FC<IExistingImmediateSlot> = ({
  immediateSlot,
}) => {
  const queryClient = useQueryClient();

  const deleteImmediateSlotMutation = useMutation(
    () => {
      return axiosInstance.delete(
        `${endpoints.immediateSlots}/${immediateSlot.id}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    }
  );

  return (
    <div>
      <ItemsContainer>
        <p>Existing Immediate Slot Details</p>
        <p>Active Method - {immediateSlot.activeMethod}</p>
        <pre>
          {JSON.stringify(
            immediateSlot[
              immediateSlotActiveMethodPropertyMap[immediateSlot.activeMethod]
            ],
            null,
            2
          )}
        </pre>
        <FilledButton
          variant="danger"
          onClick={() => deleteImmediateSlotMutation.mutate()}
        >
          Delete
        </FilledButton>
        <MutationMessage mutation={deleteImmediateSlotMutation} />
      </ItemsContainer>
    </div>
  );
};
interface ISetupImmediatSlotBySpecifyingSpecificTimestamps {
  webinar: Webinar;
}
const SetupImmediatSlotBySpecifyingSpecificTimestamps: React.FC<
  ISetupImmediatSlotBySpecifyingSpecificTimestamps
> = ({ webinar }) => {
  const [barValue, setBarValue] = useState(0);
  const [selectedValues, setSelectedValues] = useState<Array<string>>([]);
  const updateImmediateSlotMutations = useUpdateImmediateSlotMutations({
    webinar,
    payload: {
      activeMethod: immediateSlotSelectionMethod.ramdomFromValues,
      values: selectedValues,
    },
  });
  return (
    <ItemsContainer>
      <div>Selected Values: {JSON.stringify(selectedValues, null, 2)}</div>
      <BottomBar
        value={barValue}
        onChange={(v) => {
          setBarValue(v);
        }}
        roundedXmax={webinar.duration}
        xmax={webinar.duration}
      />
      <div className="flex space-x-2">
        <FilledButton
          onClick={() => {
            setSelectedValues((prev) => [
              ...prev,
              webinarUtils.getFormattedTimer(barValue),
            ]);
          }}
        >
          Add {webinarUtils.getFormattedTimer(barValue)}
        </FilledButton>
        <FilledButton
          onClick={() => {
            setSelectedValues([]);
          }}
        >
          Clear
        </FilledButton>
      </div>

      <FilledButton
        onClick={() => updateImmediateSlotMutations.primary.mutate()}
      >
        Confirm
      </FilledButton>
      <MutationMessage mutations={updateImmediateSlotMutations.sequence} />
    </ItemsContainer>
  );
};
interface ISetupImmediateSlotAtParticularTime {
  webinar: Webinar;
}
const SetupImmediateSlotAtParticularTime: React.FC<
  ISetupImmediateSlotAtParticularTime
> = ({ webinar }) => {
  const [barValue, setBarValue] = useState(1 * millisecondsInOne.minute);

  const updateImmediateSlotMutations = useUpdateImmediateSlotMutations({
    webinar,
    payload: {
      activeMethod: immediateSlotSelectionMethod.particularTime,
      time: webinarUtils.getFormattedTimer(barValue),
    },
  });
  return (
    <ItemsContainer>
      <p className="mb-2">Time: {webinarUtils.getFormattedTimer(barValue)}</p>
      <BottomBar
        value={barValue}
        onChange={(v) => {
          setBarValue(v);
        }}
        roundedXmax={webinar.duration}
        xmax={webinar.duration}
      />
      <FilledButton
        onClick={() => {
          updateImmediateSlotMutations.primary.mutate();
        }}
      >
        Confirm
      </FilledButton>
      <MutationMessage mutations={updateImmediateSlotMutations.sequence} />
    </ItemsContainer>
  );
};
const useUpdateImmediateSlotMutations = ({
  webinar,
  payload,
}: {
  webinar: Webinar;
  payload: any;
}) => {
  const queryClient = useQueryClient();

  const deleteImmediateSlotMutation = useMutation(
    () => {
      return axiosInstance.delete(
        `${endpoints.immediateSlots}/${webinar.immediateSlotId}`
      );
    },
    {
      onSuccess: () => {
        createImmediateSlotMutation.mutate();
      },
    }
  );
  const updateWebinarMutation = useMutation(
    ({ immediateSlotId }: { immediateSlotId: number }) => {
      return axiosInstance.patch(`${endpoints.webinars}/${webinar.id}`, {
        immediateSlotId: immediateSlotId,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    }
  );
  const createImmediateSlotMutation = useMutation(
    () => {
      return axiosInstance.post(`${endpoints.immediateSlots}`, payload);
    },
    {
      onSuccess: (response) => {
        const newImmediateSlot = response.data;
        // update webinar.immediateSlotId with new immediateSlot.id
        updateWebinarMutation.mutate({ immediateSlotId: newImmediateSlot.id });
      },
    }
  );
  return {
    deleteImmediateSlotMutation,
    createImmediateSlotMutation,
    updateWebinarMutation,
    primary: webinar.immediateSlotId
      ? deleteImmediateSlotMutation
      : createImmediateSlotMutation,
    sequence: {
      'Delete Previous Immediate Slot': deleteImmediateSlotMutation,
      'Create New Immediate Slot': createImmediateSlotMutation,
      'Update Webinar immediateSlotId': updateWebinarMutation,
    },
  };
};
interface ISetupImmediateSlotWithRange {
  webinar: Webinar;
}
const SetupImmediateSlotWithRange: React.FC<ISetupImmediateSlotWithRange> = ({
  webinar,
}) => {
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(millisecondsInOne.minute * 3);
  const updateImmediateSlotMutations = useUpdateImmediateSlotMutations({
    webinar,
    payload: {
      activeMethod: immediateSlotSelectionMethod.randomBetweenRange,
      range: {
        start: webinarUtils.getFormattedTimer(start),
        end: webinarUtils.getFormattedTimer(end),
      },
    },
  });

  return (
    <ItemsContainer>
      <div>
        <p className="mb-2">Start: {webinarUtils.getFormattedTimer(start)}</p>
        <BottomBar
          value={start}
          onChange={(v) => {
            if (v <= end) {
              setStart(v);
            }
          }}
          roundedXmax={webinar.duration}
          xmax={webinar.duration}
        />
      </div>
      <div>
        <p className="mb-2">End: {webinarUtils.getFormattedTimer(end)}</p>
        <BottomBar
          value={end}
          onChange={(v) => {
            if (v >= start) {
              setEnd(v);
            }
          }}
          roundedXmax={webinar.duration}
          xmax={webinar.duration}
        />
      </div>
      <FilledButton
        onClick={() => {
          updateImmediateSlotMutations.primary.mutate();
        }}
      >
        Confirm
      </FilledButton>
      <MutationMessage mutations={updateImmediateSlotMutations.sequence} />
    </ItemsContainer>
  );
};
