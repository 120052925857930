import { HttpError } from '@pankod/refine-core';

import axios from 'axios';

import { authServer } from 'constants/environmentVars';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
});
const noCredentialsDomains = ['s3.ap-south-1.amazonaws.com'];
axiosInstance.interceptors.request.use((request) => {
  if (!noCredentialsDomains.some((v) => request.url.includes(v))) {
    request.withCredentials = true;
  }
  return request;
});
axiosInstance.interceptors.response.use(
  async (response) => {
    // console.log(response);
    //  TODO: We should add to admin auth restriction to particular routes in backend

    const isAdminResponse = await fetch(
      `${authServer}/users/is-webinar-administrator`,
      {
        credentials: 'include',
      }
    );
    const data = await isAdminResponse.json();
    // console.log(data);
    if (isAdminResponse.status === 200) {
      return response;
    } else {
      if (window.location.pathname !== '/login') {
        window.location.pathname = '/login';
      }
      response.data = undefined;
      return response;
    }
  },
  (error) => {
    if (
      error.response?.data?.error === 'Unauthenticated' ||
      error.response?.data?.error === 'Forbidden'
    ) {
      if (window.location.pathname !== '/login') {
        window.location.pathname = '/login';
      }
    }
    const customError: HttpError = {
      ...error,
      message: error.response?.data?.message,
      statusCode: error.response?.status,
    };

    return Promise.reject(customError);
  }
);

export default axiosInstance;
