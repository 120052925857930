import { useDelete, useExport, useNavigation } from '@pankod/refine-core';
import { Column, useFilters, usePagination, useSortBy, useTable } from '@pankod/refine-react-table';

import React from 'react';

import FilledButton from 'components/FilledButton';
import FiltersContainer from 'components/FiltersContainer';
import { CreateIcon } from 'components/icons';
import ListContainer from 'components/OuterContainer';

export const BulkUploadList: React.FC = () => {
  const { show, edit, create } = useNavigation();
  const { mutate } = useDelete();
  const { triggerExport, isLoading: exportLoading } = useExport<any>();

  const columns: Array<Column> = React.useMemo(() => [], []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    state: { pageIndex, pageSize, filters },
  } = useTable({ columns }, useFilters, useSortBy, usePagination);

  return (
    <ListContainer>
      <FiltersContainer>
        <div className="pl-2 flex">
          <FilledButton onClick={() => create('WebinarSlots')}>
            {CreateIcon}
            <span>Create Bulk Webinar Schedules</span>
          </FilledButton>
        </div>

        <div className="pl-2 flex">
          <FilledButton onClick={() => edit('BulkUploads', '')}>
            {CreateIcon}
            <span>Create Bulk Audio Books</span>
          </FilledButton>
        </div>

        <div className="pl-2 flex">
          <FilledButton onClick={() => edit('WebinarSlots', '')}>
            {CreateIcon}
            <span>Create Bulk Guides</span>
          </FilledButton>
        </div>

        <div className="pl-2 flex">
          <FilledButton onClick={() => create('BulkUploads')}>
            {CreateIcon}
            <span>Create Bulk Guide Resources</span>
          </FilledButton>
        </div>

        <div className="pl-2 flex">
          <FilledButton onClick={() => edit('hosts', '')}>
            {CreateIcon}
            <span>Create Bulk Hosts</span>
          </FilledButton>
        </div>

        {/* <div className="pl-2 flex">
          <FilledButton onClick={() => edit('featuredWorkshops', '')}>
            {CreateIcon}
            <span>Bulk Upload Thumbnails , Rating & More</span>
          </FilledButton>
        </div> */}
        
        {/* <div className="pl-2 flex">
          <FilledButton onClick={() => edit('messages', '')}>
            {CreateIcon}
            <span>Add Bulk Messages</span>
          </FilledButton>
        </div> */}

        <div className="pl-2 flex">
          <FilledButton onClick={() => edit('featuredWorkshops', '')}>
            {CreateIcon}
            <span>Enrol users to webinar</span>
          </FilledButton>
        </div>
      </FiltersContainer>
    </ListContainer>
  );
};
