import classNames from 'classnames';

interface IProfileNameProps {
  theme?: 'dark' | 'light';
  children: string;
  isHost?: boolean;
}
const ProfileName: React.FC<IProfileNameProps> = ({
  theme = 'light',
  children,
  isHost = false,
}) => {
  return (
    <div className="flex items-center space-x-1.5">
      <div
        data-test="profile-name"
        className={classNames({
          'font-semibold text-xs md:text-sm': true,
          'text-white': theme === 'dark',
          'text-[color:var(--text-main)] opacity-70': theme === 'light',
        })}
      >
        {children}
      </div>
      {isHost && (
        <>
          <p className="bg-green-800 w-1 h-1 rounded-full"></p>
          <p className="text-green-800 font-bold text-xs">Host</p>
        </>
      )}
    </div>
  );
};
export default ProfileName;
