import { AxiosResponse } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { UseMutationResult } from 'react-query';

import ExpandableItem from 'components/ExpandableItem';
import FilledButton from 'components/FilledButton';
import ItemsContainer from 'components/ItemsContainer';

import ErrorMessage from './ErrorMessage';
import SuccessMessage from './SuccessMessage';

interface IMutationMessage {
  mutation?: UseMutationResult<AxiosResponse<any>, unknown, any, unknown>;
  mutations?: Record<
    string,
    UseMutationResult<AxiosResponse<any>, unknown, any, unknown>
  >;
}
const MutationMessage: React.FC<IMutationMessage> = ({
  mutation,
  mutations,
}) => {
  const [confimedByUser, setConfirmedByUser] = useState(true);
  const statusOfMutations = useMemo(() => {
    let statuses: string[] = [];
    if (mutations) {
      statuses = Object.values(mutations).map((m) => m.status);
    }
    if (mutation) {
      statuses.push(mutation.status);
    }

    return statuses.join(':');
  }, [mutation, mutations]);
  useEffect(() => {
    const statuses = statusOfMutations.split(':');
    if (statuses.some((v) => v !== 'idle')) {
      setConfirmedByUser(false);
    } else {
      setConfirmedByUser(true);
    }
  }, [statusOfMutations]);
  if (confimedByUser) return null;
  return (
    <ItemsContainer>
      <div>
        {mutations ? (
          <div>
            {Object.entries(mutations).map(([s, m], i) => {
              return (
                <div>
                  {!m.isIdle && (
                    <p className="">
                      {s}: <SingleMutation key={i} mutation={m} />
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        ) : mutation ? (
          <SingleMutation mutation={mutation} />
        ) : null}
      </div>
      <FilledButton
        onClick={() => {
          setConfirmedByUser(true);
        }}
        variant="warning"
      >
        Dismiss
      </FilledButton>
    </ItemsContainer>
  );
};
const SingleMutation = ({
  mutation,
}: {
  mutation: UseMutationResult<AxiosResponse<any>, unknown, any, unknown>;
}) => {
  return mutation.isSuccess ? (
    <SuccessMessage />
  ) : mutation.isError ? (
    <>
      <ErrorAsJson error={mutation.error} />
    </>
  ) : mutation.isLoading ? (
    <div>Loading...</div>
  ) : null;
};
export default MutationMessage;
interface IErrorAsJson {
  error: any;
}
const ErrorAsJson: React.FC<IErrorAsJson> = ({ error }) => {
  const errorData = error.response.data;
  return (
    <div>
      <ErrorMessage>Error</ErrorMessage>
      <ExpandableItem itemsName="Error" data={errorData} showCount={false} />
    </div>
  );
};
