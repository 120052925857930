import { useForm } from '@pankod/refine-react-hook-form';
import axiosInstance from 'axiosInstance';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import FileUpload from 'components/FileUpload';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import InputErrorMessage from 'components/InputErrorMessage';
import MutationMessage from 'components/Messages/MutationMessage';
import { useEffect, useMemo, useRef, useState } from 'react';
import CategoryPill from 'components/CategoryPill';
import { CrossIcon , EditIcon, LoadingIcon } from 'components/icons';
import ItemsContainer from 'components/ItemsContainer';
import { useNavigation, useSelect } from '@pankod/refine-core';
import { AudioBook, AudioBookTopic } from 'interfaces/apiTypes';
import ReactHlsPlayer from 'react-hls-player/dist';
import IconButton from 'components/IconButton';
import { Link } from 'react-router-dom';

// Audio Books Show Page


export const ShowAudioBook: React.FC = () => {

  const { options } = useSelect({
    resource: 'authors',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });

  const playerRef = useRef<HTMLVideoElement>(null);

  const {
    refineCore: { formLoading },
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();



  const AddNewAudioBookTopic = useRef<HTMLInputElement>(null);

  const url = window.location.href.split('/');
  const audioBookId = url?.[url.length - 1];

  const navigation = useNavigation();
  const formValues = watch();

  const [uploadedVideo, setUploadedVideo] = useState<any>();
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [showAddNewTopicsInput, setshowAddNewTopicsInput] = useState<any>(false);

  

  // To get AudioBook Data with Id

  const audioBooksQuery = useQuery(
    ['AudioBooks', audioBookId],
    () =>
      axiosInstance.get<AudioBook>(
        `/v1/audio-books/${audioBookId}`
      ),
    {
      enabled: !!audioBookId,
    }
  );
  const audioBook = audioBooksQuery?.data?.data;

  console.log("---------------------",audioBook)

  useEffect(() => {
    (async () => {
      if (audioBook?.videoUuid) {
        // in case of editing
        // with the video id we need to populate selected video
        const videoResult = await axiosInstance.get(
          `${process.env.REACT_APP_CBC_SERVER}/videos/${audioBook?.videoUuid}`,
          {
            headers: {
              Authorization: process.env.REACT_APP_CBC_TOKEN,
            },
          }
        );
        setUploadedVideo(videoResult.data);
      }
    })();
  }, [audioBook?.videoUuid]);


  // To Get AudioBooks Topics to select topic while creating

  // const AudioBooksTopicsQuery = useQuery(['webinarTopics'], () =>
  //     axiosInstance.get<AudioBookTopic[]>(`/webinarTopics`)
  //   );
  //   const AudioBookTopics = AudioBooksTopicsQuery?.data?.data;


  const uploadThumbnailMutation = useMutation(
    ({
      thumbnailUrlBucket,
      thumbnailUrlPrefix,
      videoUuid,
    }: {
      thumbnailUrlBucket: string;
      thumbnailUrlPrefix: string;
      videoUuid: number;
    }) => {
      return axiosInstance.put(
        `${process.env.REACT_APP_CBC_SERVER}/videos/${videoUuid}/thumbnail`,
        {
          thumbnailUrlBucket,
          thumbnailUrlPrefix,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_CBC_TOKEN,
          },
        }
      );
    },
    {
      onSuccess: (response) => {
        //console.log(response.data);
        setUploadedVideo(response.data);
      },
    }
  );

  // All the Functions related to s3

  useEffect(() => {
    (async () => {
      if (formValues.videoUuid) {
        // in case of editing
        // with the video id we need to populate selected video
        console.log("-.-.-.--.-.-.-.-.-.-.- formValues.videoUuid" , formValues.videoUuid)
        const videoResult = await axiosInstance.get(
          `${process.env.REACT_APP_CBC_SERVER}/videos/${formValues.videoUuid}`,
          {
            headers: {
              Authorization: process.env.REACT_APP_CBC_TOKEN,
            },
          }
        );
        setUploadedVideo(videoResult.data);
      }
    })();
  }, [formValues.videoUuid]);

  const uploadVideoFromS3ToVideoContentProvidersMutation = useMutation(
    ({
      s3UrlBucket,
      s3UrlPrefix,
    }: {
      s3UrlBucket: string;
      s3UrlPrefix: string;
    }) => {
      return axiosInstance.post(
        `${process.env.REACT_APP_CBC_SERVER}/videos`,
        {
          s3UrlBucket,
          s3UrlPrefix,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_CBC_TOKEN,
          },
        }
      );
    },
    {
      onSuccess: (response) => {
        //console.log(response.data);
        setUploadedVideo(response.data);
      },
    }
  );

  useEffect(() => {
    if (uploadedVideo) {
      if (uploadedVideo?.streams?.preferred?.hls?.url) {
        setValue('videoUrl', uploadedVideo?.streams?.preferred?.hls?.url);
      }
      if (uploadedVideo.streams?.thumbnails?.png) {
        setValue('thumbnailUrl', uploadedVideo.streams.thumbnails.png);
      }
      setValue('s3UrlBucket', uploadedVideo.video.s3UrlBucket);
      setValue('s3UrlPrefix', uploadedVideo.video.s3UrlPrefix);
      setValue(
        'thumbnailUrlBucket',
        uploadedVideo.video.thumbnailUrlBucket ?? ''
      );
      setValue(
        'thumbnailUrlPrefix',
        uploadedVideo.video.thumbnailUrlPrefix ?? ''
      );
    }
  }, [setValue, uploadedVideo]); 


  //create new AudioBook Topics

  const [newAudioBookTopics, setNewAudioBookTopics] = useState<any>("");

  const createNewAudioBookTopics = useMutation(
    () => {
      AddNewAudioBookTopic.current.value = ""
      setNewAudioBookTopics(newAudioBookTopics.sort())
      setNewAudioBookTopics(newAudioBookTopics.filter((item: any, index: any) => newAudioBookTopics.indexOf(item) === index))
      // console.log('bro',newAudioBookTopics?.[0])
      return axiosInstance.post(`/v1/audioBookTopics` , {
        newAudioBookTopics
      })
    },
    {
      onSuccess: async (data) => {
        window.location.reload();
      },
    }
  )

  const audioBookTopicsQuery = useQuery(['audioBookTopics'], () =>
    axiosInstance.get<AudioBookTopic[]>(`/v1/audioBookTopics`)
  );
  const audioBookTopics = audioBookTopicsQuery?.data?.data;

  const existingTopics = useMemo(() => {
    //@ts-ignore
    return audioBook?.audioBookTopics?.map((item: { name: any; }) => item.name);
    //@ts-ignore
  }, [audioBook?.audioBookTopics]);

  useEffect(() => {
    if (existingTopics) {
      setSelectedTopics(existingTopics);
    }
  }, [existingTopics]);

  // create a new AudioBook
  const createAudioBookMutation = useMutation(
    () => {
      const { title, description , thumbnailUrlBucket , thumbnailUrlPrefix ,thumbnailUrl , authorId , learnings ,transcript , s3UrlBucket , s3UrlPrefix } = formValues;
      let audioBookAuthorId = Number(authorId)
      let audioBookLearnings = {}
      audioBookLearnings = learnings.split("|")
      let keys = ["data"]
      let learningsobject = {}
      for(let i = 0; i < keys.length; i++){
        learningsobject[keys[i]] = audioBookLearnings;
      }
      console.log("AudioBook being created is --------" , uploadedVideo)
      return axiosInstance.post(`/v1/audio-books`, {
        title,
        description,
        duration:uploadedVideo.video.durationInMilliseconds || undefined,
        audioBookTopics: selectedTopics,
        videoDuration: uploadedVideo.video.durationInMilliseconds || undefined,
        videoUuid: uploadedVideo.video.uuid,
        thumbnailUrl:thumbnailUrl,
        thumbnailPrefix:thumbnailUrlPrefix,
        thumbnailUrlBucket: thumbnailUrlBucket,
        authorId:audioBookAuthorId,
        learnings:learningsobject,
        transcript,
        s3UrlBucket,
        s3UrlPrefix
      });
    },
    {
      // onSuccess: async (data) => {
      //   const audioBooks = data.data;
      //   navigation.push(`/AudioBooks/edit/${audioBooks.id}`);
      // },
      // onError: async()=>{
      //   window.location.reload();
      //   navigation.push(`/AudioBooks/create`);
      // }
    },
  );

  // Update an existing AudioBook
  const updateAudioBookMutation = useMutation(
    () => {
      const { name, fbqPixelId , preHeader } = formValues;
      let webinarMetaDataContent:any;
      return axiosInstance.post(`/v1/webinars`, {
        name,
        preHeader:preHeader,
        fbqPixelId: fbqPixelId || undefined,
        videoDuration: uploadedVideo.video.durationInMilliseconds || undefined,
        videoUuid: uploadedVideo.video.uuid,
        webinarMetadata: webinarMetaDataContent,
      });
    },
    {
      onSuccess: async (data) => {
        const webinar = data.data;
        navigation.push(`/webinars/show/${webinar.id}`);
      },
      onError: async()=>{
        window.location.reload();
        navigation.push(`/webinars/create`);
      }
    },
  );

  
  return (
    <OuterContainer>
      <div className="flex justify-end">
        <Link to={`/audio-books/edit/${audioBook?.id}`}>
          <IconButton onClick={() => {}}>{EditIcon}</IconButton>
        </Link>
      </div>
      <form autoComplete='off'
        onSubmit={handleSubmit(async (data) => {
          if (audioBook) {
            updateAudioBookMutation.mutate();
          } else {
            createAudioBookMutation.mutate();
          }
        })}
      >
        <ItemsContainer>
        <div className="mt-8 space-y-4">
          <div>
            {/* <label htmlFor="TopicInput" className="my-label">
                Select Audio Book Topics
              </label>
              <select
                className="my-input mt-[12px]"
                value={''}
                defaultValue=""
                onChange={(e) => {
                  const newTopic = e.target.value;
                  if (!selectedTopics.includes(newTopic)) {
                    setSelectedTopics([...selectedTopics, newTopic]);
                  }
                }}
              >
              <option value={''} disabled>
                Please select
              </option>
              {audioBookTopics?.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
              </select>
                <br/>
                <p>{audioBook?.audioBookTopics}</p>
              <div className="flex gap-x-2">
                {selectedTopics?.map((item) => {
                  return (
                    <CategoryPill key={item}>
                      <p>{item}</p>
                      <div
                        onClick={() =>
                          setSelectedTopics((prev) => {
                            return prev.filter((i) => i !== item);
                          })
                        }
                        className="w-[16px] cursor-pointer"
                      >
                        {CrossIcon}
                      </div>
                    </CategoryPill>
                  );
                })}
              </div> */}

              

              {showAddNewTopicsInput  &&
               <div className='pt-[20px]'>
                <label htmlFor="topics" className="my-label">
                  Enter Topics (Comma , seperate multiple topics)
                </label>
                
                <input
                  ref={AddNewAudioBookTopic}
                  type="text"
                  id="AudioBookTopics"
                  className="my-input"
                  placeholder="Email Marketing,Growth Hacking,Designing "
                  autoComplete='off'
                  onChange={(e)=>{
                    let newAudioBookTopics = e.target.value;
                    newAudioBookTopics = newAudioBookTopics.toLowerCase()
                    setNewAudioBookTopics(newAudioBookTopics.split(","))
                  }}
                />
                <button
                  type="button"
                  className="px-1 py-2 my-6 border-2 border-purple-400 rounded-md hover:bg-purple-200" 
                  onClick={()=>{createNewAudioBookTopics.mutate()}}
                >
                  Add Topics
                </button>
              </div>
             } 
          </div>

          {!audioBook && <div className="mt-8 space-y-4">
            <div>
              <label htmlFor="authorId" className="my-label">
                Select Author
              </label>
              <input
                id="authorId"
                list="authors"
                name="authorId"
                className="my-input"
                defaultValue={''}
                value={audioBook?.authorId}
                disabled
                {...register('authorId', { required: true })}
              />
              <datalist id="authors">
                <option value={''} disabled>
                  Please select
                </option>
                {options?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </datalist>
              </div>
          </div>}

          {audioBook && <div>
            <label htmlFor="title" className="my-label">Author Id</label>
            <input
              className="my-input"  type="text" id="title" placeholder='Enter Title' disabled value={audioBook?.authorId}
            />
          </div>}

          {/* {audioBook && <div>
            <label htmlFor="title" className="my-label">Author Name</label>
            <input
              className="my-input"  type="text" id="title" placeholder='Enter Title' disabled value={audioBook?.authorName}
            />
          </div>} */}

          <div>
            <label htmlFor="title" className="my-label">Title</label>
            <input
              {...register('title', { required: true })}
              className="my-input"  type="text" id="title" placeholder='Enter Title' disabled value={audioBook?.title}
            />
          </div>

          <div>
            <label className="my-label">Description</label>
            <input
              {...register('description', { required: true })}
              className="my-input" disabled value={audioBook?.description}
            />
          </div>

          { audioBook && 
          
          <div>
            <div>
              <label className="my-label">Affiliate Link</label>
              <input
                {...register('affiliateLink', { required: true })}
                className="my-input" disabled value={audioBook?.affiliateLink}
              />
            </div>
            <br/>
            <div>
              <label className="my-label">Author Id</label>
              <input
                {...register('authorId', { required: true })}
                className="my-input" disabled value={audioBook?.authorId}
              />
            </div>
            <br/>
            <div>
              <label className="my-label">Is Featured</label>
              <input
                {...register('isFeatured', { required: true })}
                className="my-input" disabled value={audioBook?.isFeatured}
              />
            </div>
            <br/>
            <div>
            <label className="my-label">Enter Learnings (Minimum 3 - Seperate them with | )</label>
            <div className="flex gap-x-2">
              {audioBook?.learnings?.data.map((learning: any) => {
                return (
                  <CategoryPill key={learning}>
                    <p>{learning}</p>
                  </CategoryPill>
                );
              })}
            <br />
            </div>
          </div>
          </div>
          
          }

          <div>
            <label className="my-label">Enter Transcript</label>
            <input
              {...register('transcript', { required: true })}
              className="my-input"
              placeholder='' disabled value={audioBook?.transcript}
            />
          </div>

          {/* Add Video Section Starts */}

          <div>
            <label htmlFor="s3UrlBucket" className="my-label">
              Video Bucket
            </label>
            <input
              {...register('s3UrlBucket', { required: true })}
              type="text"
              id="s3UrlBucket"
              className="my-input"
              placeholder="assets.growthschool.io"
            />
          </div>
          <div>
            <label htmlFor="s3UrlPrefix" className="my-label">
              Video Prefix
            </label>
            <input
              {...register('s3UrlPrefix', { required: true })}
              type="text"
              id="s3UrlPrefix"
              className="my-input"
              placeholder="167ce2b0-50da-47c9-9f6a-37bbadaea18c"
            />
          </div>
          <div>
            <ReactHlsPlayer
              playerRef={playerRef}
              src={uploadedVideo?.streams?.preferred?.hls?.url}
              controls
            />
          </div>
        

          {/* Add Video Section ends */}
          <label htmlFor="thumbnailUrl" className="my-label">
              Audio Book Thumbnail 
            </label>
            <input
              {...register('thumbnailUrl', { required: true })}
              type="text"
              id="thumbnailUrl"
              className="my-input"
              value={audioBook?.thumbnailUrl}
            />
          <img src={audioBook?.thumbnailUrl} width="250px" height="250px" alt='audioBook Thumbnail' />
        </div>
        <br/>
        
      </ItemsContainer>
      </form>
      <MutationMessage mutation={createAudioBookMutation} />
      <MutationMessage mutation={updateAudioBookMutation} />
    </OuterContainer>
  );
};
