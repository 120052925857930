import { TPoint } from 'interfaces/web';

interface ILineProps {
  start: TPoint;
  end: TPoint;
}
const Line: React.FC<ILineProps> = ({ start, end }) => {
  const length = Math.sqrt(
    Math.pow(end.left - start.left, 2) + Math.pow(end.top - start.top, 2)
  );
  const slope = (end.top - start.top) / (end.left - start.left);
  const degree = (Math.atan(slope) * 180) / Math.PI;
  return (
    <div
      className={`
    absolute bg-black
  `}
      style={{
        top: start.top,
        left: start.left,
        height: 1,
        width: length,
        transform: `rotate(${degree}deg)`,
        transformOrigin: 'top left',
      }}
    ></div>
  );
};
export default Line;
