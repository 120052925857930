import { useRef, useState } from 'react';

import RevealContent from 'components/Shared/RevealContent';

import ExpandableDropdown from './ExpandableDropdown';

interface IExpandableItemProps {
  itemsName: string;
  data: Array<any> | Record<string, any>;
  showCount?: boolean;
}
const ExpandableItem: React.FC<IExpandableItemProps> = ({
  itemsName,
  data,
  showCount = true,
}) => {
  const [showData, setShowData] = useState(false);
  const dataContainerRef = useRef<HTMLDivElement>(null);
  return (
    <div>
      <ExpandableDropdown
        onClick={() => setShowData((prev) => !prev)}
        expanded={showData}
        itemsName={itemsName}
        length={
          data
            ? Array.isArray(data)
              ? data.length
              : Object.keys(data).length
            : 0
        }
        countable={showCount}
      />

      <div ref={dataContainerRef}>
        <RevealContent
          parentRef={dataContainerRef}
          show={showData}
          makeBlankOnCollapse={true}
        >
          <div className="max-h-[50vh] overflow-auto">
            <pre className="py-2 overflow-auto">
              {JSON.stringify(data, null, 2)}
            </pre>
          </div>
        </RevealContent>
      </div>
    </div>
  );
};
export default ExpandableItem;
