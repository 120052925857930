import { useNavigation, useResource } from '@pankod/refine-core';
import routerProvider from '@pankod/refine-react-router-v6';

import { GlobalContext } from 'contexts/GlobalContext';
import { useContext } from 'react';
import { useQueryClient } from 'react-query';

import FilledButton from './FilledButton';

const { Link } = routerProvider;
interface IRefreshButton {}
const RefreshButton: React.FC<IRefreshButton> = () => {
  const queryClient = useQueryClient();
  return (
    <FilledButton
      onClick={() => {
        queryClient.invalidateQueries();
      }}
      data-test="refresh-button"
    >
      Refresh
    </FilledButton>
  );
};
export const Layout: React.FC = ({ children }) => {
  const { resources } = useResource();
  const { list } = useNavigation();
  const { currentUser } = useContext(GlobalContext);

  return (
    <div className="flex min-h-screen flex-col">
      <div className="fixed bottom-0 right-0">
        <RefreshButton />
      </div>
      <div className="mb-2 border-b py-2">
        <div className="container mx-auto">
          <div className="flex items-center gap-2">
            <Link to="/">
              <img className="w-8" src="/../favicon.png" alt="Logo" />
            </Link>
            <ul className="flex w-full">
              {currentUser && currentUser.isAdmin && (
                <>
                  {resources.map(({ name, icon }) => (
                    <li key={name}>
                      <button
                        className="flex cursor-pointer items-center gap-1 rounded-sm px-2 py-1 capitalize decoration-indigo-500 decoration-2 underline-offset-1 transition duration-300 ease-in-out hover:underline"
                        onClick={() => list(name)}
                      >
                        {icon}
                        <span>{name}</span>
                      </button>
                    </li>
                  ))}
                </>
              )}
              <div className="flex-1"></div>
              {!currentUser ? (
                <Link to="/login">
                  <li key={'login'}>
                    <button className="flex cursor-pointer items-center gap-1 rounded-sm px-2 py-1 capitalize decoration-indigo-500 decoration-2 underline-offset-1 transition duration-300 ease-in-out hover:underline">
                      Login
                    </button>
                  </li>
                </Link>
              ) : currentUser.isAdmin ? (
                <li>
                  {currentUser.name}-({currentUser.emails[0]})({currentUser.id})
                </li>
              ) : (
                <li className="text-red-500">
                  {currentUser.name}-({currentUser.emails[0]})({currentUser.id})
                  is not Administrator
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-white">{children}</div>
    </div>
  );
};
