import { useEffect, useState } from 'react';
import { useNavigation, useSelect } from '@pankod/refine-core';
import { useForm } from '@pankod/refine-react-hook-form';

import axiosInstance from 'axiosInstance';
import { SubscriptionTier } from 'interfaces/apiTypes';
import { useMutation, useQuery } from 'react-query';

import { LoadingIcon } from 'components/icons';
import ItemsContainer from 'components/ItemsContainer';
import MutationMessage from 'components/Messages/MutationMessage';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';

export const Payments: React.FC = () => {
  const { options } = useSelect({
    resource: 'hosts',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });

  const {
    refineCore: { formLoading },
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const navigation = useNavigation();
  const formValues = watch();

  const [tierType, setTierType] = useState<string>('');

  const url = window.location.href.split('/');
  const subscriptionTierId = url?.[url.length - 1];
  
  const paymentsQuery = useQuery(
    ['subscriptionTiers', subscriptionTierId],
    () =>
      axiosInstance.get<SubscriptionTier>(
        `/v1/subscriptionTiers/${subscriptionTierId}`
      ),
    {
      enabled: !!subscriptionTierId,
    }
  );
  const subscriptionTier = paymentsQuery?.data?.data;

  // Set the initial tierType, description, productId, and paymentLinkId when subscriptionTier is fetched
  useEffect(() => {
    if (subscriptionTier) {
      setTierType(subscriptionTier.tier);
      setValue('description', subscriptionTier.description);
      setValue('productId', subscriptionTier.productId || '');
      setValue('paymentLinkId', subscriptionTier.paymentLinkId || '');
    }
  }, [subscriptionTier, setValue]);

  console.log(subscriptionTier);

  // Create a new Subscription Tier
  const createSubscriptionTierMutation = useMutation(
    () => {
      const { price, maxRetailPrice, description, productId, paymentLinkId } = formValues;
      return axiosInstance.post(`/v1/subscriptionTiers`, {
        tier: tierType,
        price,
        maxRetailPrice,
        description,
        productId: productId ? Number(productId) : undefined,
        paymentLinkId: paymentLinkId ? paymentLinkId : undefined, // Ensuring it's a string
      });
    },
    {
      onSuccess: async () => {
        navigation.push(`/subscriptionTiers`);
      },
    }
  );

  // Update Subscription Tier
  const updateSubscriptionTierMutation = useMutation(
    () => {
      const { price, maxRetailPrice, description, productId, paymentLinkId } = formValues;
      return axiosInstance.patch(
        `/v1/subscriptionTiers/${subscriptionTier.id}`,
        {
          price,
          maxRetailPrice,
          description,
          productId: productId ? Number(productId) : undefined,
          paymentLinkId: paymentLinkId ? paymentLinkId : undefined, // Changed to string
        }
      );
    },
    {
      onSuccess: async () => {
        navigation.push(`/subscriptionTiers`);
      },
    }
  );

  return (
    <OuterContainer>
      <form
        onSubmit={handleSubmit(async (data) => {
          if (!subscriptionTier) {
            createSubscriptionTierMutation.mutate();
          } else {
            updateSubscriptionTierMutation.mutate();
          }
        })}
      >
        <ItemsContainer>
          {/* Select Tier */}
          <div>
            <label htmlFor="webinarTier" className="my-label">
              Choose Tier
            </label>
            <select
              required
              className="my-input mt-[12px]"
              value={tierType}
              onChange={(e) => {
                setTierType(e.target.value);
              }}
            >
              <option value="" disabled>
                Select Tier
              </option>
              <option value="Tier1">Tier 1</option>
              <option value="Tier2">Tier 2</option>
              <option value="Tier3">Tier 3</option>
              <option value="Tier4">Tier 4</option>
            </select>
          </div>

          {/* Description Field */}
          <div>
            <label htmlFor="description" className="my-label">
              Description
            </label>
            <input
              {...register('description', { required: true })}
              type="text"
              id="description"
              className="my-input"
              placeholder="Enter Description"
              autoComplete="off"
            />
            {errors.description && (
              <span className="text-red-500">Description is required</span>
            )}
          </div>

          {/* Product ID Field */}
          <div>
            <label htmlFor="productId" className="my-label">
              Product ID
            </label>
            <input
              {...register('productId', {
                required: true,
                valueAsNumber: true,
                validate: value => !isNaN(value) || 'Product ID must be a number',
              })}
              type="number"
              id="productId"
              className="my-input"
              placeholder="Enter Product ID"
              autoComplete="off"
            />
            {errors.productId && (
              <span className="text-red-500">
                {errors.productId.message || 'Product ID is required'}
              </span>
            )}
          </div>

          {/* Payment Link ID Field */}
          <div>
            <label htmlFor="paymentLinkId" className="my-label">
              Payment Link ID
            </label>
            <input
              {...register('paymentLinkId', {
                required: 'Payment Link ID is required',
              })}
              type="text" 
              id="paymentLinkId"
              className="my-input"
              placeholder="Enter Payment Link ID"
              autoComplete="off"
            />
            {errors.paymentLinkId && (
              <span className="text-red-500">
                {errors.paymentLinkId.message || 'Payment Link ID is required'}
              </span>
            )}
          </div>

          {/* Price Field */}
          <div>
            <label htmlFor="price" className="my-label">
              Price
            </label>
            <input
              {...register('price', { required: true })}
              type="number"
              id="price"
              className="my-input"
              placeholder="Enter Price"
              autoComplete="off"
            />
            {errors.price && (
              <span className="text-red-500">Price is required</span>
            )}
          </div>

          {/* Max Retail Price Field */}
          <div>
            <label htmlFor="maxRetailPrice" className="my-label">
              Max Retail Price
            </label>
            <input
              {...register('maxRetailPrice', { required: true })}
              type="number"
              id="maxRetailPrice"
              className="my-input"
              placeholder="Enter Max Retail Price"
              autoComplete="off"
            />
            {errors.maxRetailPrice && (
              <span className="text-red-500">
                Max Retail Price is required
              </span>
            )}
          </div>

          <SaveButton type="submit">
            {formLoading && LoadingIcon}
            <span>Save</span>
          </SaveButton>
        </ItemsContainer>
      </form>
      <MutationMessage mutation={createSubscriptionTierMutation} />
      <MutationMessage mutation={updateSubscriptionTierMutation} />
    </OuterContainer>
  );
};