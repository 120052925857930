interface ICategoryPillProps {
  children: any;
}
const CategoryPill: React.FC<ICategoryPillProps> = ({ children }) => {
  return (
    <div className="bg-[#efebeb] border-[1px] border-solid rounded-[20px] p-[6px] flex gap-x-1 items-center">
      {children}
    </div>
  );
};
export default CategoryPill;
