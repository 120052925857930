import InputErrorMessage from './InputErrorMessage';

interface IFieldRequiredErrorMessageProps {}
const FieldRequiredErrorMessage: React.FC<
  IFieldRequiredErrorMessageProps
> = () => {
  return (
    <InputErrorMessage>
      <span className="font-medium">Oops!</span> This field is required
    </InputErrorMessage>
  );
};
export default FieldRequiredErrorMessage;
