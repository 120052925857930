import { useDelete, useExport, useNavigation } from '@pankod/refine-core';
import { Column, useFilters, usePagination, useSortBy, useTable } from '@pankod/refine-react-table';

import React from 'react';

import ActionCell from 'components/ActionCell';
import FilledButton from 'components/FilledButton';
import FilterBlock from 'components/FilterBlock';
import FiltersContainer from 'components/FiltersContainer';
import GenericTable from 'components/GenericTable';
import { CreateIcon } from 'components/icons';
import ListContainer from 'components/OuterContainer';
import PageNavigation from 'components/PageNavigation';

export const FeaturedWorkshops: React.FC = () => {
  const { show, edit, create } = useNavigation();
  const { mutate } = useDelete();
  const { triggerExport, isLoading: exportLoading } = useExport<any>();

  
  return (
    <ListContainer>
      <FiltersContainer>

        <div className="pl-2 flex">
          <FilledButton onClick={() => create('featuredWorkshops')}>
            {CreateIcon}
            <span>Create Featured Workshop</span>
          </FilledButton>
        </div>
      </FiltersContainer>


    </ListContainer>
  );
};