interface IProfilePicProps {
  name: string;
  profileColor?: string;
}
const ProfilePic: React.FC<IProfilePicProps> = ({ name, profileColor }) => {
  return (
    <div
      className="w-8 h-8 rounded-full flex-center font-medium border border-black"
      style={{
        backgroundColor: profileColor ?? 'white',
        color: profileColor ? 'white' : 'black',
      }}
    >
      {name[0]}
    </div>
  );
};
export default ProfilePic;
