
import axiosInstance from 'axiosInstance';
import Papa from 'papaparse';
import { useContext, useRef, useState } from 'react';
import { useMutation } from 'react-query';

import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import axios from 'axios';
import { GlobalContext } from 'contexts/GlobalContext';

// Allowed input file
const allowedExtensions = ['csv'];

export const WebinarThumbnailsBulkCreate: React.FC = () => {
  const { currentUser } = useContext(GlobalContext);

  const [CreationStatus, setCreationStatus] = useState('');

  // This state will store the parsed data
  const [data, setData] = useState([]);

  // It state will contain the error when
  // correct file extension is not used
  const [error, setError] = useState('');

  // store the file uploaded by the user
  const [file, setFile] = useState();

  const handleFileChange = (e) => {
    setError('');

    // Check if user has entered the file
    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      // Check the file extensions, if it is not in allowed format throw error
      const fileExtension = inputFile?.type.split('/')[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setError('Please input a csv file');
        return;
      }

      // If input type is correct set the state
      setFile(inputFile);
    }
  };

  const HandleParse = () => {
    // If user clicks the parse button without a file we show a error
    if (!file) return setError('Enter a valid file');

    // Initialize a reader which allows user to read any file or blob.
    const reader = new FileReader();

    // Event listener on reader when the file loads, we parse it and set the data.
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      const parsedData = csv?.data;
      setData(parsedData);
      createScheduleMutation.mutate(parsedData);
    };

    reader.readAsText(file);
  };

  const createScheduleMutation = useMutation(
    (parsedData) => {
      console.log('User Details', parsedData);
      return axios.post('https://outskill-api.growthschool.io/v1/webinarSignups/bulk', {
        parsedData,
      });
    },
    {
      onSuccess: (response) => {
        setCreationStatus('SuccessFully Enrolled Users');
      },
      onError: async () => {
        setCreationStatus('User Enrolment failed check once');
      },
    }
  );

  return (
    <OuterContainer>
      <div>
        <label htmlFor="csvInput" style={{ display: 'block' }}>
          {/* Upload Webinar Thumbnails CSV File - */}
          Upload Sheet with User Details
        </label>
        <br/>
        <a href='https://docs.google.com/spreadsheets/d/174WPc3IlVWZIvcWG9u6wmnskGwlp2_BL8Naxhp90q5c/edit?usp=sharing' style={{ textDecoration: 'underline', color: 'blue', display: 'block', marginTop: '10px' }}>
            Click here for Template CSV
         </a>
        <br />
        <input
          onChange={handleFileChange}
          id="csvInput"
          name="file"
          type="File"
        />
        <br />
        <br />
       
          {(currentUser?.emails[0] === 'sri@growthschool.io' || currentUser?.emails[0] === 'bhargava@growthschool.io') &&  <div>
          <SaveButton onClick={HandleParse}>Upload Users</SaveButton>
        </div>}
        <br />
        <h3 className="text-[25px]">{CreationStatus}</h3>
      </div>
    </OuterContainer>
  );
};
