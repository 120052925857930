import useCreateWebinarScheduleMutation from 'apiHooks.ts/useCreateWebinarScheduleMutation';
import { Webinar } from 'interfaces/apiTypes';
import { useMemo, useState } from 'react';

import { DEFAULT_MILLISECONDS_AFTER_WHICH_WEBINAR_SCHEDULE_WILL_EXPIRE_AFTER_WEBINAR_STARTS } from 'constants/generalConstants';
import webinarUtils from 'utils/webinarUtils';

import FilledButton from './FilledButton';
import BottomBar from './GenericGraph/Children/BottomBar';
import ItemsContainer from './ItemsContainer';
import MutationMessage from './Messages/MutationMessage';

interface IScheduleWebinarAtRelativeTime {
  webinar: Webinar;
}
const ScheduleWebinarAtRelativeTime: React.FC<
  IScheduleWebinarAtRelativeTime
> = ({ webinar }) => {
  const [timestamp, setTimestamp] = useState('');
  const [expiryBarValue, setExpiryBarValue] = useState(
    DEFAULT_MILLISECONDS_AFTER_WHICH_WEBINAR_SCHEDULE_WILL_EXPIRE_AFTER_WEBINAR_STARTS
  );

  const dateMinusTimestamp = useMemo(() => {
    if (timestamp) {
      const delay = webinarUtils.timeStampToMilliseconds(timestamp);
      const currentDate = new Date();
      const date = new Date(currentDate.getTime() + delay);
      return date;
    }
    return new Date();
  }, [timestamp]);
  const createWebinarScheduleMutation = useCreateWebinarScheduleMutation({
    startTime: dateMinusTimestamp,
    expireAt: webinarUtils.getFormattedTimer(expiryBarValue),
    webinarId: webinar.id,
    type: 'particularTime',
  });

  return (
    <ItemsContainer>
      <input
        data-test="RelativeScheduleInput"
        className="my-input"
        placeholder="00:00:00:00"
        value={timestamp}
        onChange={(e) => {
          setTimestamp(e.target.value);
        }}
      />
      <p>
        Above Schedule will expire at:{' '}
        {webinarUtils.getFormattedTimer(expiryBarValue)}
      </p>
      <BottomBar
        value={expiryBarValue}
        xmax={webinar.duration}
        roundedXmax={webinar.duration}
        onChange={(v) => setExpiryBarValue(v)}
      />
      <FilledButton data-test="schedule-next-minute"
        onClick={() => {
          createWebinarScheduleMutation.mutate();
        }}
      >
        Schedule At {dateMinusTimestamp.toLocaleString()}
      </FilledButton>
      <MutationMessage
        mutation={createWebinarScheduleMutation}
      ></MutationMessage>
    </ItemsContainer>
  );
};
export default ScheduleWebinarAtRelativeTime;
