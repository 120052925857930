import { useNavigation, useSelect } from '@pankod/refine-core';
import { useForm } from '@pankod/refine-react-hook-form';

import axiosInstance from 'axiosInstance';
import { Host, SubscriptionTier, Webinar, WebinarCategory, WebinarTopic } from 'interfaces/apiTypes';
import { useEffect, useMemo, useState , useRef } from 'react';
import { useMutation, useQuery } from 'react-query';

import CategoryPill from 'components/CategoryPill';
import FileUpload from 'components/FileUpload';
import { CrossIcon, LoadingIcon } from 'components/icons';
import InputErrorMessage from 'components/InputErrorMessage';
import ItemsContainer from 'components/ItemsContainer';
import MutationMessage from 'components/Messages/MutationMessage';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import webinarUtils from 'utils/webinarUtils';
import classNames from 'classnames';

// some comment
export const HostShow: React.FC = () => {


  const { options } = useSelect({
    resource: 'hosts',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });

  const {
    refineCore: { formLoading },
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const formValues = watch();

  const url = window.location.href.split('/');
  const hostId = url?.[url.length - 1];

  const hostsQuery = useQuery(
    ['host', hostId],
    () =>
      axiosInstance.get<Host>(
        `/v1/hosts/${hostId}`
      ),
    {
      enabled: !!hostId,
    }
  );
  const hostData = hostsQuery?.data?.data;

  return (
    <OuterContainer>
      <form>
        <ItemsContainer>

        <div className="mt-8 space-y-4">
          <div>
            <label className="my-label">Host Name</label>
            <input
              {...register('name', { required: true })}
              className="my-input"
              value={hostData?.name}
            />
          </div>
          <div>
            <label className="my-label">Host Title (Designation)</label>
            <input
              {...register('title', { required: true })}
              className="my-input"
              value={hostData?.title}
            />
          </div>
          <div>
            <label className="my-label">Company</label>
            <input
              {...register('company', { required: true })}
              className="my-input"
              value={hostData?.company}
            />
          </div>
          <div>
            <label className="my-label">Picture Bucket</label>
            <input
              {...register('pictureUrlBucket', { required: true })}
              className="my-input"
              value={hostData?.pictureUrlBucket}
            />
          </div>
          <div>
            <></>
            <label className="my-label">Picture Prefix</label>
            <input
              {...register('pictureUrlPrefix', { required: true })}
              className="my-input"
              value={hostData?.pictureUrlPrefix}
            />
          </div>
          <div>
            <></>
            <label className="my-label">Picture Url</label>
            <input
              {...register('pictureUrl', { required: true })}
              className="my-input"
              value={hostData?.pictureUrl}
            />
          </div>
        </div>
        </ItemsContainer>
      </form>
    </OuterContainer>
  );
};
