import { useForm } from '@pankod/refine-react-hook-form';
import axiosInstance from 'axiosInstance';
import { useMutation, useQuery } from 'react-query';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import CategoryPill from 'components/CategoryPill';
import { CrossIcon } from 'components/icons';
import { useState } from 'react';
import MutationMessage from 'components/Messages/MutationMessage';

export const RemoveSeriesFromCollection: React.FC = () => {
  const { register, handleSubmit } = useForm();

  const [selectedCollection, setSelectedCollection] = useState<string | null>(null);
  const [selectedSeries, setSelectedSeries] = useState<string[]>([]);

  const { data: collectionsData } = useQuery('collections', () =>
    axiosInstance.get<any[]>('/v1/series/collections')
  );
  const collections = collectionsData?.data;



  const { data: seriesData } = useQuery(
    ['series', selectedCollection],
    () => axiosInstance.get<any[]>(`/v1/series/${selectedCollection}/collection`),
    { enabled: !!selectedCollection }
  );
  const series = seriesData?.data;

  const removeSeriesMutation = useMutation(
    () => {
      const seriesIdsAsNumbers = selectedSeries.map((seriesId) => Number(seriesId));
  
      const collectionIdAsNumber = Number(selectedCollection);
  
      return axiosInstance.request({
        method: 'DELETE',
        url: '/v1/series/remove-series',
        data: {
          seriesIds: seriesIdsAsNumbers,
          collectionId: collectionIdAsNumber,
        },
      });
    },
    {
      onSuccess: () => {
        setSelectedSeries([]);
        setSelectedCollection(null);
      },
      onError: (error) => {
        console.error('Failed to remove series:', error);
      },
    }
  );

  return (
    <OuterContainer>
      <h2 className="text-2xl font-bold text-gray-800 mb-4 pt-10">
        Remove Series from Collection
      </h2>

      <form
        onSubmit={handleSubmit(() => {
          removeSeriesMutation.mutate();
        })}
        className="mt-4 space-y-4"
      >
        <div>
          <label htmlFor="seriesSelect" className="my-label">
            Select Collection
          </label>
          <select
            id="collectionSelect"
            className="my-input mt-[12px]"
            value={selectedCollection || ''}
            onChange={(e) => setSelectedCollection(e.target.value)}
          >
            <option value="" disabled>
              Please select
            </option>
            {collections?.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}-{option.id}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="seriesSelect" className="my-label">
            Select Series
          </label>
          <select
            id="seriesSelect"
            className="my-input mt-[12px]"
            value=""
            onChange={(e) => {
              const newSeries = e.target.value;
              if (!selectedSeries.includes(newSeries)) {
                setSelectedSeries([...selectedSeries, newSeries]);
              }
            }}
          >
            <option value="" disabled>
              Please select
            </option>
            {series?.map((option) => (
              <option key={option.series_id} value={option.series_id}>
                {option.series_name}-{option.series_id}
              </option>
            ))}
          </select>

          <div className="flex gap-x-2 mt-2">
            {selectedSeries.map((seriesId) => (
              <CategoryPill key={seriesId}>
                <p>{seriesId}</p>
                <div
                  onClick={() =>
                    setSelectedSeries((prev) => prev.filter((id) => id !== seriesId))
                  }
                  className="w-[16px] cursor-pointer"
                >
                  {CrossIcon}
                </div>
              </CategoryPill>
            ))}
          </div>
        </div>

        <SaveButton type="submit">
          {removeSeriesMutation.isLoading
            ? 'Removing...'
            : 'Remove Series from Collection'}
        </SaveButton>
      </form>
      <MutationMessage mutation={removeSeriesMutation} />
    </OuterContainer>
  );
};