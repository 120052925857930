import IconButton from './IconButton';
import { DeleteIcon, EditIcon, ShowIcon } from './icons';

interface IActionCellProps {
  edit?: () => void;
  show?: () => void;
  remove?: () => void;
}
const ActionCell: React.FC<IActionCellProps> = ({ edit, show, remove }) => {
  return (
    <div className="flex gap-2">
      {edit && <span data-test="edit-button"><IconButton onClick={edit}>{EditIcon}</IconButton></span>}
      {show && <span data-test="show-button"><IconButton onClick={show}>{ShowIcon}</IconButton></span>}
      {remove && <IconButton onClick={remove}>{DeleteIcon}</IconButton>}
    </div>
  );
};
export default ActionCell;
