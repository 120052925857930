import axiosInstance from 'axiosInstance';
import { Webinar } from 'interfaces/apiTypes';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import endpoints from 'constants/endpoints';

import FilledButton from './FilledButton';
import ItemsContainer from './ItemsContainer';
import MutationMessage from './Messages/MutationMessage';

interface ISetupPermanentSlots {
  webinar: Webinar;
}
const SetupPermanentSlots: React.FC<ISetupPermanentSlots> = ({ webinar }) => {
  const queryClient = useQueryClient();
  const [value, setValue] = useState('');
  const updateWebinarMutation = useMutation(
    () => {
      return axiosInstance.patch(`${endpoints.webinars}/${webinar.id}`, {
        permanentSlots: value ? value.split(',') : [],
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
    }
  );
  return (
    <ItemsContainer>
      <p className="text-xl">Setup Permanent Slots</p>
      <p>Current Permanent Slots: {JSON.stringify(webinar.permanentSlots)}</p>
      <input
        className="my-input"
        placeholder="01:00:00:00,00:06:00:00"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <FilledButton
        onClick={() => {
          updateWebinarMutation.mutate();
        }}
      >
        Confirm
      </FilledButton>
      <MutationMessage mutation={updateWebinarMutation} />
    </ItemsContainer>
  );
};
export default SetupPermanentSlots;
