import { useNavigation, useSelect } from '@pankod/refine-core';
import { useForm } from '@pankod/refine-react-hook-form';

import axiosInstance from 'axiosInstance';
import { SubscriptionTier } from 'interfaces/apiTypes';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { LoadingIcon } from 'components/icons';
import ItemsContainer from 'components/ItemsContainer';
import MutationMessage from 'components/Messages/MutationMessage';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';

// some comment
export const Coupons: React.FC = () => {
  const { options } = useSelect({
    resource: 'hosts',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });

  const {
    refineCore: { formLoading },
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();

  const url = window.location.href.split('/');
  const couponId = url?.[url.length - 1];
  const navigation = useNavigation();
  const formValues = watch();
  const [uploadedVideo, setUploadedVideo] = useState<any>();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [webinarType, setWebinarType] = useState<string>('');

  const [tierType, setTierType] = useState<any>();

  const couponsQuery = useQuery(
    ['coupons', couponId],
    () => axiosInstance.get<SubscriptionTier>(`/v1/coupons/${couponId}`),
    {
      enabled: !!couponId,
    }
  );
  const coupon = couponsQuery?.data?.data;

  // create a new Coupon code
  const createCouponMutation = useMutation(
    () => {
      const { couponCode, expiryDate, quantity, createdBy } = formValues;
      return axiosInstance.post(`/v1/coupons`, {
        subscriptionTierId: tierType,
        couponCode: couponCode.toUpperCase(),
        expiryDate,
        quantity,
        createdBy,
      });
    },
    {
      onSuccess: async () => {
        navigation.push(`/Coupons`);
      },
    }
  );

  // update Subscription Tier
  const updateCouponMutation = useMutation(
    () => {
      const { expiryDate, quantity, updatedByemail } = formValues;
      return axiosInstance.patch(`/v1/coupons/${coupon.id}`, {
        expiryDate,
        quantity,
        updatedBy: updatedByemail,
        isEnabled: true,
      });
    },
    {
      onSuccess: async () => {
        navigation.push(`/Coupons`);
      },
    }
  );

  const subscriptionTiersQuery = useQuery(['subscriptionTier'], () =>
    axiosInstance.get<SubscriptionTier[]>(`/v1/subscriptionTiers`)
  );
  const subscriptionTiers = subscriptionTiersQuery?.data?.data;

  //create new Webinar Topics

  // const [newWebinarTopics, setNewWebinarTopics] = useState<any>("");
  //   const createNewWebinarTopics = useMutation(
  //     () => {
  //       AddNewWebinarTopic.current.value = ""
  //       setNewWebinarTopics(newWebinarTopics.sort())
  //       setNewWebinarTopics(newWebinarTopics.filter((item: any, index: any) => newWebinarTopics.indexOf(item) === index))
  //       return axiosInstance.post(`/webinarTopics` , {
  //         newWebinarTopics
  //       })
  //     },
  //     {
  //       onSuccess: async (data) => {
  //         window.location.reload();
  //       },
  //     }
  //   )

  // const updateWebinarMutation = useMutation(
  //   () => {
  //     const { name, fbqPixelId , preHeader , hostId } = formValues;
  //     let webinarHostId = Number(hostId)
  //     let webinarMetaDataContent:any;
  //     if(webinarType === "CBCWebinar"){
  //       webinarMetaDataContent = {}
  //     }
  //     else{
  //       webinarMetaDataContent = JSON.parse(textInputValue)
  //     }
  //     return axiosInstance.patch(`/webinars/${webinar.id}`, {
  //       name,
  //       preHeader:preHeader,
  //       fbqPixelId: fbqPixelId || undefined,
  //       videoDuration: uploadedVideo.video.durationInMilliseconds || undefined,
  //       videoUuid: uploadedVideo.video.uuid,
  //       categories: selectedCategories,
  //       topics: selectedTopics,
  //       webinarType: webinarType,
  //       paletteId: randomColorPaletteId,
  //       hostId:webinarHostId,
  //       webinarMetadata: webinarMetaDataContent,
  //     });
  //   },
  //   {
  //     onSuccess: async (data) => {
  //       const webinar = data.data;
  //       navigation.push(`/webinars/show/${webinar.id}`);
  //     },
  //   }
  // );

  // const setWebinarThemeColor = (paletteId) => {
  //   let colorpaletteId = Number(paletteId);
  //   setSelectedColorPaletteId(Number(paletteId));
  //   setSelectedColorPaletteColor(
  //     webinarUtils.getColorPalette(colorpaletteId)[0].cardColor
  //   );
  // };

  return (
    <OuterContainer>
      <form
        onSubmit={handleSubmit(async (data) => {
          if (!coupon) {
            createCouponMutation.mutate();
          } else {
            updateCouponMutation.mutate();
          }
          //createSubscriptionTierMutation.mutate();
        })}
      >
        <ItemsContainer>
          {/* Select webinar Topics */}

          {!coupon && (
            <div>
              <label htmlFor="couponCode" className="my-label">
                Enter Coupon Code
              </label>
              <input
                {...register('couponCode', { required: true })}
                type="text"
                id="price"
                className="my-input"
                placeholder="Enter Coupon Code"
                autoComplete="off"
              />
            </div>
          )}

          <div>
            <label htmlFor="expiryDate" className="my-label">
              Expiry Date
            </label>
            <input
              {...register('expiryDate', { required: true })}
              type="date"
              id="price"
              className="my-input"
              placeholder="Enter Expiry Date"
              autoComplete="off"
            />
          </div>

          <div>
            <label htmlFor="quantity" className="my-label">
              Quantity
            </label>
            <input
              {...register('quantity', { required: true })}
              type="number"
              id="quantity"
              className="my-input"
              placeholder="Enter quantity"
              autoComplete="off"
            />
          </div>

          {!coupon && (
            <div>
              <label htmlFor="webinarTier" className="my-label">
                Choose Tier
              </label>
              <select
                required
                className="my-input mt-[12px]"
                placeholder=""
                onChange={(e) => {
                  setTierType(e.target.value);
                }}
              >
                <option selected disabled>
                  Slect Tier
                </option>
                {subscriptionTiers?.map((option) => (
                  <option key={option.id} value={option.id}>
                    {option.tier}
                  </option>
                ))}
              </select>
            </div>
          )}

          {!coupon && (
            <div>
              <label htmlFor="email" className="my-label">
                Email Id
              </label>
              <input
                {...register('createdBy', { required: true })}
                type="email"
                id="email"
                className="my-input"
                placeholder="Enter Your Email Id"
                autoComplete="off"
              />
            </div>
          )}

          {coupon && (
            <div>
              <label htmlFor="email" className="my-label">
                Email Id
              </label>
              <input
                {...register('updatedByemail', { required: true })}
                type="email"
                id="email"
                className="my-input"
                placeholder="Enter Your Email Id"
                autoComplete="off"
              />
            </div>
          )}

          <SaveButton type="submit">
            {formLoading && LoadingIcon}
            <span>Save</span>
          </SaveButton>
        </ItemsContainer>
      </form>
      <MutationMessage mutation={createCouponMutation} />
      <MutationMessage mutation={updateCouponMutation} />
    </OuterContainer>
  );
};
