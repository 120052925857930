import axiosInstance from 'axiosInstance';
import classNames from 'classnames';
import { Webinar } from 'interfaces/apiTypes';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import RevealContent from 'components/Shared/RevealContent';

import ExpandableDropdown from './ExpandableDropdown';
import FilledButton from './FilledButton';
import MutationMessage from './Messages/MutationMessage';
import { MessagesOffset } from './WebinarFileUpload';

interface IWebinarExpandableItemProps {
  itemsName: string;
  data: Array<any> | Record<string, any>;
  dataKey?: string;
  showCount?: boolean;
  rawData?: any;
  webinar: Webinar;
}
const WebinarExpandableItem: React.FC<IWebinarExpandableItemProps> = ({
  itemsName,
  data,
  dataKey,
  showCount = true,
  rawData,
  webinar,
}) => {
  const [showData, setShowData] = useState(false);

  const [activeTab, setActiveTab] = useState<'database' | 'raw' | 'edit'>(
    () => {
      const enableRawTabConditions = () => {
        if (Array.isArray(rawData)) return rawData.length > 0;
        if (rawData !== null && typeof rawData === 'object')
          return Object.keys(rawData).length > 0;
        return !!rawData;
      };
      const enableRawTab = enableRawTabConditions();
      if (enableRawTab) return 'raw';
      return 'database';
    }
  );
  const dataContainerRef = useRef<HTMLDivElement>(null);
  const [textInputValue, setTextInputValue] = useState('');

  const queryClient = useQueryClient();
  const [messagesDelayOffset, setMessagesDelayOffset] = useState(0);

  useEffect(() => {
    setTextInputValue(JSON.stringify(rawData, null, 4));
  }, [rawData]);
  const updateMutation = useMutation(
    () => {
      if (!webinar) {
        throw new Error('no webinar');
      }

      return axiosInstance.post(
        `/v1/webinars/${webinar.id}/${dataKey}?replace=true&offset=${messagesDelayOffset}`,
        {
          fileData: JSON.parse(textInputValue),
        }
      );
    },
    {
      onSuccess: (response) => {
        console.log(response.data);
        // TODO: ['webinars', webinarId, 'resources'] custom hook react query
        queryClient.invalidateQueries(['webinars', webinar.id, 'resources']);
        queryClient.invalidateQueries(['webinars', webinar.id, 'rawResource']);
        setActiveTab('raw');
      },
    }
  );
  const [errorOccurredInEditing, setErrorOccurredInEditing] = useState(true);

  useEffect(() => {
    if (updateMutation.isError) {
      setErrorOccurredInEditing(true);
    }
  }, [updateMutation.isError]);

  useEffect(() => {
    try {
      JSON.parse(textInputValue);
      setErrorOccurredInEditing(false);
    } catch (err) {
      setErrorOccurredInEditing(true);
    }
  }, [textInputValue]);

  return (
    <div>
      <div className="flex gap-2 items-center mb-3">
        <ExpandableDropdown
          onClick={() => setShowData((prev) => !prev)}
          expanded={showData}
          itemsName={itemsName}
          length={
            data
              ? Array.isArray(data)
                ? data.length
                : Object.keys(data).length
              : 0
          }
          countable={showCount}
        />
        <div className="flex">
          <Tab
            active={activeTab === 'database'}
            onClick={() => {
              setActiveTab('database');
              setShowData(true);
            }}
          >
            Database
          </Tab>
          <Tab
            active={activeTab === 'raw'}
            onClick={() => {
              setActiveTab('raw');
              setShowData(true);
            }}
          >
            Raw
          </Tab>
          <Tab
            active={activeTab === 'edit'}
            onClick={() => {
              setActiveTab('edit');
              setShowData(true);
            }}
          >
            Edit
          </Tab>
        </div>
        {activeTab === 'edit' && (
          <>
            <FilledButton
              onClick={() => {
                try {
                  JSON.parse(textInputValue);
                  updateMutation.mutate();
                } catch (err) {
                  alert('JSON input is incorrect');
                }
              }}
            >
              Save
            </FilledButton>
            <FilledButton
              onClick={() => {
                setActiveTab('raw');
                // reset the data
                setTextInputValue(JSON.stringify(rawData, null, 4));
              }}
            >
              Cancel
            </FilledButton>
          </>
        )}
      </div>
      {dataKey === 'messages' && activeTab === 'edit' && (
        <div className="mb-3">
          <MessagesOffset
            webinar={webinar}
            offset={messagesDelayOffset}
            setOffset={setMessagesDelayOffset}
          />
        </div>
      )}
      <div className="my-2">
        <MutationMessage mutation={updateMutation} />
      </div>
      <div ref={dataContainerRef}>
        <RevealContent
          parentRef={dataContainerRef}
          show={showData}
          makeBlankOnCollapse={true}
          deps={[activeTab]}
        >
          <div className="h-[50vh] overflow-auto">
            {/* border-blue-500 */}
            {activeTab === 'edit' ? (
              <textarea
                onFocus={() => {
                  setErrorOccurredInEditing(false);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Tab') {
                    if (!e.shiftKey) {
                      document.execCommand('insertText', false, '\t');
                    } else {
                      // can't figure out outdent
                      // but backspace works
                    }
                    e.preventDefault();
                    return false;
                  }
                }}
                className={classNames({
                  'py-2 overflow-auto w-full h-full resize-none border-2 rounded-lg outline-none':
                    true,
                  'border-blue-500': !errorOccurredInEditing,
                  'border-red-500': errorOccurredInEditing,
                })}
                value={textInputValue}
                onChange={(e) => {
                  setTextInputValue(e.target.value);
                }}
              />
            ) : activeTab === 'raw' ? (
              <pre
                className="py-2 overflow-auto border-2 border-green-500 rounded-lg"
                key={2}
              >
                {JSON.stringify(rawData, null, 2)}
              </pre>
            ) : (
              <pre
                className="py-2 overflow-auto border-2 border-gray-500 rounded-lg"
                key={3}
              >
                {JSON.stringify(data, null, 2)}
              </pre>
            )}
          </div>
        </RevealContent>
      </div>
    </div>
  );
};
export default WebinarExpandableItem;
interface ITabProps {
  children: any;
  onClick: () => void;
  active: boolean;
}
const Tab: React.FC<ITabProps> = ({ children, onClick, active }) => {
  return (
    <div
      onClick={onClick}
      className={classNames({
        'cursor-pointer px-2': true,
        'border-b-black border-b-2': active,
      })}
    >
      {children}
    </div>
  );
};
