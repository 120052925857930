import axiosInstance from 'axiosInstance';
import { Webinar } from 'interfaces/apiTypes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import endpoints from 'constants/endpoints';
import {
  DEFAULT_MILLISECONDS_AFTER_WHICH_WEBINAR_SCHEDULE_WILL_EXPIRE_AFTER_WEBINAR_STARTS,
  millisecondsInOne,
} from 'constants/generalConstants';
import webinarUtils from 'utils/webinarUtils';

import FilledButton from './FilledButton';
import BottomBar from './GenericGraph/Children/BottomBar';
import MutationMessage from './Messages/MutationMessage';

interface IScheduleWebinarAtInterval {
  webinar: Webinar;
}
const ScheduleWebinarAtInterval: React.FC<IScheduleWebinarAtInterval> = ({
  webinar,
}) => {
  const [minutes, setMinutes] = useState(30);
  const [barValue, setBarValue] = useState(0);
  const queryClient = useQueryClient();
  useEffect(() => {
    setBarValue(
      Math.min(
        (minutes / 2) * millisecondsInOne.minute,
        DEFAULT_MILLISECONDS_AFTER_WHICH_WEBINAR_SCHEDULE_WILL_EXPIRE_AFTER_WEBINAR_STARTS
      )
    );
  }, [minutes]);
  const expireAt = useMemo(() => {
    return webinarUtils.getFormattedTimer(barValue);
  }, [barValue]);

  const scheduleWebinarAtIntervalMutation = useMutation(
    ({
      delay,
      from,
      to,
      webinarId,
    }: {
      delay: number;
      from: Date;
      to: Date;
      webinarId: number;
    }) =>
      axiosInstance.post(endpoints.webinarSchedules + '/interval', {
        delay,
        from,
        to,
        expireAt,
        webinarId,
      }),
    {
      onSuccess: (response) => {
        console.log(response.data);
        queryClient.invalidateQueries([
          endpoints.webinarSchedules,
          'webinarId=',
          webinar.id,
        ]);
      },
    }
  );
  // console.log({ points });
  const handleScheduleWebinarAtInterval = useCallback(() => {
    const delay = minutes * 60 * 1000;
    const from = new Date();
    const to = new Date(from.getTime() + 30 * 24 * 60 * 60 * 1000);
    const webinarId = webinar.id;
    scheduleWebinarAtIntervalMutation.mutate({ delay, from, to, webinarId });
  }, [minutes, webinar.id, scheduleWebinarAtIntervalMutation]);

  return (
    <div className="space-y-4">
      <div>
        <select
          defaultValue={30}
          className="my-input"
          onChange={(e) => setMinutes(+e.target.value)}
        >
          {[5, 10, 15, 30]?.map((option) => (
            <option key={option} value={option}>
              {option} minutes
            </option>
          ))}
        </select>
      </div>

      <p>Webinar Schedules expire at: {expireAt}</p>
      <BottomBar
        value={barValue}
        onChange={(v) => setBarValue(v)}
        xmax={webinar.duration}
        roundedXmax={webinar.duration}
      />
      <FilledButton onClick={handleScheduleWebinarAtInterval}>
        Schedule Every {minutes} minutes
      </FilledButton>
      <MutationMessage mutation={scheduleWebinarAtIntervalMutation} />
    </div>
  );
};
export default ScheduleWebinarAtInterval;
