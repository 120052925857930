const endpoints = {
  webinars: 'webinars',
  webinarSchedules: 'v1/webinarSchedules',
  webinarSignups: 'v1/webinarSignups',
  webinarActivities: 'v1/webinarActivities',
  polls: 'v1/polls',
  pollOptions: 'v1/pollOptions',
  messages: 'v1/messages',
  resourceCtas: 'v1/resourceCtas',
  offerCtas: 'v1/offerCtas',
  disableMessagingEvents: 'v1/disableMessagingEvents',
  livePeopleGraphs: 'v1/livePeopleGraphs',
  graphs: 'v1/graphs',
  points: 'v1/points',
  recurringSchedules: 'v1/recurringSchedules',
  immediateSlots: 'v1/immediateSlots',
};
export default endpoints;
