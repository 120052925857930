interface ISaveButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}
const SaveButton: React.FC<ISaveButtonProps> = ({ children, ...props }) => {
  return (
    <button
      className={`
    flex w-full items-center rounded-lg
     bg-indigo-500 px-5 py-2.5 text-center
      text-sm font-medium text-white 
      hover:bg-indigo-600 sm:w-auto
    `}
      {...props}
    >
      {children}
    </button>
  );
};
export default SaveButton;
