import { useForm } from '@pankod/refine-react-hook-form';
import axiosInstance from 'axiosInstance';
import { useMutation, useQuery } from 'react-query';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import MutationMessage from 'components/Messages/MutationMessage';
import {useState } from 'react';
import {LoadingIcon } from 'components/icons';
import ItemsContainer from 'components/ItemsContainer';
import {useNavigation, useSelect } from '@pankod/refine-core';
import {WebinarCategory } from 'interfaces/apiTypes';
import FieldRequiredErrorMessage from 'components/FieldRequiredErrorMessage';
import InputErrorMessage from 'components/InputErrorMessage';


export const CloneWebinar: React.FC = () => {
  const [createdWebinarId, setCreatedWebinarId] = useState('');

  const { options } = useSelect({
    resource: 'webinars',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });
  const navigation = useNavigation();
  const {
    refineCore: { formLoading },
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue
  } = useForm();

  const formValues = watch();
  
  // create a new Featured Workshop
  const cloneWorkshopMutation = useMutation(
    () => {
      const { webinarId ,name  } = formValues;  
      return axiosInstance.post(`/v1/webinars/clone-webinar`, {
        id:webinarId,
        name
      });
    },
    {
      onSuccess: async (data) => {
        const webinar = data.data;
        setCreatedWebinarId(`Created Webinar : ${webinar.id} `);
      },
      onError: async()=>{
      }
    },
  );

  
  return (
    <OuterContainer>
      <form autoComplete='off'
        onSubmit={handleSubmit(async (data) => {
          cloneWorkshopMutation.mutate();
        })}
      >
        <ItemsContainer>
          <div className="mt-8 space-y-4">
            <h1 className='my-heading'>Clone Webinar - </h1>
            <div>
            <label htmlFor="webinarId" className="my-label">
              Please Select Source Webinar
            </label>
            <input
              id="webinarId"
              list="webinars"
              name="webinarId"
              className="my-input"
              defaultValue={''}
              {...register('webinarId', { required: true })}
              onChange={(e) => setValue('webinarId', e.target.value)}
            />
            <datalist id="webinars">
              <option value={''} disabled>
                Please select
              </option>
              {options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </datalist>
            {errors.webinarId && <FieldRequiredErrorMessage />}
          </div>

          <br />

            <label htmlFor="name" className="my-label">
              Webinar Name
            </label>
            <input
              {...register('name', { required: true })}
              type="text"
              id="name"
              className="my-input"
              placeholder="Name"
              autoComplete="off"
            />
            {errors.name && (
              <InputErrorMessage>
                <span className="font-medium">Oops!</span> This field is
                required
              </InputErrorMessage>
            )}
            
          </div>
                     
          <SaveButton type="submit">
            {formLoading && LoadingIcon}
            <span>Save</span>
          </SaveButton>

          <br/>
          <h3 className="text-[25px]">{createdWebinarId}</h3>
      </ItemsContainer>
      </form>
      <MutationMessage mutation={cloneWorkshopMutation} />
    </OuterContainer>
  );
};
