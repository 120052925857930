import { useEffect } from 'react';

interface IBottomBarProps {
  value: number;
  roundedXmax: number;
  xmax: number;
  xmin?: number;
  roundedXMin?: number;
  onChange: (v: number) => void;
}
const BottomBar: React.FC<IBottomBarProps> = ({
  value,
  roundedXmax,
  xmax,
  onChange,
  xmin = 0,
  roundedXMin = 0,
}) => {
  useEffect(() => {
    if (value > xmax) {
      onChange(xmax);
    }
    if (value < xmin) {
      onChange(xmin);
    }
  }, [onChange, value, xmax, xmin]);
  return (
    <input
      type="range"
      min={roundedXMin}
      max={roundedXmax}
      value={value}
      className="slider"
      onChange={(e) => {
        const newValue = +e.target.value;
        if (newValue <= xmax && newValue >= xmin) onChange(newValue);
      }}
    />
  );
};
export default BottomBar;
