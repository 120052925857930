import { useForm } from '@pankod/refine-react-hook-form';
import axiosInstance from 'axiosInstance';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import FileUpload from 'components/FileUpload';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import { Host } from 'interfaces/apiTypes';
import { LoadingIcon } from 'components/icons';
import MutationMessage from 'components/Messages/MutationMessage';


export const CreateHost: React.FC = () => {

  const { register, setValue, handleSubmit, reset , watch ,  refineCore: { formLoading } } = useForm();
  const queryClient = useQueryClient();
  const formValues = watch();

  const url = window.location.href.split('/');
  const hostId = url?.[url.length - 1];

  const hostsQuery = useQuery(
    ['host', hostId],
    () =>
      axiosInstance.get<Host>(
        `/v1/hosts/${hostId}`
      ),
    {
      enabled: !!hostId,
    }
  );
  const hostData = hostsQuery?.data?.data;

  const addHostMutation = useMutation(
    ({
      name,
      pictureUrlBucket,
      pictureUrlPrefix,
      title,
      company,
      pictureUrl,
    }: {
      name: string;
      pictureUrlBucket: string;
      pictureUrlPrefix: string;
      title: string;
      company:string;
      pictureUrl: string;
    }) => {
      return axiosInstance.post(`v1/hosts`, {
        name,
        pictureUrlBucket,
        pictureUrlPrefix,
        title,
        company,
        pictureUrl,
      });
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(['host', hostId, 'host']);
        reset();
      },
    }
  );


  const editHostMutation = useMutation(
    ({
      name,
      pictureUrlBucket,
      pictureUrlPrefix,
      title,
      company,
      pictureUrl,
    }: {
      name: string;
      pictureUrlBucket: string;
      pictureUrlPrefix: string;
      title: string;
      company:string;
      pictureUrl: string;
    }) => {
      console.log("I got hit -------------------")
      return axiosInstance.patch(`v1/hosts/${hostId}`, {
        name,
        pictureUrlBucket,
        pictureUrlPrefix,
        title,
        company,
        pictureUrl,
      });
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries(['host', hostId, 'host']);
        reset();
      },
    }
  );
  
  return (
    <OuterContainer>
      <form
        onSubmit={handleSubmit(async (values) => {
          if (hostData) {
            editHostMutation.mutate(values as any);
          } else {
            addHostMutation.mutate(values as any);
          }
        })}
      >
        <div className="mt-8 space-y-4">
          <div>
            <label className="my-label">Host Name</label>
            <input
              {...register('name', { required: true })}
              className="my-input"
            />
          </div>
          <div>
            <label className="my-label">Host Title (Designation)</label>
            <input
              {...register('title', { required: true })}
              className="my-input"
            />
          </div>
          <div>
            <label className="my-label">Company</label>
            <input
              {...register('company', { required: true })}
              className="my-input"
            />
          </div>
          <div>
            <label className="my-label">Picture Bucket</label>
            <input
              {...register('pictureUrlBucket', { required: true })}
              className="my-input"
            />
          </div>
          <div>
            <></>
            <label className="my-label">Picture Prefix</label>
            <input
              {...register('pictureUrlPrefix', { required: true })}
              className="my-input"
            />
          </div>
          <div>
            <></>
            <label className="my-label">Picture Url</label>
            <input
              {...register('pictureUrl', { required: true })}
              className="my-input"
            />
          </div>
          <div>
            <p className="text-xl mt-4 text-center">OR</p>
            <div>
              <FileUpload
                acceptedFileType="image"
                finalFileUrl={''}
                hostImageUpload={true}
                setFinalFileUrl={(presignedPost) => {
                  if (presignedPost) {
                    setValue(
                      'pictureUrlBucket',
                      presignedPost.fields['bucket']
                    );
                    setValue('pictureUrlPrefix', presignedPost.fields['Key']);
                    setValue(
                      'pictureUrl',
                      `https://assets.growthschool.io/${presignedPost.fields['Key']}`
                    );
                  }
                }}
              />
              {hostData &&  <img src={formValues.pictureUrl} alt="HostImage" className='w-[500px]' />}
            </div>
          </div>
          <SaveButton type="submit">
            {formLoading && LoadingIcon}
            <span>Save</span>
          </SaveButton>
        </div>
      </form>
      <MutationMessage mutation={addHostMutation} />
      <MutationMessage mutation={editHostMutation} />
    </OuterContainer>
  );
};
