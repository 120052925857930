import axiosInstance from "axiosInstance";

export function capitalize(input: string) {
  const words = input.split(' ');
  const capitalized = [];
  words.forEach((element) => {
    capitalized.push(
      element[0].toUpperCase() + element.slice(1, element.length)
    );
  });
  return capitalized.join(' ');
}

export const displayPartialText = (
  text: string,
  {
    maxLength,
    suffix = '...',
    completeLastWord,
  }: {
    maxLength: number;
    suffix?: string;
    completeLastWord: boolean;
  }
) => {
  if (text.length <= maxLength) {
    return text;
  }
  // note maxLength is inclusive of suffix length
  const remainingLength = maxLength - suffix.length;
  let partialText = text.slice(0, remainingLength);
  if (completeLastWord) {
    if (text[remainingLength] !== ' ') {
      const indexOfSpaceFromLast = partialText.lastIndexOf(' ');
      partialText = partialText.slice(0, indexOfSpaceFromLast);
    }
  }
  const finalText = partialText + suffix;
  return finalText;
};

export async function getUserCommonPropertiesForWebengage(webinarSignupId: number) {
  try {
    const response = await axiosInstance.get(
      `/v1/webinarSignups/${webinarSignupId}`
    );
    return response.data
  } catch (error) {
    console.error('Error:', error);
    console.error(
      'Failed to fetch webinar signupDetails. Please try again later.'
    );
  }
}

export async function getWebinarDetails(webinarId: number) {
  try {
    const webinarDetails = await axiosInstance.get(
      `/v1/webinars/${webinarId}`
    );
    return webinarDetails.data
  } catch (error) {
    console.error('Error:', error);
    console.error(
      'Failed to fetch webinar Details. Please try again later.'
    );
  }
}