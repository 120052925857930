import { authServer } from 'constants/environmentVars';

const redirectUrl = window.location.origin;
interface ILoginPage {}
const LoginPage: React.FC<ILoginPage> = () => {
  return (
    <div className="h-[100vh]">
      <div className="text-center max-w-[624px] relative top-[20%] left-[50%] -translate-x-1/2">
        <div className="text-3xl">Login or Signup</div>
        <div className="mt-[24px]">
          <a
            href={`${authServer}/auth/google?redirect=${redirectUrl}`}
            className="webinar-popup-signin-button-link"
          >
            <button className="webinar-popup-signin-button">
              <img
                src="https://s3.ap-south-1.amazonaws.com/assets.growthschool.io/Google+Icon.svg"
                alt=""
              />
              Continue with Google
            </button>
          </a>
        </div>
        <div className="mt-[24px]">
          <a
            href={`${authServer}/auth/linkedin?redirect=${redirectUrl}`}
            className="webinar-popup-signin-button-link"
          >
            <button className="webinar-popup-signin-button">
              <img
                src="https://s3.ap-south-1.amazonaws.com/assets.growthschool.io/Linkedin+Icon.svg"
                alt=""
              />
              Continue with Linkedin
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};
export default LoginPage;
