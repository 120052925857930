import { useForm } from '@pankod/refine-react-hook-form';
import axiosInstance from 'axiosInstance';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import FileUpload from 'components/FileUpload';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import { Host, WebinarTopic } from 'interfaces/apiTypes';
import { LoadingIcon } from 'components/icons';
import MutationMessage from 'components/Messages/MutationMessage';
import { useEffect, useRef, useState } from 'react';
import { useNavigation } from '@pankod/refine-core';

 export const CreateWebinarTopic = () => {

  const navigation = useNavigation();

  const { register, setValue, handleSubmit, reset , watch ,  refineCore: { formLoading } } = useForm();
  const formValues = watch();
  const queryClient = useQueryClient();


  const url = window.location.href.split('/');
  const topicId = url?.[url.length - 1];

  const AddNewWebinarTopic = useRef<HTMLInputElement>(null);

  //create new Webinar Topics
  const [newWebinarTopics, setNewWebinarTopics] = useState<any>('');
  
  // const createNewTopics = useMutation(
  //   () => {
  //     AddNewWebinarTopic.current.value = '';
  //     setNewWebinarTopics(newWebinarTopics.sort());
  //     console.log("Hey hey")
  //     setNewWebinarTopics(
  //       newWebinarTopics.filter(
  //         (item: any, index: any) => newWebinarTopics.indexOf(item) === index
  //       )
  //     );
  //     return axiosInstance.post(`/v1/webinarTopics`, {
  //       newWebinarTopics,
  //     });
  //   },
  //   {
  //     onSuccess: async (data) => {
  //       window.location.reload();
  //     },
  //   }
  // );

  const addNewTopic = useMutation(
    ({name}: {
      name: string;
    }) => {
      let topicName:any = name
      if(topicName.length <= 3){
        topicName = topicName.toUpperCase()
      }else{
        let newWebinarTopicsArray = name.split(' ');
        for (let i = 0; i < newWebinarTopicsArray.length; i++) {
          newWebinarTopicsArray[i] =
          newWebinarTopicsArray[i].charAt(0).toUpperCase() +
          newWebinarTopicsArray[i].slice(1);
        }
        console.log("-----------",newWebinarTopicsArray)
        let finalWebinarTopic = [];
        finalWebinarTopic = newWebinarTopicsArray
        .toString()
        .replaceAll(',', ' ')
        .split('?');
        topicName = finalWebinarTopic.toString()
      }
      return axiosInstance.post(`/v1/webinarTopics`, {
        name:topicName,
      });
    },
    {
      onSuccess: async (data) => {
        navigation.push(`/webinarTopics`);
      },
      onError: async (error) => {
        console.log(error)
      },
    }
  );
  

  return (
    <OuterContainer>
      <form
        onSubmit={handleSubmit((values) => {
        addNewTopic.mutate(values as any);
        })}
      >
        <div className="mt-8 space-y-4">
          <div>
            <label className="my-label">Topic Name</label>
            <input
              {...register('name', { required: true })}
              className="my-input"
            />
          </div>
          <SaveButton type="submit">Save</SaveButton>
        </div>
      </form>
    </OuterContainer>
   
  );
};


