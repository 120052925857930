import { useForm } from '@pankod/refine-react-hook-form';
import axiosInstance from 'axiosInstance';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import FileUpload from 'components/FileUpload';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import InputErrorMessage from 'components/InputErrorMessage';
import MutationMessage from 'components/Messages/MutationMessage';
import { useEffect, useMemo, useRef, useState } from 'react';
import CategoryPill from 'components/CategoryPill';
import { CrossIcon , LoadingIcon } from 'components/icons';
import ItemsContainer from 'components/ItemsContainer';
import { useNavigation, useSelect } from '@pankod/refine-core';
import { AudioBook, Guide, GuideModule, GuideResource } from 'interfaces/apiTypes';

export const CreateGuideResource: React.FC = () => {

  const [selectedGuideId, setSelectedGuideId] = useState<any>();
  const [selectedGuideModuleId, setSelectedGuideModuleId] = useState<any>();
  const [selectedResourceType, setSelectedResourceType] = useState<any>();

  const { options } = useSelect({
    resource: 'guides',
    optionLabel: 'title',
    fetchSize: 100000000000000,
  });

  const {
    refineCore: { formLoading },
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();


  const guideslistQuery = useQuery(['guides'], () =>
  axiosInstance.get<Guide[]>(`/v1/guides`)
  );
  const availableGuides = guideslistQuery?.data?.data;

    const guideModuleslistQuery = useQuery([selectedGuideId,'guideModules'], () =>
    axiosInstance.get<GuideModule[]>(`/v1/guideModules/${selectedGuideId}`), {
      enabled: !!selectedGuideId
    }
    );
    const availableGuideModules = guideModuleslistQuery.data?.data;
  
  const AddNewGuideTopic = useRef<HTMLInputElement>(null);

  const url = window.location.href.split('/');
  const guideResourceId = url?.[url.length - 1];

  const navigation = useNavigation();
  const formValues = watch();
  const [uploadedVideo, setUploadedVideo] = useState<any>();
  
  // To get AudioBook Data with Id

  const guideResourceQuery = useQuery(
    ['GuideResource', guideResourceId],
    () =>
      axiosInstance.get<GuideResource>(
        `/v1/guideResources/${guideResourceId}`
      ),
    {
      enabled: !!guideResourceId,
    }
  );
  const guideResource = guideResourceQuery?.data?.data;

  // create a new Guide
  const createGuideResourceMutation = useMutation(
    () => {
      const { title , resourceUrl } = formValues;
      return axiosInstance.post(`/v1/guideResources`, {
        title,
        resourceUrl,
        //guideId:selectedGuideId,
        guideModule:selectedGuideModuleId,
        resourceType:selectedResourceType
      });
    },
    {
      onSuccess: async (data) => {
        const guideResource = data.data;
        navigation.push(`/guideResources`);
      },
      onError: async()=>{
        // window.location.reload();
        // navigation.push(`/AudioBooks/create`);
      }
    },
  );

  // Update an existing AudioBook
  const updateGuideMutation = useMutation(
    () => {
      const { name, fbqPixelId , preHeader } = formValues;
      let webinarMetaDataContent:any;
      return axiosInstance.post(`/v1/webinars`, {
        name,
        preHeader:preHeader,
        fbqPixelId: fbqPixelId || undefined,
        videoDuration: uploadedVideo.video.durationInMilliseconds || undefined,
        videoUuid: uploadedVideo.video.uuid,
        webinarMetadata: webinarMetaDataContent,
      });
    },
    {
      onSuccess: async (data) => {
        const webinar = data.data;
        navigation.push(`/webinars/show/${webinar.id}`);
      },
      onError: async()=>{
        window.location.reload();
        navigation.push(`/webinars/create`);
      }
    },
  );


  
  return (
    <OuterContainer>
      <form autoComplete='off'
        onSubmit={handleSubmit(async (data) => {
          if (guideResource) {
            updateGuideMutation.mutate();
          } else {
            createGuideResourceMutation.mutate();
          }
        })}
      >
        <ItemsContainer>
        <div className="mt-8 space-y-4">
              <div className="mt-8 space-y-4">
                <div>
                <label htmlFor="title" className="my-label">Resource Title</label>
                <input
                  {...register('title', { required: true })}
                  className="my-input"  type="text" id="title" placeholder=''
                />
              </div>
              {!guideResource &&
              <div>
                <label htmlFor="couponCode" className="my-label">
                  Select Guide
                </label>
                <select
                  required
                  className="my-input mt-[12px]"
                  placeholder=""
                  onChange={(e) => {
                    setSelectedGuideId(e.target.value);
                  }}
                >
                  <option selected disabled>
                    Select Guide
                  </option>
                  {availableGuides?.map((guide) => (
                    <option key={guide.id} value={guide.id}>
                    {guide.title}
                    </option>
                  ))} 
                </select>
              </div>}
            </div>

            <div className="mt-8 space-y-4">
              <div>
                <label htmlFor="couponCode" className="my-label">
                  Select Guide Module
                </label>
                <select
                  required
                  className="my-input mt-[12px]"
                  placeholder=""
                  onChange={(e) => {
                    setSelectedGuideModuleId(e.target.value);
                  }}
                >
                  <option selected disabled>
                    Select Guide Module
                  </option>
                  {availableGuideModules?.map((guideModule) => (
                    <option key={guideModule.id} value={guideModule.id}>
                    {guideModule.title}
                    </option>
                  ))} 
                </select>
              </div>
            </div>
           <div className="mt-8 space-y-4">
            <div>
              <label htmlFor="couponCode" className="my-label">
                Select Resource Type
              </label>
              <select
                required
                className="my-input mt-[12px]"
                placeholder=""
                onChange={(e) => {
                  setSelectedResourceType(e.target.value);
                }}
              >
                <option selected disabled>
                  Select Resource Type
                </option>
                <option value="Video">
                Video
                </option>
                <option value="Article">
                Article
                </option>
                <option value="Resource">
                Resource
                </option> 
              </select>
            </div>
           </div>
          <div>
            <label htmlFor="resourceUrl" className="my-label">Resource URL</label>
            <input
              {...register('resourceUrl', { required: true })}
              className="my-input"  type="text" id="url" placeholder=''
            />
          </div>
        </div>
        <br/>
          <SaveButton type="submit">
            {formLoading && LoadingIcon}
            <span>Create Resource</span>
          </SaveButton>
      </ItemsContainer>
      </form>
      <MutationMessage mutation={createGuideResourceMutation} />
      <MutationMessage mutation={updateGuideMutation} />
    </OuterContainer>
  );
};
