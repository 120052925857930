import classNames from 'classnames';
import { useMemo } from 'react';

import {displayPartialText} from 'utils/generalUtils'

// import newEventTracker from 'utils/newEventTracker/newEventTracker';

interface IMessageProps {
  theme?: 'dark' | 'light';
  children: string;
  collapseMessage?: boolean;
}
const Message: React.FC<IMessageProps> = ({
  theme = 'dark',
  children,
  collapseMessage = false,
}) => {
  const messageTextWithLink = useMemo(() => {
    let message = children ?? '';

    // remove all the carriage returns and spaces at start and end
    message = message.trim();

    const suffix = '...';
    message = collapseMessage
      ? displayPartialText(message, {
          maxLength: 40,
          suffix,
          completeLastWord: true,
        })
      : message;

    const brTag = '<br/>';

    // carriage returns are present in zoom recordings.txt file
    // we add newline for \r
    // checkout webinar-admin README for more
    // https://github.com/growth-school/webinar-admin/blob/main/README.MD#note-about-messagetext-property
    message = message.replace(/\r/g, brTag);
    // replaceAll is not used because of browser compatibilty reasons on Android
    // message = message.replaceAll('\r', brTag);

    if (message.endsWith(`${brTag}${suffix}`)) {
      // displayPartialText might return "aman gupta\r..."
      // in this case ellipsis will on new line
      // replace <br/>..." with "..."
      message =
        message.slice(0, message.length - brTag.length - suffix.length) +
        suffix;
    }
    message = wrapLinksInTextWithAnchorTag({
      message,
      theme,
      trackLinkClick: true,
      // currently trackLinkClick is always true,
      // but we can have situation where we want to track link clicks only for Growth School Admin messages
      // or we want to track all link clicks except one's which are sent by user himself in chat
    });

    return message;
  }, [children, collapseMessage, theme]);
  return (
    <div
      data-test="message-text"
      className={classNames({
        'text-sm break-words': true,
        'text-white': theme === 'dark',
        'text-[color:var(--text-main)]': theme === 'light',
      })}
      dangerouslySetInnerHTML={{
        __html: messageTextWithLink,
      }}
    ></div>
  );
};
export default Message;

const getLinksFromString = (str: string) => {
  const regexpForLink =
    /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?;&//=]*)?/gm;

  const iter = str.matchAll(regexpForLink);
  let nextValue = iter.next();
  const links: string[] = [];
  while (!nextValue.done) {
    const link = nextValue.value[0];
    links.push(link);
    nextValue = iter.next();
  }
  return links;
};

const appendHttpToLinkIfNotPresent = (link: string) => {
  if (!link.startsWith('http')) {
    link = `http://${link}`;
  }
  return link;
};

const wrapLinksInTextWithAnchorTag = ({
  message,
  theme,
  trackLinkClick,
}: {
  message: string;
  theme: 'dark' | 'light';
  trackLinkClick: boolean;
}) => {
  // we want to process the link just once
  const links = new Set(getLinksFromString(message));
  links.forEach((link) => {
    const onClickListener = trackLinkClick
      ? `onclick="handleChatMessageLinkClick('${link}')"`
      : ``;
    message = message.replaceAll(
      link,
      `<a
        href="${appendHttpToLinkIfNotPresent(link)}"
        target="_blank"
        class="${classNames({
          'text-[#0000EE]': theme === 'light',
          'text-[#2997FF]': theme === 'dark',
        })}"
        ${onClickListener}
        >${link}</a
      >`
    );
  });
  return message;
};

// handleChatMessageLinkClick has to be attached to window to be accessed by anchor tag onclick attribute
// (window as any).handleChatMessageLinkClick = (link: string) => {
//   newEventTracker.chatMessageLinkClicked({
//     messageLink: link,
//   });
// };
