import { useForm } from '@pankod/refine-react-hook-form';
import axiosInstance from 'axiosInstance';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import FileUpload from 'components/FileUpload';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import InputErrorMessage from 'components/InputErrorMessage';
import MutationMessage from 'components/Messages/MutationMessage';
import { useEffect, useMemo, useRef, useState } from 'react';
import CategoryPill from 'components/CategoryPill';
import { CrossIcon , LoadingIcon } from 'components/icons';
import ItemsContainer from 'components/ItemsContainer';
import { useNavigation, useSelect } from '@pankod/refine-core';
import { AudioBook, AudioBookTopic, Webinar, WebinarCategory, WebinarSchedule } from 'interfaces/apiTypes';
import ThumbnailUpload from 'components/ThumbnailUpload';
import FieldRequiredErrorMessage from 'components/FieldRequiredErrorMessage';


export const CreateFeaturedWorkshop: React.FC = () => {
  const [selectedWebinarId, setselectedWebinarId] = useState<number>();
  const [selectedWebinarSchedule, setselectedWebinarSchedule] = useState<number>();
  const [selectedWebinarCategoryId, setselectedWebinarCategoryId] = useState<number>();
  const [webinarSchedules, setwebinarSchedules] = useState<any>();
  const [selectedWebinarCategories, setselectedWebinarCategories] = useState<any>();

  const { options } = useSelect({
    resource: 'webinars',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });

  const webinarCategories = useQuery(['webinarCategories'], () =>
    axiosInstance.get<WebinarCategory[]>(`/v1/webinarCategories`)
  );
  const webinarCategoriesDetails = webinarCategories?.data?.data;

  const {
    refineCore: { formLoading },
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue
  } = useForm();

  const formValues = watch();

  const { webinarId } = formValues;
  
  const getWebinarScheduleDetails = (webinarId) =>{
      axiosInstance.get(`/v1/webinarSchedules?webinarId=${webinarId}`)
      .then(response => {
        // Handle the response data here
        setwebinarSchedules(response.data)
      })
      .catch(error => {
        // Handle errors here
        console.error('Error:', error);
      });

      axiosInstance.get(`/v1/webinars/get-webinar-category/${webinarId}`)
      .then(response => {
        // Handle the response data here
        setselectedWebinarCategories(response.data)
      })
      .catch(error => {
        // Handle errors here
        console.error('Error:', error);
      });
      
  }

  if(webinarId && webinarSchedules == null ){
    getWebinarScheduleDetails(webinarId)
  }


  

  // create a new Featured Workshop
  const createFeaturedWorkshopMutation = useMutation(
    () => {
      const { date , webinarId  } = formValues;  
      return axiosInstance.post(`/v1/featured-workshops`, {
        webinarId,
        webinarScheduleId:selectedWebinarSchedule,
        date , 
        webinarCategory:selectedWebinarCategoryId ,
      });
    },
    {
      onSuccess: async (data) => {
        if(data.data === "workshop already exists for same category on same day"){
          window.alert(data.data)
        }
        else{
          window.location.reload();
        }
      },
      onError: async()=>{
        // window.location.reload();
        // navigation.push(`/AudioBooks/create`);
      }
    },
  );

  
  return (
    <OuterContainer>
      <form autoComplete='off'
        onSubmit={handleSubmit(async (data) => {
          createFeaturedWorkshopMutation.mutate();
        })}
      >
        <ItemsContainer>
          <div className="mt-8 space-y-4">
            <div>
            <label htmlFor="webinarId" className="my-label">
              Please Select Webinar
            </label>
            <input
              id="webinarId"
              list="webinars"
              name="webinarId"
              className="my-input"
              defaultValue={''}
              {...register('webinarId', { required: true })}
              onChange={(e) => setValue('webinarId', e.target.value)}
            />
            <datalist id="webinars">
              <option value={''} disabled>
                Please select
              </option>
              {options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </datalist>
            {errors.webinarId && <FieldRequiredErrorMessage />}
          </div>

            <div>
              <label htmlFor="webinarScheuleId" className="my-label">
                Select Webinar Schedule
              </label>
              <select
                className="my-input mt-[12px]"
                defaultValue=""
                onChange={(e) => {
                  const featuredWebinarScheduleId = Number(e.target.value);
                  setselectedWebinarSchedule(featuredWebinarScheduleId)
                }}
              >
              <option value={''} disabled>
                Please select
              </option>
              {webinarSchedules?.map((webinarSchedule) => (
                new Date(webinarSchedule.startTime) > new Date() &&
                <option key={webinarSchedule.id} value={webinarSchedule.id}>
                  {webinarSchedule.id} - {webinarSchedule.startTime}
                </option>
              ))}
              </select>
            </div>

            

            <div>
            <label htmlFor="date" className="my-label">
              Date
            </label>
            <input
              type="date"
              className="my-input"
              id="date"
              {...register('date', {
                required: true,
                setValueAs: (v) => {
                  if (v) {
                    return new Date(v).toISOString();
                  }
                },
              })}
            />
            {errors.date && <FieldRequiredErrorMessage />}
            </div>

            {webinarCategoriesDetails?.map((webinarCategory) => (
                <h3 key={webinarCategory.id}>
                  <b>{webinarCategory.id}</b> - {webinarCategory.name} 
                </h3>
              ))}

            <div>
              <label htmlFor="webinarId" className="my-label">
                Select Webinar Category
              </label>
              <select
                className="my-input mt-[12px]"
                defaultValue=""
                onChange={(e) => {
                  const featuredWebinarScheduleId = Number(e.target.value);
                  setselectedWebinarCategoryId(featuredWebinarScheduleId)
                }}
              >
              <option value={''} disabled>
                Please select
              </option>
              {selectedWebinarCategories?.map((webinarCategory,index) => (
                  <option key={webinarCategory.id} value={webinarCategory.id}>
                    {webinarCategory.id}
                  </option>
              ))}
              </select>
            </div>

          </div>
                     
          <SaveButton type="submit">
            {formLoading && LoadingIcon}
            <span>Save</span>
          </SaveButton>
      </ItemsContainer>
      </form>
      <MutationMessage mutation={createFeaturedWorkshopMutation} />
    </OuterContainer>
  );
};
