import { useSelect } from '@pankod/refine-core';

import axiosInstance from 'axiosInstance';
import useRefresh from 'hooks/useRefresh';
import { Webinar, WebinarCategory, WebinarScheduleType } from 'interfaces/apiTypes';
import QueryString from 'qs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactHlsPlayer from 'react-hls-player/dist';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';

import CutVideo from 'components/CutVideo';
import ExpandableItem from 'components/ExpandableItem';
import FileUpload from 'components/FileUpload';
import FilledButton from 'components/FilledButton';
import IconButton from 'components/IconButton';
import { EditIcon } from 'components/icons';
import ItemsContainer from 'components/ItemsContainer';
import MutationMessage from 'components/Messages/MutationMessage';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import endpoints from 'constants/endpoints';
import { capitalize } from 'utils/generalUtils';

export const GuideModuleShow: React.FC = () => {
  const url = window.location.href.split('/');
  const guideModuleId = url?.[url.length - 1];

  const guideModulesQuery = useQuery(
    ['guideModules', guideModuleId],
    () =>
      axiosInstance.get<Webinar & { webinarCategories: WebinarCategory[] }>(
        `/v1/guideModules/getModuleDetails/${guideModuleId}`
      ),
    {
      enabled: !!guideModuleId,
    }
  );

  const guideModule = guideModulesQuery?.data?.data;

  const [uploadedVideo, setUploadedVideo] = useState<any>();

  const playerRef = useRef<HTMLVideoElement>(null);

  if (!guideModule) {
    return (
      <p data-test="non-existent-webinar-message">
        No guideModule with id: {guideModule}
      </p>
    );
  }

  // const setWebinarThemeColor = (paletteId) => {
  //   let colorpaletteId = Number(paletteId)
  //   setSelectedColorPaletteId(Number(paletteId))
  //   setSelectedColorPaletteColor(webinarUtils.getColorPalette(colorpaletteId)[0].cardColor);
  // }
  
  return (
    <OuterContainer>
      {/* <div className="flex justify-end">
        <Link to={`/audio-books/edit/${guideModule[0]?.id}`}>
          <IconButton onClick={() => {}}>{EditIcon}</IconButton>
        </Link>
      </div> */}
      <ItemsContainer>
        <div>
          <label className="my-label" htmlFor="id">
            Guide Module Id
          </label>
          <input
            id="id"
            value={guideModule[0]?.id}
            disabled
            className="my-input"
          />
        </div>

        <div>
          <label className="my-label" htmlFor="name">
            Guide Id
          </label>
          <input
            id="name"
            value={guideModule[0]?.guideId}
            disabled
            className="my-input"
          />
        </div>

        <div>
          <label className="my-label" htmlFor="name">
            Guide Module Title
          </label>
          <input
            id="name"
            value={guideModule[0]?.title}
            disabled
            className="my-input"
          />
        </div>

        <div>
          <label className="my-label" htmlFor="name">
            Guide Module Sequence Number
          </label>
          <input
            id="name"
            value={guideModule[0]?.seqNumber}
            disabled
            className="my-input"
          />
        </div>
      </ItemsContainer>
    </OuterContainer>
  );
};

interface IVideoAndThumbnail {
  webinar: Webinar;
}
const VideoAndThumbnail: React.FC<IVideoAndThumbnail> = ({ webinar }) => {
  const [uploadedVideo, setUploadedVideo] = useState<any>();
  const queryClient = useQueryClient();
  useEffect(() => {
    (async () => {
      if (webinar.videoUuid) {
        // in case of editing
        // with the video id we need to populate selected video
        const videoResult = await axiosInstance.get(
          `${process.env.REACT_APP_CBC_SERVER}/videos/${webinar.videoUuid}`,
          {
            headers: {
              Authorization: process.env.REACT_APP_CBC_TOKEN,
            },
          }
        );
        setUploadedVideo(videoResult.data);
      }
    })();
  }, [webinar.videoUuid]);
  const updateWebinarMutation = useMutation(
    ({ videoDuration }: { videoDuration: number }) => {
      return axiosInstance.patch(`/${endpoints.webinars}/${webinar.id}`, {
        videoDuration,
      });
    },
    {
      onSuccess: (response) => {
        // console.log(response.data);
        queryClient.invalidateQueries();
      },
    }
  );
  useEffect(() => {
    // this is useful for the scenario if uploadedVideo duration was null
    // eg api sets the duration through queue which takes some time
    // so we update the duration when user visits the show page
    if (uploadedVideo) {
      if (
        webinar.videoDuration !== uploadedVideo.video.durationInMilliseconds
      ) {
        // updated webinar videoDuration
        updateWebinarMutation.mutate({
          videoDuration: Number(uploadedVideo.video.durationInMilliseconds),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadedVideo, webinar.videoDuration]);
  const playerRef = useRef<HTMLVideoElement>(null);
  if (!uploadedVideo) return <></>;
  return (
    <ItemsContainer>
      <div>
        <label htmlFor="s3UrlBucket" className="my-label">
          Video Bucket
        </label>
        <input
          type="text"
          id="s3UrlBucket"
          className="my-input"
          disabled
          value={uploadedVideo?.video.s3UrlBucket}
        />
      </div>
      <div>
        <label htmlFor="s3UrlPrefix" className="my-label">
          Video Prefix
        </label>
        <input
          type="text"
          id="s3UrlPrefix"
          className="my-input"
          disabled
          value={uploadedVideo?.video.s3UrlPrefix}
        />
      </div>
      <div>
        <label className="my-label">Video Url</label>
        <input
          className="my-input"
          disabled
          value={uploadedVideo?.streams?.preferred?.hls?.url}
        />
      </div>
      <div>
        <ExpandableItem itemsName="uploadedVideo" data={uploadedVideo} />
      </div>
      <div>
        <ReactHlsPlayer
          playerRef={playerRef}
          src={uploadedVideo?.streams?.preferred?.hls?.url}
          controls
        />
      </div>
      <div>
        <CutVideo webinar={webinar} />
      </div>

      <div>
        <label htmlFor="thumbnailUrlBucket" className="my-label">
          Thumbnail Bucket
        </label>
        <input
          type="text"
          id="thumbnailUrlBucket"
          className="my-input"
          disabled
          value={uploadedVideo?.video.thumbnailUrlBucket}
        />
      </div>
      <div>
        <label htmlFor="thumbnailUrlPrefix" className="my-label">
          Thumbnail Prefix
        </label>
        <input
          type="text"
          id="thumbnailUrlPrefix"
          className="my-input"
          disabled
          value={uploadedVideo?.video.thumbnailUrlPrefix}
        />
      </div>
      <div>
        <label className="my-label">Thumbnail Url</label>
        <input
          className="my-input"
          disabled
          value={uploadedVideo?.streams?.thumbnails?.png}
        />
      </div>
      <div>
        <img
          src={uploadedVideo.streams.thumbnails.png}
          alt=""
          style={{
            maxHeight: '50vh',
            maxWidth: '50vw',
          }}
        />
      </div>
    </ItemsContainer>
  );
};

interface IAddHosts {
  webinarId: number;
}
const AddHosts: React.FC<IAddHosts> = ({ webinarId }) => {
  const hostsQuery = useQuery(['webinars', webinarId, 'hosts'], () => {
    return axiosInstance.get(`/v1/webinars/${webinarId}/hosts`);
  });
  const hosts = useMemo(() => hostsQuery.data?.data, [hostsQuery.data?.data]);
  return (
    <div>
      {!!hosts?.length && (
        <div>
          {hosts.map((host) => (
            <HostShow key={host.id} host={host} webinarId={webinarId} />
          ))}
        </div>
      )}
      {!!!hosts?.length && <HostAdd webinarId={webinarId}></HostAdd>}
      {!!!hosts?.length && <HostInput webinarId={webinarId}></HostInput>}
    </div>
  );
};
export default AddHosts;
interface IDeleteParticularTypeSchedule {
  webinar: Webinar;
  type: WebinarScheduleType;
}
const DeleteParticularTypeSchedule: React.FC<IDeleteParticularTypeSchedule> = ({
  webinar,
  type,
}) => {
  const queryClient = useQueryClient();
  const deleteWebinarSchedulesForWebinarMutation = useMutation(
    ({ type }: { type: WebinarScheduleType }) => {
      let query = '';
      query += `?type=${type}`;
      return axiosInstance.delete(
        `/v1/webinars/${webinar.id}/webinarSchedules${query}`
      );
    },
    {
      onSuccess: (response) => {
        //console.log(response.data);
        queryClient.invalidateQueries([
          endpoints.webinarSchedules,
          'webinarId=',
          webinar.id,
          'type=',
          type,
        ]);
      },
    }
  );
  const webinarSchedulesQuery = useQuery(
    [endpoints.webinarSchedules, 'webinarId=', webinar.id, 'type=', type],
    () =>
      axiosInstance.get(
        `${endpoints.webinarSchedules}?webinarId=${webinar.id}&type=${type}`
      )
  );
  const schedules = webinarSchedulesQuery.data?.data || [];

  return (
    <ItemsContainer>
      <FilledButton
        variant="danger"
        onClick={() => {
          deleteWebinarSchedulesForWebinarMutation.mutate({
            type: type,
          });
        }}
      >
        Delete {type} Schedules ({schedules.length})
      </FilledButton>
      <ExpandableItem
        itemsName={`Webinar Schedules with type "${type}"`}
        data={schedules}
      />
      <MutationMessage
        mutation={deleteWebinarSchedulesForWebinarMutation}
      ></MutationMessage>
    </ItemsContainer>
  );
};

interface IEnrollBuldUsers {}
const EnrollBuldUsers: React.FC<IEnrollBuldUsers> = ({}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const webinarScheduleIdInputRef = useRef<HTMLInputElement>(null);
  const refresh = useRefresh();
  const enrollBulkUsersMutation = useMutation((formData: FormData) => {
    const queryData = {
      webinarScheduleId: webinarScheduleIdInputRef.current.value,
      nameAccessor: 'First Name',
      emailAccessor: 'Email',
      phoneAccessor: 'Mobile',
    };
    const query = QueryString.stringify(queryData);
    //console.log(query);
    return axiosInstance.post(
      `/v1/webinarSchedules/enroll-bulk-users?${query}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    );
  });
  const handleUpload = useCallback(async () => {
    if (fileInputRef.current && fileInputRef.current.files?.[0]) {
      const formData = new FormData();
      // console.log({
      //   textFileToUpload: fileInputRef.current.files[0],
      // });
      formData.append('users-list', fileInputRef.current.files[0]);
      enrollBulkUsersMutation.mutate(formData);
    }
  }, [enrollBulkUsersMutation]);

  return (
    <div className="mt-8 space-y-4">
      <h2 className="text-2xl">Enroll Bulk Users for Webinar Schedule</h2>
      <label className="my-label">webinarScheduleId</label>
      <input
        className="my-input"
        type="text"
        placeholder="webinarScheduleId"
        ref={webinarScheduleIdInputRef}
      />
      <label className="my-label">{capitalize('users')} File:</label>
      <input
        className="my-input"
        ref={fileInputRef}
        type="file"
        onChange={refresh}
      />
      <FilledButton
        onClick={handleUpload}
        disabled={(fileInputRef.current?.files.length ?? 0) === 0}
      >
        {enrollBulkUsersMutation.status === 'idle'
          ? 'Upload'
          : enrollBulkUsersMutation.status === 'loading'
          ? 'Uploading'
          : enrollBulkUsersMutation.status === 'success'
          ? 'Uploaded'
          : 'Upload Failed'}
      </FilledButton>
      <MutationMessage mutation={enrollBulkUsersMutation} />
    </div>
  );
};
interface IHostShow {
  host: any;
  webinarId: number;
}
const HostShow: React.FC<IHostShow> = ({ host, webinarId }) => {
  const queryClient = useQueryClient();

  const deleteHostMutation = useMutation(
    () => {
      return axiosInstance.delete(`/v1/hosts/${host.id}`);
    },
    {
      onSuccess: (response) => {
        // console.log(response.data)
        queryClient.invalidateQueries(['webinars', webinarId, 'hosts']);
      },
    }
  );
  const { register, setValue, handleSubmit, reset } = useForm();
  const { options } = useSelect({
    resource: 'hosts',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });

  const addHostMutation = useMutation(
    ({ hostId }: { hostId: number }) => {
      return axiosInstance.patch(`/v1/webinars/${webinarId}/hosts`, {
        hostId,
      });
    },
    {
      onSuccess: (response) => {
        //console.log(response.data);
        queryClient.invalidateQueries(['webinars', webinarId, 'hosts']);
        reset();
      },
    }
  );

  return (
    <div className="mt-8 space-y-4">
      <form
        onSubmit={handleSubmit((values) => {
          addHostMutation.mutate(values as any);
        })}
      >
        <div className="mt-8 space-y-4">
          <div>
            <label htmlFor="hostId" className="my-label">
              Host
            </label>
            <input
              id="hostId"
              list="hosts"
              name="hostId"
              className="my-input"
              defaultValue={''}
              {...register('hostId', { required: true })}
            />
            <datalist id="hosts">
              <option value={''} disabled>
                Please select
              </option>
              {options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </datalist>
          </div>
          <SaveButton type="submit">Change Host</SaveButton>
        </div>
      </form>
      <div>
        <label className="my-label">Name</label>
        <input disabled value={host.name} className="my-input" />
      </div>
      <div>
        <label className="my-label">title</label>
        <input disabled value={host.title} className="my-input" />
      </div>
      <div>
        <label className="my-label">Company</label>
        <input disabled value={host.company} className="my-input" />
      </div>
      <div>
        <label className="my-label">Picture Bucket</label>
        <input disabled value={host.pictureUrlBucket} className="my-input" />
      </div>
      <div>
        <label className="my-label">Picture Prefix</label>
        <input disabled value={host.pictureUrlPrefix} className="my-input" />
      </div>
      <div>
        <div>
          <span className="inline-block max-w-96 max-h-96 overflow-hidden">
            <img src={host.pictureUrl} alt="" />
          </span>
        </div>
      </div>
      <FilledButton
        onClick={() => {
          deleteHostMutation.mutate();
        }}
      >
        Delete
      </FilledButton>
    </div>
  );
};

interface IHostAdd {
  webinarId: number;
}

const HostAdd: React.FC<IHostAdd> = ({ webinarId }) => {
  const queryClient = useQueryClient();

  const { register, setValue, handleSubmit, reset } = useForm();
  const { options } = useSelect({
    resource: 'hosts',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });

  const addHostMutation = useMutation(
    ({ hostId }: { hostId: number }) => {
      return axiosInstance.patch(`/v1/webinars/${webinarId}/hosts`, {
        hostId,
      });
    },
    {
      onSuccess: (response) => {
        // console.log(response.data);
        queryClient.invalidateQueries(['webinars', webinarId, 'hosts']);
        reset();
      },
    }
  );

  return (
    <div className="mt-8 space-y-4">
      <form
        onSubmit={handleSubmit((values) => {
          addHostMutation.mutate(values as any);
        })}
      >
        <div className="mt-8 space-y-4">
          <div>
            <label htmlFor="hostId" className="my-label">
              Host
            </label>
            <input
              id="hostId"
              list="hosts"
              name="hostId"
              className="my-input"
              defaultValue={''}
              {...register('hostId', { required: true })}
            />
            <datalist id="hosts">
              <option value={''} disabled>
                Please select
              </option>
              {options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </datalist>
          </div>
          <SaveButton type="submit">Add Host</SaveButton>
        </div>
      </form>
      {/* <div>
        <label className="my-label">Name</label>
        <input disabled value={host.name} className="my-input" />
      </div>
      <div>
        <label className="my-label">Designation</label>
        <input disabled value={host.designation} className="my-input" />
      </div>
      <div>
        <label className="my-label">Picture Bucket</label>
        <input disabled value={host.pictureUrlBucket} className="my-input" />
      </div>
      <div>
        <label className="my-label">Picture Prefix</label>
        <input disabled value={host.pictureUrlPrefix} className="my-input" />
      </div>
      <div>
        <div>
          <span className="inline-block max-w-96 max-h-96 overflow-hidden">
            <img src={host.pictureUrl} alt="" />
          </span>
        </div>
      </div> */}
    </div>
  );
};

interface IHostInput {
  webinarId: number;
}
const HostInput: React.FC<IHostInput> = ({ webinarId }) => {
  const { register, setValue, handleSubmit, reset } = useForm();
  const queryClient = useQueryClient();
  const addHostMutation = useMutation(
    ({
      name,
      pictureUrlBucket,
      pictureUrlPrefix,
      title,
      company,
      pictureUrl,
    }: {
      name: string;
      pictureUrlBucket: string;
      pictureUrlPrefix: string;
      title: string;
      company: string;
      pictureUrl: string;
    }) => {
      return axiosInstance.post(`/v1/webinars/${webinarId}/hosts`, {
        name,
        pictureUrlBucket,
        pictureUrlPrefix,
        title,
        company,
        pictureUrl,
      });
    },
    {
      onSuccess: (response) => {
        //console.log(response.data);
        queryClient.invalidateQueries(['webinars', webinarId, 'hosts']);
        reset();
      },
    }
  );
  return (
    // Add new host completely
    <>
      <form
        onSubmit={handleSubmit((values) => {
          addHostMutation.mutate(values as any);
        })}
      >
        <div className="mt-8 space-y-4">
          <div>
            <label className="my-label">Name</label>
            <input
              {...register('name', { required: true })}
              className="my-input"
            />
          </div>
          <div>
            <label className="my-label">Title</label>
            <input
              {...register('title', { required: true })}
              className="my-input"
            />
          </div>
          <div>
            <label className="my-label">Company</label>
            <input
              {...register('company', { required: true })}
              className="my-input"
            />
          </div>
          <div>
            <label className="my-label">Picture Bucket</label>
            <input
              {...register('pictureUrlBucket', { required: true })}
              className="my-input"
            />
          </div>
          <div>
            <></>
            <label className="my-label">Picture Prefix</label>
            <input
              {...register('pictureUrlPrefix', { required: true })}
              className="my-input"
            />
          </div>
          <div>
            <></>
            <label className="my-label">Picture Url</label>
            <input
              {...register('pictureUrl', { required: true })}
              className="my-input"
            />
          </div>
          <div>
            <p className="text-xl mt-4 text-center">OR</p>
            <div>
              <FileUpload
                acceptedFileType="image"
                finalFileUrl={''}
                hostImageUpload={true}
                setFinalFileUrl={(presignedPost) => {
                  if (presignedPost) {
                    setValue(
                      'pictureUrlBucket',
                      presignedPost.fields['bucket']
                    );
                    setValue('pictureUrlPrefix', presignedPost.fields['Key']);
                    setValue(
                      'pictureUrl',
                      `https://assets.growthschool.io/${presignedPost.fields['Key']}`
                    );
                  }
                }}
              />
            </div>
          </div>
          <SaveButton type="submit">Add Host</SaveButton>
        </div>
      </form>
    </>
  );
};
/*
"https://player.vimeo.com/play/ea6e7b74-f393-4b5b-859c-6243b338d715/hls?s=704077672_1651401295_d5ce80ea108c2230f769500ad92123be&context=Vimeo%5CController%5CApi%5CResources%5CUser%5CVideoController.&log_user=0&oauth2_token_id=1604831868"
*/
