import { useDelete, useNavigation } from '@pankod/refine-core';
import {
  Column,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from '@pankod/refine-react-table';

import React from 'react';

import ActionCell from 'components/ActionCell';
import FilledButton from 'components/FilledButton';
import FiltersContainer from 'components/FiltersContainer';
import { CreateIcon } from 'components/icons';
import ListContainer from 'components/OuterContainer';

export const WebinarAdminChatList: React.FC = () => {
  const { show, edit, create } = useNavigation();
  const { mutate } = useDelete();

  const columns: Array<Column> = React.useMemo(
    () => [
      {
        id: 'id',
        Header: 'ID',
        accessor: 'id',
      },
      {
        id: 'webinar_id',
        Header: 'webinar_id',
        accessor: 'webinar_id',
        filter: 'contains',
      },
      {
        id: 'category_id',
        Header: 'category_id',
        accessor: 'webinar_category',
        filter: 'contains',
      },
      {
        id: 'sequence_number',
        Header: 'sequence_number',
        accessor: 'sequence_number',
        filter: 'contains',
      },
      {
        id: 'action',
        Header: 'Action',
        accessor: 'id',
        Cell: ({ value }) => (
          <ActionCell
            edit={() => edit('top-workshops', value)}
            show={() => show('top-workshops', value)}
            remove={() => {
              const result = window.confirm(
                'Are you sure, you want to delete top workshop with id ' + value
              );
              if (result) {
                mutate({ id: value, resource: 'top-workshops' });
              }
            }}
          />
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    state: { pageIndex, pageSize, filters },
  } = useTable({ columns }, useFilters, useSortBy, usePagination);

  return (
    <ListContainer>
      <FiltersContainer>
        <div className="pl-2 flex">
          <FilledButton onClick={() => create('webinarChat')}>
            {CreateIcon}
            <span>Webinar Admin Real time chat</span>
          </FilledButton>
        </div>
        <div className="pl-2 flex">
          <FilledButton onClick={() => show('webinarChat', 1)}>
            {CreateIcon}
            <span>Highlight Message</span>
          </FilledButton>
        </div>
      </FiltersContainer>
    </ListContainer>
  );
};
