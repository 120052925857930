import { useForm } from '@pankod/refine-react-hook-form';
import axiosInstance from 'axiosInstance';
import { useEffect } from 'react';
import { useMutation, useQuery  } from 'react-query';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import MutationMessage from 'components/Messages/MutationMessage';
import { useState } from 'react';
import { LoadingIcon } from 'components/icons';
import ItemsContainer from 'components/ItemsContainer';
import { useSelect } from '@pankod/refine-core';
import { TopWorkshops, WebinarCategory } from 'interfaces/apiTypes';
import FieldRequiredErrorMessage from 'components/FieldRequiredErrorMessage';


export const CreateTopWorkshop: React.FC = () => {
  const [selectedWebinarCategories, setselectedWebinarCategories] = useState<any>();
  const [topWorkshopCategory, setTopWorkshopCategory] = useState<number>();

  const [topWorkshopsForWebinarCategory, setTopWorkshopsForWebinarCategory] = useState<any>();
  const [availableSequenceNumbers, setAvailableSequenceNumbers] = useState<any>([1,2,3,4,5,6,7,8,9,10]);
  const [sequenceNumber, setSequenceNumber] = useState<any>();
  const [error, setError] = useState<any>();

  const { options } = useSelect({
    resource: 'webinars',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });

  const webinarCategories = useQuery(['webinarCategories'], () =>
    axiosInstance.get<WebinarCategory[]>(`/v1/webinarCategories`)
  );
  const webinarCategoriesDetails = webinarCategories?.data?.data;
  
  const {
    refineCore: { formLoading },
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue
  } = useForm();

  const formValues = watch();

  
  const getWebinarCategoryDetails = (webinarId:number) =>{
      axiosInstance.get(`/v1/webinars/get-webinar-category/${webinarId}`)
      .then(response => {
        setselectedWebinarCategories(response.data)
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }
  
  
  const getTopWorkshopsForWebinarCategoryId = (topWorkshopCategory:number) =>{
    axiosInstance.get(`/v1/top-workshops/webinar-category/${topWorkshopCategory}`)
    .then(response => {
      setTopWorkshopsForWebinarCategory(response.data)
    })
    .catch(error => {
      console.error('Error:', error);
    });
  }

  useEffect(() => {
    if (topWorkshopsForWebinarCategory) {
      const sequencesToRemove = topWorkshopsForWebinarCategory.map((topWorkshopData: any) => (topWorkshopData.sequence_number));
      setAvailableSequenceNumbers((prev: any[]) => prev.filter((number: any) => !sequencesToRemove.includes(number)));
    }
  }, [topWorkshopsForWebinarCategory]);

  // create a new Top Workshop
  const createTopWorkshopMutation = useMutation(
    () => {
      const { webinarId } = formValues;  
      return axiosInstance.post(`/v1/top-workshops`, {
        webinarId,
        webinarCategory:topWorkshopCategory ,
        sequenceNumber
      });
    },
    {
      onSuccess: async (data) => {
      },
      onError: async(error)=>{
        setError(error)
      }
    },
  );

  
  return (
    <OuterContainer>
      <form autoComplete='off'
        onSubmit={handleSubmit(async (data) => {
          createTopWorkshopMutation.mutate();
        })}
      >
        <ItemsContainer>
          <div className="mt-8 space-y-4">
            <div>
              <label htmlFor="webinarId" className="my-label">
                Please Select Webinar
              </label>
              <input
                id="webinarId"
                list="webinars"
                name="webinarId"
                className="my-input"
                defaultValue={''}
                {...register('webinarId', { required: true })}
                onChange={(e) => {
                setValue('webinarId', e.target.value)
                getWebinarCategoryDetails(Number(e.target.value))
              }}
              />
              <datalist id="webinars">
                <option value={''} disabled>
                  Please select
                </option>
                {options?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </datalist>
              {errors.webinarId && <FieldRequiredErrorMessage />}
            </div>

            {webinarCategoriesDetails?.map((webinarCategory) => (
                <h3 key={webinarCategory.id}>
                <b>{webinarCategory.id}</b> - {webinarCategory.name} 
                </h3>
            ))}

            <div>
              <label htmlFor="webinarId" className="my-label">
                Select Webinar Category
              </label>
              <select
                className="my-input mt-[12px]"
                defaultValue=""
                onChange={(e) => {
                const topWorkshopCategoryId = Number(e.target.value);
                  setTopWorkshopCategory(topWorkshopCategoryId)
                  getTopWorkshopsForWebinarCategoryId(topWorkshopCategoryId)
                }}
              >
                <option value={''} disabled>
                  Please select
                </option>
                {selectedWebinarCategories?.map((webinarCategory:any,index:any) => (
                  <option key={webinarCategory.id} value={webinarCategory.id}>
                    {webinarCategory.id}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <label htmlFor="webinarId" className="my-label">
                Select Sequence Number
              </label>
              <select
                className="my-input mt-[12px]"
                defaultValue=""
                onChange={(e) => {
                const topWorkshopSequenceNumber = Number(e.target.value);
                  setSequenceNumber(topWorkshopSequenceNumber)
                }}
              >
                <option value={''} disabled>
                  Please select
                </option>
                {availableSequenceNumbers?.map((sequenceNumber:any,index:any) => (
                  <option key={sequenceNumber} value={sequenceNumber}>
                    {sequenceNumber}
                  </option>
                ))}
              </select>
            </div>
          
          <SaveButton type="submit">
            {formLoading && LoadingIcon}
            <span>Save</span>
          </SaveButton>
          {error && <h2>{error.message}</h2>}
      </ItemsContainer>
      </form>
      <MutationMessage mutation={createTopWorkshopMutation} />
    </OuterContainer>
  );
};
