import classNames from 'classnames';

import { ChevronRightIcon } from 'components/icons';

interface IExpandableDropdownProps {
  onClick: () => void;
  expanded: boolean;
  itemsName: string;
  length?: number;
  countable: boolean;
}
const ExpandableDropdown: React.FC<IExpandableDropdownProps> = ({
  onClick,
  expanded,
  itemsName,
  length,
  countable,
}) => {
  return (
    <button
      onClick={onClick}
      className={classNames(
        'border border-[#c4c4c4] px-2 py-1 rounded-lg flex items-center space-x-3',
        {
          'border-[#111111]': expanded,
        }
      )}
    >
      <span>
        {length ? (expanded ? 'Collapse' : 'Expand') : countable ? 'No' : ''}
        &nbsp;{itemsName}
        {countable && !!length && <span>&nbsp;({length})</span>}
      </span>
      <div
        className={classNames('transition-all', {
          'rotate-90': !expanded,
          '-rotate-90': expanded,
        })}
      >
        {ChevronRightIcon}
      </div>
    </button>
  );
};
export default ExpandableDropdown;
