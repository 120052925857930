import { useForm  } from '@pankod/refine-react-hook-form';
import { useNavigation } from '@pankod/refine-core';
import axiosInstance from 'axiosInstance';
import { useMutation, useQueryClient } from 'react-query';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';

export const CreateAudioBookAuthor: React.FC = () => {

  const navigation = useNavigation();

  const { register, handleSubmit, reset } = useForm();
  const url = window.location.href.split('/');
  const webinarId = url?.[url.length - 1];
  const queryClient = useQueryClient();

  const addAuthorMutation = useMutation(
    ({
      name,
    }: {
      name: string;
    }) => {
      return axiosInstance.post(`/v1/authors`, {
        name,
      });
    },
    {
      onSuccess: async (data) => {
        const author = data.data;
        navigation.push(`/authors`);
      },
      onError: async () => {
        window.location.reload();
      },
    }
  );
  
  return (
    <OuterContainer>
      <form
        onSubmit={handleSubmit((values) => {
        addAuthorMutation.mutate(values as any);
        })}
      >
        <div className="mt-8 space-y-4">
          <div>
            <label className="my-label">Author Name</label>
            <input
              {...register('name', { required: true })}
              className="my-input"
            />
          </div>
          <SaveButton type="submit">Add New Author</SaveButton>
        </div>
      </form>
    </OuterContainer>
  );
};
