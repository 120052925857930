import { DependencyList, useEffect, useRef } from 'react';

interface IRevealContentProps {
  show: boolean;
  children: React.ReactNode;
  duration?: number;
  parentRef: React.RefObject<HTMLElement>;
  deps?: DependencyList;
  makeBlankOnCollapse?: boolean;
}
const RevealContent: React.FC<IRevealContentProps> = ({
  show,
  children,
  duration = 200,
  parentRef,
  deps = [],
  makeBlankOnCollapse = false,
}) => {
  const childRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (parentRef.current) {
      parentRef.current.style.overflow = 'hidden';
      parentRef.current.style.transition = `all ${duration}ms ease`;
    }
  }, [duration, parentRef]);
  useEffect(() => {
    // re-evaluating height
    if (parentRef.current && childRef.current) {
      const parentHeight = parentRef.current.clientHeight;
      const childHeight = childRef.current.clientHeight;
      if (show) {
        parentRef.current.style.maxHeight = parentHeight + childHeight + 'px';
      } else {
        // parentRef.current.style.transition = `all ${duration}ms ease`;
        // we can set different cubic bizare for revealing and hiding
        if (makeBlankOnCollapse) {
          parentRef.current.style.maxHeight = '0px';
        } else {
          parentRef.current.style.maxHeight = parentHeight - childHeight + 'px';
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parentRef, show, ...deps]);
  return <div ref={childRef}>{children}</div>;
};
export default RevealContent;
