import { useSelect } from '@pankod/refine-core';
import { useForm } from '@pankod/refine-react-hook-form';

import axiosInstance from 'axiosInstance';
import { TPartialMessage } from 'interfaces/apiTypes';
import { useEffect, useRef, useState } from 'react';

import ChatMessage from 'components/AdminLiveChat/ChatMessage';
import FieldRequiredErrorMessage from 'components/FieldRequiredErrorMessage';
import InputErrorMessage from 'components/InputErrorMessage';
import ItemsContainer from 'components/ItemsContainer';
import OuterContainer from 'components/OuterContainer';
import {
  deleteFirebaseMessage,
  getMessagesForWebinarSchedule,
  highlightFirebaseMessage,
  publishFirebaseMessage,
  sendNewMessageToFirebase,
  sendNewReplyToFirebase,
  unPublishFirebaseMessage,
} from 'utils/firebaseUtils';
import webinarUtils from 'utils/webinarUtils';

export type TFirebaseMessageReplies = TPartialMessage & {
  docId?: any;
  webinarSignupId: number;
  showInChat?: boolean;
  isHighlighted?: boolean;
  adminSignupId?: number;
  replies: any;
};

export const CreateWebinarAdminChat: React.FC = () => {
  const [webinarDetails, setWebinarDetails] = useState<any>();
  const [webinarSchedules, setWebinarSchedules] = useState<any>();
  const [selectedWebinarSchedule, setSelectedWebinarSchedule] =
    useState<number>();
  const [selectedWebinarScheduleDetails, setSelectedWebinarScheduleDetails] =
    useState<any>();
  const [webinarSignupDetails, setWebinarSignupDetails] = useState<any>();
  const [selectedTab, setSelectedTab] = useState<any>('live_chat');
  const [selectedMessages, setSelectedMessages] = useState<Set<string>>(
    new Set()
  );
  const [messages, setUserMessages] = useState<TFirebaseMessageReplies[]>();
  const [replyingToMessage, setReplyingToMessage] = useState(null);
  const [iframeUrl, setIframeUrl] = useState<string>('');
  const isMounted = useRef(false);
  const messageEndRef = useRef<HTMLDivElement>(null);

  const messageRef = useRef(null);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm();

  const formValues = watch();

  const { options } = useSelect({
    resource: 'webinars',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });

  const { webinarId } = formValues;

  // Function to get all the upcoming webinar schedules based on webinar id
  const getUpcomingWebinarScheduleDetails = async (webinarId: any) => {
    try {
      const response = await axiosInstance.get(
        `/v1/webinarSchedules/${webinarId}/get-upcoming-webinar-schedules`
      );
      setWebinarSchedules(response.data);
    } catch (error) {
      console.error('Error:', error);
      console.error(
        'Failed to fetch webinar schedules. Please try again later.'
      );
    }
  };

  if (webinarId && webinarSchedules == null) {
    getUpcomingWebinarScheduleDetails(webinarId);
  }

  // Get Selected webinar schedule details

  const getSelectedWebinarScheduleDetails = async (webinarScheduleId: any) => {
    try {
      const response = await axiosInstance.get(
        `/v1/webinarSchedules/${webinarScheduleId}`
      );
      setSelectedWebinarScheduleDetails(response.data);

      const getWebinarSignupDetails = await axiosInstance.get(
        `/v1/webinarSignups/${selectedWebinarSchedule}/get-admin-signup`
      );
      setWebinarSignupDetails(getWebinarSignupDetails.data);
      setIframeUrl(
        `${process.env.REACT_APP_WEBINAR_RUNTIME}/live/${selectedWebinarSchedule}?signup=${getWebinarSignupDetails.data.uuid}`
      );
    } catch (error) {
      console.error('Error:', error);
      console.error(
        'Failed to fetch webinar schedules. Please try again later.'
      );
    }
  };

  useEffect(() => {
    if (webinarSignupDetails) {
      (window as any).webengage.user.login(webinarSignupDetails.email);
      (window as any).webengage.user.setAttribute(
        'we_email',
        webinarSignupDetails.email
      );
      (window as any).webengage.user.setAttribute(
        'we_first_name',
        webinarSignupDetails.name
      );
      (window as any).webengage.user.setAttribute(
        'we_phone',
        webinarSignupDetails.phone
      );
      (window as any).webengage.user.setAttribute('we_email_opt_in', true);
      (window as any).webengage.user.setAttribute('we_sms_opt_in', true);
      (window as any).webengage.user.setAttribute('we_whatsapp_opt_in', true);
    }
  }, [webinarSignupDetails]);

  const getSelectedWebinarDetails = async (webinarId: any) => {
    try {
      const response = await axiosInstance.get(`/v1/webinars/${webinarId}`);
      setWebinarDetails(response.data);
    } catch (error) {
      console.error('Error:', error);
      console.error(
        'Failed to fetch webinar schedules. Please try again later.'
      );
    }
    getUpcomingWebinarScheduleDetails(webinarId);
  };

  useEffect(() => {
    (async () => {
      if (selectedWebinarSchedule) {
        await getSelectedWebinarScheduleDetails(selectedWebinarSchedule);
      }
      if (webinarId) {
        await getSelectedWebinarDetails(webinarId);
      }
    })();
  }, [selectedWebinarSchedule, webinarId]);

  useEffect(() => {
    // Component did mount
    isMounted.current = true;

    const fetchMessages = async () => {
      try {
        if (isMounted.current) {
          await getMessagesForWebinarSchedule(
            setUserMessages,
            webinarId,
            selectedWebinarSchedule,
            isMounted
          );
        }
      } catch (error) {
        console.error('Failed to fetch messages:', error);
      }
    };

    if (isMounted.current) {
      fetchMessages();
    }

    return () => {
      isMounted.current = false;
    };
  }, [selectedWebinarSchedule, webinarId]);

  const deleteMessage = () => {
    try {
      selectedMessages.forEach((messageId) => {
        deleteFirebaseMessage(messageId, webinarId, selectedWebinarSchedule);
      });
    } catch (error) {
      console.log('Error deleting Messaging', error);
      throw error;
    }
  };

  const highLightMessage = () => {
    try {
      selectedMessages.forEach((messageId) => {
        highlightFirebaseMessage(messageId, webinarId, selectedWebinarSchedule);
      });
    } catch (error) {
      console.log('Error highlighting Messaging', error);
      throw error;
    }
  };

  const publishMessage = () => {
    try {
      selectedMessages.forEach((messageId) => {
        publishFirebaseMessage(messageId, webinarId, selectedWebinarSchedule);
      });
    } catch (error) {
      console.log('Error Publishing Messaging', error);
      throw error;
    }
  };

  const unPublishMessage = () => {
    try {
      selectedMessages.forEach((messageId) => {
        unPublishFirebaseMessage(messageId, webinarId, selectedWebinarSchedule);
      });
    } catch (error) {
      console.log('Error Un Publishing Messaging', error);
      throw error;
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };

  const sendMessage = () => {
    let parentDocumentId: string;
    if (replyingToMessage === null) {
      sendNewMessageToFirebase(
        {
          text: messageRef.current.value,
          type: 'message',
          showInChat: true,
          adminSignupId: webinarSignupDetails?.id,
          delay: webinarUtils.timeStampToMilliseconds(
            webinarUtils.millisecondsToTimeStamp(
              webinarUtils.getElapsedTimeForWebinar(
                selectedWebinarScheduleDetails
              )
            )
          ),
          profileColor: '#6466F1',
          name:
            webinarDetails?.webinarType === 'AppWebinar'
              ? 'Outskill'
              : 'GrowthSchool',
          createdAt: new Date(),
        },
        webinarDetails,
        selectedWebinarScheduleDetails
      );
    } else {
      try {
        if (replyingToMessage?.referenceDocumentId) {
          parentDocumentId = replyingToMessage?.referenceDocumentId;
        } else if (replyingToMessage?.docId) {
          parentDocumentId = replyingToMessage?.docId;
        }
        sendNewReplyToFirebase(
          {
            referenceDocumentId: parentDocumentId,
            text: messageRef.current.value,
            type: 'reply',
            webinarSignupId: replyingToMessage?.webinarSignupId,
            adminSignupId: webinarSignupDetails.id,
            delay: webinarUtils.timeStampToMilliseconds(
              webinarUtils.millisecondsToTimeStamp(
                webinarUtils.getElapsedTimeForWebinar(
                  selectedWebinarScheduleDetails
                )
              )
            ),
            profileColor: '#6466F1',
            name:
              webinarDetails?.webinarType === 'AppWebinar'
                ? 'Outskill'
                : 'GrowthSchool',
            createdAt: new Date(),
          },
          webinarDetails,
          selectedWebinarScheduleDetails
        );

        (window as any).webengage.track('Webinar Admin Replied', {
          webinar_id: webinarDetails.id,
          webinar_title: webinarDetails.name,
          webinar_type: webinarDetails.webinarType,
          webinar_schedule_id: selectedWebinarScheduleDetails.id,
          webinar_start_time: selectedWebinarScheduleDetails.startTime,
          email: webinarSignupDetails.email,
          name: webinarSignupDetails.name,
          // phone: webinarSignupDetails.phone,
        });
      } catch (error) {
        console.log('Error sending message', error);
      }
    }
    setReplyingToMessage(null);
    messageRef.current.value = '';
  };

  let filteredMessages = [];

  switch (selectedTab) {
    case 'live_chat':
      filteredMessages = messages;
      break;
    case 'replied':
      filteredMessages = messages.filter(
        (message: any) => message.replies && message.replies.length > 0
      );
      break;
    case 'to_be_replied':
      filteredMessages = messages.filter(
        (message: any) => message.replies && message.replies.length === 0
      );
      break;
    case 'published':
      filteredMessages = messages.filter(
        (message: any) => message.showInChat === true
      );
      break;
    case 'multi-word-messages':
      filteredMessages = messages.filter((message: any) => {
        return (
          message.text &&
          message.text.split(' ').length !== 1 &&
          message.text.split(' ').length !== 2
        );
      });
      break;
    default:
      filteredMessages = [...messages];
  }

  // useEffect(() => {
  //   if (filteredMessages && filteredMessages.length > 0 && isMounted?.current) {
  //     messageEndRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // }, [filteredMessages]);

  return (
    <OuterContainer>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(async (data) => {
          console.log(data);
        })}
      >
        <ItemsContainer>
          <div className="mt-8 space-y-4">
            <div>
              <label htmlFor="webinarId" className="my-label">
                Webinar Id
              </label>
              <input
                id="webinarId"
                list="webinars"
                name="webinarId"
                className="my-input"
                defaultValue={''}
                {...register('webinarId', { required: true })}
                onChange={(e) => setValue('webinarId', e.target.value)}
              />
              <datalist id="webinars">
                <option value={''} disabled>
                  Please select
                </option>
                {options?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </datalist>
              {errors.webinarId && <FieldRequiredErrorMessage />}
            </div>

            <div>
              <label htmlFor="webinarScheuleId" className="my-label">
                Select Webinar Schedule
              </label>
              <select
                className="my-input mt-[12px]"
                defaultValue=""
                onChange={(e) => {
                  const selectedWebinarScheduleId = Number(e.target.value);
                  setSelectedWebinarSchedule(selectedWebinarScheduleId);
                }}
              >
                <option value={''} disabled>
                  Please select
                </option>
                {webinarSchedules?.map((webinarSchedule: any) => (
                  <option key={webinarSchedule.id} value={webinarSchedule.id}>
                    {webinarSchedule.id} - {webinarSchedule.startTime}
                  </option>
                ))}
              </select>
            </div>

            {errors.name && (
              <InputErrorMessage>
                <span className="font-medium">Oops!</span> This field is
                required
              </InputErrorMessage>
            )}
          </div>

          {/* <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={getWebinarSignupDetailsForWebinarSchedule}
          >
            View Webinar
          </button> */}
        </ItemsContainer>
      </form>

      <br />

      <div className="flex flex-col lg:flex-row h-screen lg:h-[100%] space-y-4 lg:space-y-0 lg:space-x-4">
        <div className="flex w-2/5">
          <iframe
            id="webinar_runtime"
            width="500"
            height="300"
            src={iframeUrl}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        </div>
        <div className="flex flex-col">
          <div className="flex">
            <select
              style={{
                backgroundColor: '#F1F1F1',
                width: '530px',
                height: '44px',
                color: 'black',
                borderRadius: '8px',
                padding: '10px',
              }}
              onChange={(e) => setSelectedTab(e.target.value)}
              defaultValue="live_chat"
            >
              <option value="live_chat">Live Chat</option>
              <option value="replied">Replied</option>
              <option value="to_be_replied">To be Replied</option>
              <option value="published">Published</option>
              <option value="multi-word-messages">multi-word-messages</option>
            </select>
          </div>
          <div
            className="flex flex-col mt-[10px] w-[530px] h-[756px] relative"
            style={{ border: '0.5px solid grey', borderRadius: '12px' }}
          >
            <div
              className="flex p-[15px] flex space-x-4"
              style={{ color: 'whitesmoke' }}
            >
              {selectedTab !== 'published' && (
                <button
                  className="w-[116px] h-[34px] bg-[#6466F1]"
                  style={{ borderRadius: '4px' }}
                  onClick={publishMessage}
                >
                  Publish
                </button>
              )}
              {selectedTab === 'published' && (
                <button
                  className="w-[116px] h-[34px] bg-[#6466F1]"
                  style={{ borderRadius: '4px' }}
                  onClick={unPublishMessage}
                >
                  Unpublish
                </button>
              )}
              <button
                className="w-[198px] h-[34px] bg-[#6466F1] flex items-center justify-between px-[16px]"
                style={{ borderRadius: '4px' }}
                onClick={highLightMessage}
              >
                <svg
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.2454 4.25466C14.7217 2.73091 12.655 1.87488 10.5001 1.87488C8.34519 1.87488 6.27854 2.73091 4.75479 4.25466C3.23104 5.77842 2.375 7.84507 2.375 9.99998C2.375 12.1549 3.23103 14.2215 4.75479 15.7453C6.27854 17.269 8.34519 18.1251 10.5001 18.1251C12.655 18.1251 14.7217 17.269 16.2454 15.7453C17.7692 14.2215 18.6252 12.1549 18.6252 9.99998C18.6252 7.84507 17.7692 5.77842 16.2454 4.25466ZM8.0001 16.4062V11.875H13.0001V16.4062C11.392 17.0313 9.60817 17.0313 8.0001 16.4062ZM11.7501 10.625H9.2501V7.88592L11.7501 6.63592V10.625ZM15.361 14.8609C15.0231 15.1995 14.6508 15.5019 14.2501 15.7633V11.875C14.2501 11.5435 14.1184 11.2255 13.884 10.9911C13.6496 10.7567 13.3316 10.625 13.0001 10.625V5.62498C13.0002 5.51839 12.973 5.41355 12.9211 5.32043C12.8693 5.2273 12.7944 5.14899 12.7038 5.09294C12.6131 5.03688 12.5096 5.00494 12.4032 5.00016C12.2967 4.99538 12.1907 5.0179 12.0954 5.0656L8.34541 6.9406C8.24158 6.99256 8.15427 7.07242 8.09327 7.17122C8.03228 7.27003 8.00002 7.38387 8.0001 7.49998V10.625C7.66858 10.625 7.35064 10.7567 7.11622 10.9911C6.8818 11.2255 6.7501 11.5435 6.7501 11.875V15.7633C5.55907 14.9885 4.63735 13.8637 4.11171 12.5437C3.58606 11.2236 3.48233 9.77315 3.81477 8.39174C4.14721 7.01032 4.89949 5.76584 5.96814 4.82946C7.03679 3.89308 8.36932 3.31081 9.78243 3.16273C11.1955 3.01465 12.6198 3.30804 13.8594 4.00254C15.0989 4.69704 16.0929 5.75853 16.7045 7.04102C17.316 8.32351 17.5153 9.76399 17.2747 11.1643C17.0341 12.5647 16.3656 13.8561 15.361 14.8609Z"
                    fill="white"
                  />
                </svg>
                <p>Publish to highlight</p>
                
              </button>
              <div
                className="w-[34px] h-[34px] bg-[#BD2828] flex items-center justify-center"
                style={{ borderRadius: '4px' }}
                onClick={deleteMessage}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 4H3.33333H14"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12.6673 3.9987V13.332C12.6673 13.6857 12.5268 14.0248 12.2768 14.2748C12.0267 14.5249 11.6876 14.6654 11.334 14.6654H4.66732C4.3137 14.6654 3.97456 14.5249 3.72451 14.2748C3.47446 14.0248 3.33398 13.6857 3.33398 13.332V3.9987M5.33398 3.9987V2.66536C5.33398 2.31174 5.47446 1.9726 5.72451 1.72256C5.97456 1.47251 6.3137 1.33203 6.66732 1.33203H9.33398C9.68761 1.33203 10.0267 1.47251 10.2768 1.72256C10.5268 1.9726 10.6673 2.31174 10.6673 2.66536V3.9987"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M6.66602 7.33203V11.332"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M9.33398 7.33203V11.332"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </div>

            {/* messages  of users*/}
            <div className="overflow-y-scroll flex-grow">
              {filteredMessages?.map((message) => {
                return (
                  <div className="items-center">
                    <ChatMessage
                      key={message?.docId}
                      message={message}
                      onSelectionChange={(isSelected) => {
                        setSelectedMessages((currentSelectedMessages) => {
                          const updatedSelectedMessages = new Set(
                            currentSelectedMessages
                          );
                          if (isSelected) {
                            updatedSelectedMessages.add(message.docId);
                          } else {
                            updatedSelectedMessages.delete(message.docId);
                          }
                          return updatedSelectedMessages;
                        });
                      }}
                      onReplyClick={setReplyingToMessage}
                    />
                  </div>
                );
              })}
              <div ref={messageEndRef}></div>
            </div>

            <div className="bg-[#F1F1F1]  w-full rounded-b-[12px]">
              <div className="bg-white flex flex-col mx-[2rem] my-[1rem] rounded-[12px]">
                {replyingToMessage && (
                  <div className="py-[6px] px-[10px]  relative">
                    {' '}
                    <div className="bg-[#F1F1F1]  rounded-[6px] ">
                      <div className="flex  gap-x-[10px] px-[10px] justify-between py-[6px]">
                        <div className="">
                          <p className="text-[#030A21] text-[12px] font-[600] text-base">
                            {replyingToMessage?.name}
                          </p>
                          <p className="text-[14px] font-[400] text-base truncate-3-lines">
                            {replyingToMessage?.text}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div
                      className="cursor-pointer absolute top-4 right-4"
                      onClick={() => {
                        setReplyingToMessage(null);
                      }}
                    >
                      <svg
                        width="12" // Decreased from 32 to 24
                        height="12" // Decreased from 32 to 24
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="16" cy="16" r="16" fill="#353B4D" />
                        <path
                          strokeWidth="2"
                          d="M21.7137 10.2871L10.2852 21.7157"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.2852 10.2871L21.7137 21.7157"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                )}
                <div className="flex items-center ">
                  <input
                    type="text"
                    id="message"
                    className=" flex-1 py-2 px-4   outline-none"
                    placeholder="Type your message..."
                    autoComplete="off"
                    ref={messageRef}
                    onKeyDown={handleKeyDown}
                  />

                  <button
                    className="w-12 h-12 rounded-full text-white flex items-center justify-center"
                    onClick={sendMessage}
                  >
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16 3C13.4288 3 10.9154 3.76244 8.77759 5.1909C6.63975 6.61935 4.97351 8.64968 3.98957 11.0251C3.00563 13.4006 2.74819 16.0144 3.2498 18.5362C3.75141 21.0579 4.98953 23.3743 6.80762 25.1924C8.6257 27.0105 10.9421 28.2486 13.4638 28.7502C15.9856 29.2518 18.5995 28.9944 20.9749 28.0104C23.3503 27.0265 25.3807 25.3603 26.8091 23.2224C28.2376 21.0846 29 18.5712 29 16C28.9964 12.5533 27.6256 9.24882 25.1884 6.81163C22.7512 4.37445 19.4467 3.00364 16 3ZM20.7075 15.7075C20.6146 15.8005 20.5043 15.8742 20.3829 15.9246C20.2615 15.9749 20.1314 16.0008 20 16.0008C19.8686 16.0008 19.7385 15.9749 19.6171 15.9246C19.4957 15.8742 19.3854 15.8005 19.2925 15.7075L17 13.4137V21C17 21.2652 16.8946 21.5196 16.7071 21.7071C16.5196 21.8946 16.2652 22 16 22C15.7348 22 15.4804 21.8946 15.2929 21.7071C15.1054 21.5196 15 21.2652 15 21V13.4137L12.7075 15.7075C12.5199 15.8951 12.2654 16.0006 12 16.0006C11.7346 16.0006 11.4801 15.8951 11.2925 15.7075C11.1049 15.5199 10.9994 15.2654 10.9994 15C10.9994 14.7346 11.1049 14.4801 11.2925 14.2925L15.2925 10.2925C15.3854 10.1995 15.4957 10.1258 15.6171 10.0754C15.7385 10.0251 15.8686 9.99921 16 9.99921C16.1314 9.99921 16.2615 10.0251 16.3829 10.0754C16.5043 10.1258 16.6146 10.1995 16.7075 10.2925L20.7075 14.2925C20.8005 14.3854 20.8742 14.4957 20.9246 14.6171C20.9749 14.7385 21.0008 14.8686 21.0008 15C21.0008 15.1314 20.9749 15.2615 20.9246 15.3829C20.8742 15.5043 20.8005 15.6146 20.7075 15.7075Z"
                        fill="#6466F1"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <MutationMessage mutation={cloneWorkshopMutation} /> */}
    </OuterContainer>
  );
};
