
import axiosInstance from 'axiosInstance';
import Papa from 'papaparse';
import { useState } from 'react';
import { useMutation } from 'react-query';

import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';

// Allowed input file
const allowedExtensions = ['csv'];

export const CreateBulkTopWorkshops: React.FC = () => {

  const [CreationStatus, setCreationStatus] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const [error, setError] = useState('');

  const [file, setFile] = useState();

  const handleFileChange = (e) => {
    setError('');

    if (e.target.files.length) {
      const inputFile = e.target.files[0];

      const fileExtension = inputFile?.type.split('/')[1];
      if (!allowedExtensions.includes(fileExtension)) {
        setError('Please input a csv file');
        return;
      }
      setFile(inputFile);
    }
  };

  const HandleParse = () => {
    if (!file) return setError('Enter a valid file');

    const reader = new FileReader();

    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, { header: true });
      let parsedData = csv?.data;

      createScheduleMutation.mutate(parsedData);
    };

    reader.readAsText(file);
  };

  const createScheduleMutation = useMutation(
    (parsedData) => {
      setIsUploading(true)
      return axiosInstance.post('/v1/top-workshops/bulk', {
        topWorkshopsData:parsedData,
      });
    },
    {
      onSuccess: (response) => {
        setIsUploading(false)
        setCreationStatus(response.data);
      },
      onError: async (error:any) => {
        setIsUploading(false)
        setCreationStatus('failed check once');
      },
    }
  );

  return (
    <OuterContainer>
  <div style={{ margin: '20px', padding: '20px' }}>
    <label htmlFor="csvInput">
      Upload Top Workshops CSV File -
    </label>
    <a href='https://docs.google.com/spreadsheets/d/1tgnLD2lF8VhyOpolxm0Bn7dmfjSRX8ov9ojI-UzAz-A/edit?usp=sharing' style={{ textDecoration: 'underline', color: 'blue', display: 'block', marginTop: '10px' }}>
      Click here for Template CSV
    </a>
    <input
      onChange={handleFileChange}
      id="csvInput"
      name="file"
      type="File"
      accept=".csv"
      style={{ marginTop: '10px' }}
    />
    <div style={{ marginTop: '10px' }}>
      <SaveButton onClick={HandleParse}>{isUploading ? 'uploading' : 'create top workshops'}</SaveButton>
    </div>
    <h3 style={{ fontSize: '25px', marginTop: '20px' }}>{CreationStatus}</h3>
  </div>
  </OuterContainer>

  );
};
