import { useNavigation, useSelect } from '@pankod/refine-core';
import { useForm } from '@pankod/refine-react-hook-form';

import axiosInstance from 'axiosInstance';
import { Webinar, WebinarCategory, WebinarTopic , SubscriptionTier , Coupons } from 'interfaces/apiTypes';
import { useEffect, useMemo, useState , useRef } from 'react';
import { useMutation, useQuery } from 'react-query';

import CategoryPill from 'components/CategoryPill';
import FileUpload from 'components/FileUpload';
import { CrossIcon, LoadingIcon } from 'components/icons';
import InputErrorMessage from 'components/InputErrorMessage';
import ItemsContainer from 'components/ItemsContainer';
import MutationMessage from 'components/Messages/MutationMessage';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import webinarUtils from 'utils/webinarUtils';
import classNames from 'classnames';

// some comment
export const EditWebinarTopic: React.FC = () => {

  const topicName = useRef<any>()


  const { options } = useSelect({
    resource: 'hosts',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });

  const {
    refineCore: { formLoading },
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();


  const url = window.location.href.split('/');
  const topicId = url?.[url.length - 1];
  const navigation = useNavigation();
  const formValues = watch();

  const topicQuery = useQuery(
    ['webinarTopics', topicId],
    () =>
      axiosInstance.get<WebinarTopic>(
        `/v1/webinarTopics/${topicId}`
      ),
    {
      enabled: !!topicId,
    }
  );

  const topicData = topicQuery?.data?.data



  const editTopics = useMutation(
    () => {
      const name = topicName.current.value
      console.log(name)
      console.log('form values',formValues)
      return axiosInstance.patch(`/v1/webinarTopics/${topicId}`, {
        name,
      });
    },
    {
      onSuccess: async (data) => {
        navigation.push(`/webinarTopics`);
      },
    }
  );

  return (
    <OuterContainer>
      <form
        onSubmit={ (values:any) => {
            editTopics.mutate(values)
          }
        }
      >
        <ItemsContainer>

          {/* Select webinar Topics */}
          <label htmlFor="topicId" className="my-label">
                Topic Id
              </label>
              <input
                {...register('id', { required: true })}
                type="text"
                id="id"
                name='topicId'
                className="my-input"
                placeholder="Topic Id"
                autoComplete="off"
                disabled
              />
              <label htmlFor="topicName" className="my-label">
                Current Topic Name
              </label>
              <input
                {...register('name', { required: true })}
                type="text"
                id="name"
                name='topicName'
                className="my-input"
                placeholder="Name"
                autoComplete="off"
                ref={topicName}
              />
          <SaveButton type="submit">
            {formLoading && LoadingIcon}
            <span>Save</span>
          </SaveButton>
        </ItemsContainer>
      </form>
      <MutationMessage mutation={editTopics} />
    </OuterContainer>
  );
};
