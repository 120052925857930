import { useCallback, useState } from 'react';

const useRefresh = () => {
  const [, setValue] = useState(false);
  const refresh = useCallback(() => {
    setValue((prev) => !prev);
  }, []);
  return refresh;
};

export default useRefresh;
