import axiosInstance from 'axiosInstance';
import { useRef, useState } from 'react';
import { useMutation } from 'react-query';

import FilledButton from 'components/FilledButton';
import ListContainer from 'components/OuterContainer';

export const ShowWebinarChat = () => {
  const docIdRef = useRef<HTMLInputElement>(null);
  const webinarIdRef = useRef<HTMLInputElement>(null);
  const [resultState, setResultState] = useState<any>();
  const highlightMessage = useMutation(
    ({ documentId, webinarId }: { documentId: string; webinarId: number }) => {
      return axiosInstance.post(`/v1/messages/highlight/${documentId}`, {
        webinarId,
      });
    },
    {
      onSuccess: (response) => {
        setResultState({
          message: 'Message Highlighted Successfully',
          error: false,
        });
        docIdRef.current!.value = '';
        webinarIdRef.current!.value = '';
      },
      onError: (error) => {
        setResultState({ message: 'Something Went Wrong', error: true });
      },
    }
  );
  const handleClick = async () => {
    highlightMessage.mutate({
      documentId: docIdRef.current?.value || '',
      webinarId: +webinarIdRef.current?.value || 0,
    });
  };

  return (
    <ListContainer>
      <div className="flex flex-col gap-y-[1rem] max-w-[500px]">
        <div className="flex items-center gap-x-[1rem]">
          <label className="text-md">Enter the Document Id</label>
          <input
            type="text"
            ref={docIdRef}
            className="border-[1px] border-solid px-2 py-2 rounded-md"
            placeholder="Enter the Document Id"
          />
        </div>
        <div className="flex items-center gap-x-[1rem]">
          <label className="text-md">Enter the Webinar Id</label>
          <input
            type="text"
            ref={webinarIdRef}
            className="border-[1px] border-solid px-2 py-2 rounded-md"
            placeholder="Enter the Document Id"
          />
        </div>
        <div className="pl-2 flex">
          <FilledButton onClick={handleClick}>Submit</FilledButton>
        </div>
        {resultState && (
          <div>
            <p
              className={`${
                resultState.error ? 'text-red-600' : 'text-green-800'
              }`}
            >
              {resultState.message}
            </p>
          </div>
        )}
      </div>
    </ListContainer>
  );
};
