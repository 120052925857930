import { useForm } from '@pankod/refine-react-hook-form';
import axiosInstance from 'axiosInstance';
import { useMutation, useQuery } from 'react-query';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import CategoryPill from 'components/CategoryPill';
import { CrossIcon } from 'components/icons';
import { useState } from 'react';
import MutationMessage from 'components/Messages/MutationMessage';

export const RemoveBitesFromSeries: React.FC = () => {
  const { register, handleSubmit } = useForm();
  const [selectedSeries, setSelectedSeries] = useState<string | null>(null);
  const [selectedBites, setSelectedBites] = useState<string[]>([]);

  const { data: seriesData } = useQuery('series', () =>
    axiosInstance.get<any[]>('/v1/series')
  );
  const series = seriesData?.data;

  const { data: bitesData } = useQuery(
    ['bites', selectedSeries],
    () => axiosInstance.get<any[]>(`/v1/series/${selectedSeries}/bites`),
    { enabled: !!selectedSeries }
  );
  const bites = bitesData?.data;

  const removeBitesMutation = useMutation(
    () => {
      const biteIdsAsNumbers = selectedBites.map((biteId) => Number(biteId));
  
      const seriesIdAsNumber = Number(selectedSeries);
  
      return axiosInstance.request({
        method: 'DELETE',
        url: '/v1/series/remove-bites',
        data: {
          biteIds: biteIdsAsNumbers,
          seriesId: seriesIdAsNumber,
        },
      });
    },
    {
      onSuccess: () => {
        setSelectedBites([]);
        setSelectedSeries(null);
      },
      onError: (error) => {
        console.error('Failed to remove bites:', error);
      },
    }
  );

  return (
    <OuterContainer>
      <h2 className="text-2xl font-bold text-gray-800 mb-4 pt-10">
        Remove Bites from Series
      </h2>

      <form
        onSubmit={handleSubmit(() => {
          removeBitesMutation.mutate();
        })}
        className="mt-4 space-y-4"
      >
        <div>
          <label htmlFor="seriesSelect" className="my-label">
            Select Series
          </label>
          <select
            id="seriesSelect"
            className="my-input mt-[12px]"
            value={selectedSeries || ''}
            onChange={(e) => setSelectedSeries(e.target.value)}
          >
            <option value="" disabled>
              Please select
            </option>
            {series?.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}-{option.id}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label htmlFor="bitesSelect" className="my-label">
            Select Bites
          </label>
          <select
            id="bitesSelect"
            className="my-input mt-[12px]"
            value=""
            onChange={(e) => {
              const newBite = e.target.value;
              if (!selectedBites.includes(newBite)) {
                setSelectedBites([...selectedBites, newBite]);
              }
            }}
          >
            <option value="" disabled>
              Please select
            </option>
            {bites?.map((option) => (
              <option key={option.bite_id} value={option.bite_id}>
                {option.name}-{option.bite_id}
              </option>
            ))}
          </select>

          <div className="flex gap-x-2 mt-2">
            {selectedBites.map((biteId) => (
              <CategoryPill key={biteId}>
                <p>{biteId}</p>
                <div
                  onClick={() =>
                    setSelectedBites((prev) => prev.filter((id) => id !== biteId))
                  }
                  className="w-[16px] cursor-pointer"
                >
                  {CrossIcon}
                </div>
              </CategoryPill>
            ))}
          </div>
        </div>

        <SaveButton type="submit">
          {removeBitesMutation.isLoading
            ? 'Removing...'
            : 'Remove Bites from Series'}
        </SaveButton>
      </form>
      <MutationMessage mutation={removeBitesMutation} />
    </OuterContainer>
  );
};