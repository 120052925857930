const GenericTable = ({
  getTableProps,
  headerGroups,
  getTableBodyProps,
  page,
  prepareRow,
}: any) => {
  return (
    <table
      className="min-w-full table-fixed divide-y divide-gray-200 border"
      {...getTableProps()}
    >
      <thead className="bg-gray-100">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className="py-3 px-6 text-left text-xs font-medium uppercase tracking-wider text-gray-700 "
              >
                {column.render('Header')}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody
        {...getTableBodyProps()}
        className="divide-y divide-gray-200 bg-white"
      >
        {page.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} className="transition hover:bg-gray-100">
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    className="py-2 px-6 text-sm font-medium text-gray-900"
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
export default GenericTable;
