//Not Using this route

import { useForm } from '@pankod/refine-react-hook-form';
import axiosInstance from 'axiosInstance';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import { AudioBook, AudioBookAuthor } from 'interfaces/apiTypes';

export const ShowAudioBookAuthor: React.FC = () => {

  const { register, handleSubmit, reset } = useForm();

  const queryClient = useQueryClient();

   // To get AudioBook Data with Id

   const url = window.location.href.split('/');
   const authorId = url?.[url.length - 1];

   const authorsQuery = useQuery(
    ['AudioBooks', authorId],
    () =>
      axiosInstance.get<AudioBookAuthor>(
        `/v1/authors/${authorId}`
      ),
    {
      enabled: !!authorId,
    }
  );
  const author = authorsQuery?.data?.data;

  console.log("-----------------Audio Book -----------------",author)

  const addAuthorMutation = useMutation(
    ({
      name,
    }: {
      name: string;
    }) => {
      return axiosInstance.post(`/v1/authors`, {
        name,
      });
    },
    {
      onSuccess: (response) => {
        // console.log(response.data);
        // queryClient.invalidateQueries(['webinars', webinarId, 'hosts']);
        reset();
      },
    }
  );
  
  return (
    <OuterContainer>
      <form
        onSubmit={handleSubmit((values) => {
        addAuthorMutation.mutate(values as any);
        })}
      >
        <div className="mt-8 space-y-4">
          <div>
            <label className="my-label">Author Name</label>
            <input
              {...register('name', { required: true })}
              className="my-input"
            />
          </div>
          <SaveButton type="submit">Add New Author</SaveButton>
        </div>
      </form>
    </OuterContainer>
  );
};
