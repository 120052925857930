import {TPartialMessage} from 'interfaces/apiTypes';
import ProfileName from './Children/ProfileName';
import ProfilePic from './Children/ProfilePic';

interface IProfileProps {
  theme?: 'dark' | 'light';
  message: TPartialMessage;
  reply?:boolean;
}

const Profile: React.FC<IProfileProps> = ({ theme = 'light', message }) => {
  return (
    <div className="flex space-x-3 items-start h-4">
      <ProfilePic
        name={message.name}
        profileColor={message.profileColor ?? undefined}
      />
      
      <ProfileName theme={theme} isHost={false}>
        {message.name} 
      </ProfileName>

    </div>
  );
};

export default Profile;
