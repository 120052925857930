import { useDelete, useExport, useNavigation, useOne } from '@pankod/refine-core';
import { Column, useFilters, usePagination, useSortBy, useTable } from '@pankod/refine-react-table';

import React from 'react';

import ActionCell from 'components/ActionCell';
import FilledButton from 'components/FilledButton';
import FilterBlock from 'components/FilterBlock';
import FiltersContainer from 'components/FiltersContainer';
import GenericTable from 'components/GenericTable';
import { CreateIcon } from 'components/icons';
import ListContainer from 'components/OuterContainer';
import PageNavigation from 'components/PageNavigation';
import { Webinar } from 'interfaces/apiTypes';
import { Link, useSearchParams } from 'react-router-dom';

export const UsersList: React.FC = () => {
  const { show, edit, create } = useNavigation();
  const { mutate } = useDelete();
  const { triggerExport, isLoading: exportLoading } = useExport<any>();

  const columns: Array<Column> = React.useMemo(
    () => [
      {
        id: 'id',
        Header: 'ID',
        accessor: 'id',
      },
      {
        id: 'title',
        Header: 'title',
        accessor: 'title',
        filter: 'contains',
      },
      
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    state: { pageIndex, pageSize, filters },
  } = useTable({ columns }, useFilters, useSortBy, usePagination);

  return (
    <ListContainer>
      <FiltersContainer>
        <div className="pl-2 flex">
          <FilledButton onClick={() => create('UserManagement')}>
            <span>Remove Users Subscription</span>
          </FilledButton>
          
          <FilledButton onClick={() => edit('UserManagement','')}>
            <span>Remove Users Onboarding</span>
          </FilledButton>

          <FilledButton onClick={() => show('UserManagement','')}>
            <span>Reset all</span>
          </FilledButton>
        </div>
      </FiltersContainer>  
    </ListContainer>
  );
};
