import { useDelete, useNavigation } from '@pankod/refine-core';
import { Column, useFilters, usePagination, useSortBy, useTable } from '@pankod/refine-react-table';

import React from 'react';

import ActionCell from 'components/ActionCell';
import FilledButton from 'components/FilledButton';
import FilterBlock from 'components/FilterBlock';
import FiltersContainer from 'components/FiltersContainer';
import GenericTable from 'components/GenericTable';
import { CreateIcon } from 'components/icons';
import ListContainer from 'components/OuterContainer';
import PageNavigation from 'components/PageNavigation';

export const SeriesList: React.FC = () => {
  const { show, edit, create } = useNavigation();
  const { mutate } = useDelete();

  const columns: Array<Column> = React.useMemo(
    () => [
      {
        id: 'id',
        Header: 'ID',
        accessor: 'id',
      },
      {
        id: 'webinar_id',
        Header: 'webinar_id',
        accessor: 'webinar_id',
        filter: 'contains',
      },
      {
        id: 'category_id',
        Header: 'category_id',
        accessor: 'webinar_category',
        filter: 'contains',
      },
      {
        id: 'sequence_number',
        Header: 'sequence_number',
        accessor: 'sequence_number',
        filter: 'contains',
      },
      {
        id: 'action',
        Header: 'Action',
        accessor: 'id',
        Cell: ({ value }) => (
          <ActionCell
            edit={() => edit('series', value)}
            show={() => show('series', value)}
            remove={() => {
              const result = window.confirm(
                'Are you sure, you want to delete top workshop with id ' + value
              );
              if (result) {
                mutate({ id: value, resource: 'top-workshops' });
              }
            }}
          />
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    state: { pageIndex, pageSize, filters },
  } = useTable({ columns }, useFilters, useSortBy, usePagination);
  
  return (
    <ListContainer>
      <FiltersContainer>
        <FilterBlock
          filterId="id"
          label="Id"
          inputType="number"
          inputWidth={50}
          filters={filters}
          setFilter={setFilter}
        />
        <FilterBlock
          filterId="webinar_id"
          label="webinar_id"
          inputType="text"
          filters={filters}
          setFilter={setFilter}
        />
        <div className="pl-2 flex">
          <FilledButton onClick={() => create('series')}>
            {CreateIcon}
            <span>Create Series</span>
          </FilledButton>
          <FilledButton onClick={() => edit('series','')}>
            {CreateIcon}
            <span>Remove series from collection</span>
          </FilledButton>
        </div>
      </FiltersContainer>
      <GenericTable
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        page={page}
        prepareRow={prepareRow}
      />
      <PageNavigation
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
      />
    </ListContainer>
  );
};