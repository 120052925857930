import { message } from 'antd';
import { getFirestore } from 'App';
import axiosInstance from 'axiosInstance';
import { Webinar, WebinarSchedule } from 'interfaces/apiTypes';
import { Dispatch, SetStateAction } from 'react';

import endpoints from 'constants/endpoints';

import eventTracker from './eventTracker';
import { getUserCommonPropertiesForWebengage, getWebinarDetails } from './generalUtils';

export type TNewFirebaseMessage = {
  referenceDocumentId?: string;
  text: string;
  name: string;
  delay: number;
  profileColor: string;
  createdAt: any;
  webinarSignupId?: number;
  adminSignupId?: number;
  type: string;
  showInChat?:boolean
};

export const getMessages = (
  setFirebaseMessages: any,
  webinarId: number
  // webinarSignupId: number
) =>
  //   collectionName: string,
  //   chatGroupId: string,
  //   setMessages: any
  {
    let temp;
    const db = getFirestore();

    try {
      //@ts-ignore
      db.collection('messages')
        .doc(`${webinarId}`)
        .collection('original-messages')
        .orderBy('delay', 'asc')
        .onSnapshot(
          (snapshot: any) => {
            return setFirebaseMessages(
              snapshot.docs.map((doc: any) => {
                const docData = doc.data();

                return { docId: doc.id, ...docData, type: 'message' };
              })
            );
          },
          (error: any) => {
            getMessages(setFirebaseMessages, webinarId);
            console.log('error', error);
          }
        );
      return `result ${temp}`;
    } catch (e) {
      console.log('error in getting messages', e);
    }
  };

export const replaceAndAddMessageToFirebase = async (
  //   message: TNewFirebaseMessage[],
  messages: any,
  callback?: Dispatch<SetStateAction<string>>
) => {
  const db = getFirestore();

  messages = messages.map((msg: any) => {
    return {
      ...msg,
      delay: parseInt(msg.delay),
      createdAt: new Date().getTime(),
      upadtedAt: new Date().getTime(),
      showInChat:
        msg.showInChat === 't' || msg.showInChat === true ? true : false,
    };
  });
  //@ts-ignore

  const result = await axiosInstance.delete(
    `${endpoints.messages}/delete-collections/${messages[0].webinarId}`
  );

  try {
    await db.collection('messages').doc(`${messages[0]?.webinarId}`).set({
      webinarId: messages[0]?.webinarId,
    });
    messages.forEach(async (msg) => {
      await db
        .collection('messages')
        .doc(`${msg.webinarId}`)
        .collection('original-messages')
        .add(msg)
        .then((docRef: any) => {
          console.log('Document written with ID: ', docRef.id);
        })
        .catch((error: any) => {
          console.error('Error adding document: ', error);
        });
    });
    // callback('Messages Uploaded successfully');
  } catch (e) {
    console.log('error', e);
    // callback(
    //   'We are having trouble sending your message. Please try again later.'
    // );
  }
};

export const sendBulkMessageToFirebase = async (
  //   message: TNewFirebaseMessage[],
  messages: any,
  callback?: Dispatch<SetStateAction<string>>
) => {
  const db = getFirestore();
  messages = messages.map((msg: any) => {
    return {
      ...msg,
      delay: parseInt(msg.delay),
      createdAt: new Date().getTime(),
      upadtedAt: new Date().getTime(),
      showInChat:
        msg.showInChat === 't' || msg.showInChat === true ? true : false,
    };
  });
  //@ts-ignore

  try {
    await db.collection('messages').doc(`${messages[0]?.webinarId}`).set({
      webinarId: messages[0]?.webinarId,
    });
    messages.forEach(async (msg) => {
      await db
        .collection('messages')
        .doc(`${msg.webinarId}`)
        .collection('original-messages')
        .add(msg)
        .then((docRef: any) => {
          console.log('Document written with ID: ', docRef.id);
        })
        .catch((error: any) => {
          console.error('Error adding document: ', error);
        });
    });
    // callback('Messages Uploaded successfully');
  } catch (e) {
    console.log('error', e);
    // callback(
    //   'We are having trouble sending your message. Please try again later.'
    // );
  }
};

export const getMessagesForWebinarSchedule = async (
  setUserMessagesReplies: any,
  webinarId: number,
  webinarScheduleId?: number,
  isMounted?: React.MutableRefObject<boolean>
) => {
  const db = getFirestore();
  async function getWebinarScheduleMessagesAndCombine() {
    db.collection('messages')
      .doc(`${webinarId}`)
      .collection(`webinar-schedule-${webinarScheduleId}`)
      .onSnapshot((snapshot: any) => {
        const replyMessages = snapshot.docs
          .filter((doc: any) => doc.data().type === 'reply')
          .map((doc: any) => doc.data())
          .sort((a: any, b: any) => a.delay - b.delay);
        const webinarScheduleMessages = snapshot.docs
          .filter((doc: any) => doc.data().type === 'message')
          .map((doc: any) => {
            const docData = doc.data();
            return { docId: doc.id, ...docData, type: 'message' };
          });
        var tempArray = JSON.parse(JSON.stringify(webinarScheduleMessages));
        const userReplies = tempArray.filter((doc: any) => {
          const filteredReply = replyMessages.filter((reply: any) => {
            if (doc?.docId == reply.referenceDocumentId) {
              return reply;
            }
          });
          doc.replies = [...filteredReply];
          // doc.type = 'reply';
          return doc;
        }).sort((a: any, b: any) => a.delay - b.delay);
        if (isMounted.current) {
          return setUserMessagesReplies(userReplies);
        }

        //   return setFirebaseMessages(allMessages);
      });
  }

  try {
    await getWebinarScheduleMessagesAndCombine();
  } catch (error) {
    console.log('Error getting webinar schedule messages: ', error);
    await getWebinarScheduleMessagesAndCombine();
  }
};

export const sendNewReplyToFirebase = async (
  message: TNewFirebaseMessage,
  webinar:Webinar,
  webinarSchedule:WebinarSchedule
) => {
  const db = getFirestore();
  db.collection('messages')
    .doc(`${webinar.id}`)
    //TODO changed to webinarscheudleID
    .collection(`webinar-schedule-${webinarSchedule.id}`)
    .add(message)
    .then(async (docRef: any) => {
      const webinarDetails = await getWebinarDetails(webinar.id)
      const webinarSignupDetails = await getUserCommonPropertiesForWebengage(message?.webinarSignupId)
     
      const fireMixpanelEvent = await eventTracker.chatMessageSent({message,webinar,webinarSchedule})
      
      console.log('message sent successfully');
    })
    .catch((error: any) => {
      console.error('Error adding document: ', error);
    });

  const notificationsMessage = {
    referenceDocumentId: message.referenceDocumentId,
    webinarSignupId: message.webinarSignupId,
    // count: 0,
  };

  const notificationDocRef = db
    .collection('messages')
    .doc(`${webinar.id}`)
    .collection('notifications')
    .doc(`webinarSchedule-${webinarSchedule.id}-notifications`);
  const doc = await notificationDocRef.get();
  if (!doc.exists) {
    notificationDocRef.set({
      webinarScheduleId: webinarSchedule.id,
    });
    console.log('No such document exista!');
  } else {
    console.log('Document data:', doc.data());
  }

  db.collection('messages')
    .doc(`${webinar.id}`)
    .collection('notifications')
    .doc(`webinarSchedule-${webinarSchedule.id}-notifications`)
    .collection(`user-notifications`)
    .add(notificationsMessage)
    .then((docRef: any) => {
      console.log('notification sent successfully');
    })
    .catch((error: any) => {
      console.error('Error adding document for notification: ', error);
    });
};

export const publishFirebaseMessage = async (docId, webinarId, webinarScheduleId) => {
  const db = getFirestore();
  const docRef = db
    .collection('messages')
    .doc(`${webinarId}`)
    .collection(`webinar-schedule-${webinarScheduleId}`);

  try {
    // Update document to set showInChat to true
    await docRef.doc(docId).update({
      showInChat: true,
    });

    console.log("Message published successfully.");
    return { success: true };
  } catch (error) {
    console.error("Error publishing message:", error);
    return { success: false, error };
  }
};

export const unPublishFirebaseMessage = async (docId, webinarId, webinarScheduleId) => {
  const db = getFirestore();
  const docRef = db
    .collection('messages')
    .doc(`${webinarId}`)
    .collection(`webinar-schedule-${webinarScheduleId}`);

  try {
    // Update document to set showInChat to true
    await docRef.doc(docId).update({
      showInChat: false,
      isHighlighted:false
    });

    console.log("Message published successfully.");
    return { success: true };
  } catch (error) {
    console.error("Error publishing message:", error);
    return { success: false, error };
  }
};

export const deleteFirebaseMessage = async (docId, webinarId, webinarScheduleId) => {
  const db = getFirestore();
  const docRef = db
    .collection('messages')
    .doc(`${webinarId}`)
    .collection(`webinar-schedule-${webinarScheduleId}`);

  try {
    // Delete the document
    await docRef.doc(docId).delete();
    
    console.log("Message deleted successfully.");
    return { success: true };
  } catch (error) {
    console.error("Error deleting message:", error);
    return { success: false, error };
  }
};

export const highlightFirebaseMessage = async (docId, webinarId, webinarScheduleId) => {
  const db = getFirestore();
  const docRef = db
    .collection('messages')
    .doc(`${webinarId}`)
    .collection(`webinar-schedule-${webinarScheduleId}`);

  try {
    // Update the document to set isHighlighted and showInChat to true
    await docRef.doc(docId).update({
      isHighlighted: true,
      showInChat: true
    });
    
    console.log("Message highlighted successfully.");
    return { success: true };
  } catch (error) {
    console.error("Error highlighting message:", error);
    return { success: false, error };
  }
};

export const getMessageByDocumentId = async (docId, webinarId, webinarScheduleId) => {

  try {
    const db = getFirestore();
    
    const docRef = db
      .collection('messages')
      .doc(`${webinarId}`)
      .collection(`webinar-schedule-${webinarScheduleId}`).doc(docId);
  
      const snapshot = await docRef.get()
      const message = snapshot.data()

    return message;
  } catch (error) {
    console.error("Error getting message", error);
    return { success: false, error };
  }
};

export const sendNewMessageToFirebase = (
  message: TNewFirebaseMessage,
  webinar:Webinar,
  webinarSchedule:WebinarSchedule
) => {
  const db = getFirestore();
  console.log('message sent: ', message,webinar.id,webinarSchedule.id);
  db.collection('messages')
    .doc(`${webinar.id}`)
    .collection(`webinar-schedule-${webinarSchedule.id}`)
    .add(message)
    .then(async (docRef: any) => {
      console.log('message sent successfully');
      const fireMixpanelEvent = await eventTracker.chatMessageSent({message,webinar,webinarSchedule})
    })
    .catch((error: any) => {
      console.error('Error adding document: ', error);
    });
};