// Auto Schedule Creation

import { useNavigation, useOne, useSelect } from '@pankod/refine-core';
import { useForm } from '@pankod/refine-react-hook-form';

import { Webinar, webinarScheduleType } from 'interfaces/apiTypes';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import FieldRequiredErrorMessage from 'components/FieldRequiredErrorMessage';
import BottomBar from 'components/GenericGraph/Children/BottomBar';
import { LoadingIcon } from 'components/icons';
import ItemsContainer from 'components/ItemsContainer';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import endpoints from 'constants/endpoints';
import foreignKeys from 'constants/foreignKeys';
import { DEFAULT_MILLISECONDS_AFTER_WHICH_WEBINAR_SCHEDULE_WILL_EXPIRE_AFTER_WEBINAR_STARTS } from 'constants/generalConstants';
import webinarUtils from 'utils/webinarUtils';
import { useMutation } from 'react-query';
import axiosInstance from 'axiosInstance';
import MutationMessage from 'components/Messages/MutationMessage';

export const CreateAutoSchedule: React.FC = () => {
  const {
    refineCore: { onFinish, formLoading },
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const [slotCreationStatus, setSlotCreationStatus] = useState('');

  const [today, setToday] = useState('');

  // Get today's date on component mount and format it as YYYY-MM-DD
  useEffect(() => {
    const todayDate = new Date();
    const formattedDate = todayDate.toISOString().split('T')[0]; // Format as 'YYYY-MM-DD'
    setToday(formattedDate);
  }, []);

  const formValues = watch();
  const navigation = useNavigation();

  const { options } = useSelect({
    resource: 'webinars',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });

  const webinarQuery = useOne<Webinar>({
    resource: endpoints.webinars,
    id: formValues[foreignKeys.webinarId],
    queryOptions: {
      enabled: !!formValues[foreignKeys.webinarId],
    },
  });

  const webinar = webinarQuery.data?.data;

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const it = searchParams.entries();
    let pair = it.next();
    while (!pair.done) {
      const [key, value] = pair.value;
      switch (key) {
        case foreignKeys.webinarId: {
          setValue(foreignKeys.webinarId, +value);
        }
      }
      pair = it.next();
    }
  }, [searchParams, setValue, options]);

  const [barValue, setBarValue] = useState(
    DEFAULT_MILLISECONDS_AFTER_WHICH_WEBINAR_SCHEDULE_WILL_EXPIRE_AFTER_WEBINAR_STARTS
  );

  useEffect(() => {
    setValue('expireAt', webinarUtils.getFormattedTimer(barValue));
  }, [barValue, setValue]);

    // create a new Webinar
    const createAutoScheduleMutation = useMutation(
        () => {
          const { startDate , endDate , webinarId , expireAt  } = formValues;

          const previousDateToStartDate = getPreviousDay(startDate) 

          setSlotCreationStatus("Creating webinar schedules ⏰ ")

          return axiosInstance.post(`/v1/webinarSchedules/auto-schedule`, {
            startDate:previousDateToStartDate , endDate , webinarId , type:"autoSchedule" , expireAt
          });
        },
        {
          onSuccess: async (data) => {
            const webinarSchedulesCreationStatus = data.data;
            setSlotCreationStatus(`${webinarSchedulesCreationStatus} ✅`);
          },
          onError: async (error:any) => {
            setSlotCreationStatus(error)
          },
        }
      );
    
      // Function to add one day to a date
  const getNextDay = (dateString: string) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    return date.toISOString().slice(0, 10); // Format as 'YYYY-MM-DD'
  };

  const getPreviousDay = (dateString: string) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() - 1);
    return date.toISOString().slice(0, 10); // Format as 'YYYY-MM-DD'
  };


  return (
    <OuterContainer>
      <form
        onSubmit={handleSubmit(async (data) => {
              createAutoScheduleMutation.mutate();
          })}
        >
        <ItemsContainer>
          <div>
            <label htmlFor="startDate" className="my-label">
              Start Date
            </label>
            <input
              type="date"
              className="my-input"
              id="startDate"
              min={today}
              {...register('startDate', {
                required: true,
                setValueAs: (v) => {
                  if (v) {
                    return new Date(v).toISOString().slice(0, 10);
                  }
                },
              })}
            />
          </div>

          <div>
            <label htmlFor="endDate" className="my-label">
              End Date
            </label>
            <input
              type="date"
              className="my-input"
              id="endDate"
              min={formValues.startDate ? getNextDay(formValues.startDate) : undefined}
              {...register('endDate', {
                required: true,
                setValueAs: (v) => {
                  if (v) {
                    return new Date(v).toISOString().slice(0, 10);
                  }
                },
              })}
            />
          </div>

          {errors.startDate && <FieldRequiredErrorMessage />}
          {errors.startHour && <FieldRequiredErrorMessage />}
          
          <div>
            <label htmlFor="webinarScheduleType" className="my-label">
              Webinar Schedule Type
            </label>
            <input
              {...register('webinarScheduleType')}
              type="text"
              id="webinarScheduleType"
              className="my-input"
              value={webinarScheduleType.autoSchedule}
              disabled={true}
            />
          </div>

          <div>
            <label htmlFor="webinarId" className="my-label">
              Webinar
            </label>
            <input
              id="webinarId"
              list="webinars"
              name="webinarId"
              className="my-input"
              defaultValue={''}
              {...register('webinarId', { required: true })}
            />
            <datalist id="webinars">
              <option value={''} disabled>
                Please select
              </option>
              {options?.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </datalist>
            {errors.webinarId && <FieldRequiredErrorMessage />}
          </div>

          {webinar && (
            <div>
              <div>
                <label className="my-label" htmlFor="name">
                  Webinar Type
                </label>
                <input
                  id="name"
                  value={webinar.webinarType}
                  disabled
                  className="my-input"
                />
              </div>
              <br></br>
              <div>
                <label htmlFor="expireAt" className="my-label">
                  Expire At
                </label>
                <input
                  type="text"
                  className="my-input"
                  id="expireAt"
                  {...register('expireAt', {})}
                />
                <div className="mt-4">
                  <BottomBar
                    value={barValue}
                    onChange={(v) => setBarValue(v)}
                    xmax={webinar?.duration ?? 0}
                    roundedXmax={webinar?.duration ?? 0}
                    xmin={-1800000} // Allows the user to select values down to -30:00 if they want
                    roundedXMin={-1800000} // Allows rounding down to -30:00
                  />
                </div>
              </div>
            </div>
          )}

            <h3 className="text-[25px]">{slotCreationStatus}</h3>

          <SaveButton type="submit">
            {formLoading && LoadingIcon}
            <span>Save</span>
          </SaveButton>
        </ItemsContainer>
      </form>
      <MutationMessage mutation={createAutoScheduleMutation} />
    </OuterContainer>
  );
};
