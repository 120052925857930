import { useState } from 'react';

interface IFilterBlockProps {
  label: string;
  filterId: string;
  filters: any;
  setFilter: any;
  inputType: React.HTMLInputTypeAttribute | 'dropdown' | 'boolean';
  inputWidth?: string | number;
  inputPlaceholder?: string;
  options?: string[];
}

const FilterBlock: React.FC<IFilterBlockProps> = ({
  label,
  filterId,
  filters,
  setFilter,
  inputType,
  inputWidth = '100%',
  options,
  inputPlaceholder = filterId,
}) => {
  const [booleanFilterValue, setBooleanFilterValue] = useState(
    filters.find((filter) => filter.id === filterId)?.value
  );
  return (
    <div className="flex items-center">
      <label className="mr-1" htmlFor={filterId}>
        {label}:
      </label>
      {inputType === 'boolean' ? (
        <input
          className="my-input"
          type="text"
          placeholder="true/false"
          value={booleanFilterValue}
          onChange={(event) => {
            setBooleanFilterValue(event.target.value);
            if (['true', 'false', ''].includes(event.target.value)) {
              setFilter(filterId, event.target.value);
            }
            // console.log(event.target.checked);
          }}
        />
      ) : inputType === 'dropdown' ? (
        <select
          className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm"
          value={filters.find((filter) => filter.id === filterId)?.value || ''}
          onChange={(event) => setFilter(filterId, event.target.value)}
        >
          <option value={''}>all</option>
          {options?.map((o) => (
            <option key={o} value={o}>
              {o}
            </option>
          ))}
        </select>
      ) : (
        <input
          id={filterId}
          type={inputType}
          className="rounded border border-gray-200 p-1 text-gray-700"
          placeholder={inputPlaceholder}
          value={filters.find((filter) => filter.id === filterId)?.value || ''}
          onChange={(event) => setFilter(filterId, event.target.value)}
          style={{ width: inputWidth }}
        />
      )}
    </div>
  );
};
export default FilterBlock;
