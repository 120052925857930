interface IBottomTickProps {
  value: number;
  offsetLeft: number;
  showTick?: boolean;
  showValue?: boolean;
  showGrid?: boolean;
  height: number;
}
const BottomTick: React.FC<IBottomTickProps> = ({
  value,
  offsetLeft,
  showTick = true,
  showValue = true,
  showGrid = false,
  height,
}) => {
  return (
    <div className={`absolute`} style={{ left: offsetLeft }}>
      {showValue && (
        <div className="absolute top-[10px] -translate-x-1/2">{value}</div>
      )}
      {showTick && (
        <div className="absolute w-[1px] h-3 -translate-y-1/2 bg-black"></div>
      )}
      {showGrid && (
        <div
          className="absolute w-[1px] bg-gray-300"
          style={{ height: height, top: -height }}
        ></div>
      )}
    </div>
  );
};
export default BottomTick;
