import { Webinar, WebinarSchedule } from 'interfaces/apiTypes';
import mixpanel from 'mixpanel-browser';

import webinarUtils from './webinarUtils';

if (process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN) {
  console.log("Inside mixpanel init")

  mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN, {debug: true, track_pageview: true, persistence: 'localStorage'});

}

const eventTracker = {
  chatMessageSent: ({
    message,
    webinar,
    webinarSchedule
  }: {
    message: any;
    webinar: Webinar;
    webinarSchedule: WebinarSchedule;
  }) => {
    try{
      console.log("here inside mixpanel event")
      const { webinar_playback_timestamp, elapsed_time } =
      webinarUtils.getWebinarPlaybackDetails( webinar, webinarSchedule );
      console.log("I have reached till here")

      mixpanel.track('Webinar Chat Message Sent', {
        chat_message: message.text,
        webinar_playback_timestamp,
        elapsed_time,
        is_highlighted: message.isHighlighted ? true : false, 
        is_admin_reply: true
      });

      // mixpanel.register({
      //   program_id: webinar.id,
      //   program_name: webinar.name,
      //   program_type: webinar.webinarType,
      //   webinar_schedule_id: webinarSchedule.id,
      //   webinar_schedule_type: webinarSchedule.type,
      //   webinar_start_time:webinarSchedule.startTime,
      // });
    }
    catch(error){
      console.log("Error while sending mixpanel event",error)
    }
  }
}

export default eventTracker;