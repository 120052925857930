import produce from 'immer';
import { Point } from 'interfaces/apiTypes';
import { TPoint } from 'interfaces/web';
import { useEffect, useMemo, useState } from 'react';

import FilledButton from 'components/FilledButton';
import webinarUtils from 'utils/webinarUtils';

import AxisCoordClickListener from './Children/AxisCoordClickListener';
import BarLine from './Children/BarLine';
import BottomAxis from './Children/BottomAxis';
import BottomBar from './Children/BottomBar';
import Indicator from './Children/Indicator';
import LeftAxis from './Children/LeftAxis';
import Line from './Children/Line';
import { PointIndicator } from './Children/PointIndicator';

interface IGenericGraphProps {
  xAxisDifferencePerTick: number;
  yAxisDifferencePerTick: number;
  width: number;
  height: number;
  initialPoints: Point[];
  confirm: (points: TPoint[]) => void;
  xmax: number;
  ymax: number;
  xlabel: string;
  ylabel: string;
}

const GenericGraph: React.FC<IGenericGraphProps> = ({
  xAxisDifferencePerTick,
  yAxisDifferencePerTick,
  width,
  height,
  initialPoints,
  confirm,
  xmax,
  ymax,
  xlabel,
  ylabel,
}) => {
  const [barValue, setBarValue] = useState(0);
  const [yAtCurrentX, setYAtCurrentX] = useState(0);
  const [points, setPoints] = useState<TPoint[]>([]);
  const [graphIsValid, setGraphIsValid] = useState(true);

  const { completeInitialPoints, roundedXmax } = useMemo(() => {
    const candidate =
      Math.ceil(xmax / xAxisDifferencePerTick) * xAxisDifferencePerTick;
    // below method gives a buffer so that we can select graph point beyond xmax a bit
    const roundedXmax =
      candidate - xmax > 5 ? candidate : candidate + xAxisDifferencePerTick;

    let completeInitialPoints = [];
    if (initialPoints) {
      completeInitialPoints = initialPoints.map((point) => {
        return {
          x: point.x,
          y: point.y,
          left: width * (point.x / roundedXmax),
          top: height - height * (point.y / ymax),
        };
      });
    }
    return {
      roundedXmax,
      completeInitialPoints,
    };
  }, [height, initialPoints, width, xAxisDifferencePerTick, xmax, ymax]);
  useEffect(() => {
    setPoints(completeInitialPoints);
  }, [completeInitialPoints]);
  useEffect(() => {
    const yval = webinarUtils.getYAtXFromGraphPoints(points, barValue);
    const floored = Math.floor(yval);
    setYAtCurrentX(floored);
  }, [barValue, points]);

  useEffect(() => {
    if (initialPoints) {
      // below code checks if we have cut the video or updated the webinar with different video
      // if graph is not in sync with new videoDuration we set it as invalid
      const lastPoint = initialPoints[initialPoints.length - 1];
      if (lastPoint.x > roundedXmax) {
        setGraphIsValid(false);
      } else {
        setGraphIsValid(true);
      }
    }
  }, [initialPoints, roundedXmax]);

  return (
    <div className="">
      {!graphIsValid && (
        <p className="text-red-500 font-bold">Graph is invalid!!!</p>
      )}
      <div
        className={`
      border-l-black border-b-black border
       border-transparent h-[300px] relative w-[700px] m-auto
      `}
      >
        <LeftAxis
          height={height}
          width={width}
          min={0}
          max={ymax}
          differencePerTick={yAxisDifferencePerTick}
        />
        <BottomAxis
          height={height}
          width={width}
          min={0}
          max={roundedXmax}
          differencePerTick={xAxisDifferencePerTick}
        />
        <AxisCoordClickListener
          width={width}
          height={height}
          xmin={0}
          xmax={roundedXmax}
          ymin={0}
          ymax={ymax}
          onPointClicked={(point) => {
            // console.log({x,y})
            // console.log({ left, top });
            if (point.x > roundedXmax) return;
            setPoints(
              produce((draft) => {
                draft.push({
                  ...point,
                  x: Math.floor(point.x),
                  y: Math.floor(point.y),
                });
                draft.sort((a, b) => a.x - b.x);
              })
            );
          }}
        />
        <Indicator variant="vertical" offset={width * (xmax / roundedXmax)} />

        {points.map((p, i) => (
          <PointIndicator point={p} key={i} />
        ))}

        {points.map((p, i) => {
          if (i !== 0) {
            return <Line key={i} start={points[i - 1]} end={points[i]} />;
          }
          return null;
        })}
        <BarLine value={barValue} min={0} max={roundedXmax} width={width} />
        <div className="absolute top-[115%] w-full">
          <BottomBar
            value={barValue}
            roundedXmax={roundedXmax}
            xmax={xmax}
            onChange={(v) => setBarValue(v)}
          />
        </div>
      </div>
      <div className="flex-center flex-col pt-[100px]">
        <p>
          {xlabel}: <strong>{barValue}</strong>
        </p>
        <p>
          {ylabel}: <strong>{yAtCurrentX}</strong>
        </p>
        <div className="pt-2 flex space-x-2">
          <FilledButton onClick={() => setPoints(completeInitialPoints)}>
            Revert
          </FilledButton>
          <FilledButton onClick={() => setPoints([])}>Reset</FilledButton>
          <FilledButton onClick={() => confirm(points)}>Confirm</FilledButton>
        </div>
      </div>
    </div>
  );
};
export default GenericGraph;
