import axiosInstance from 'axiosInstance';
import { Webinar } from 'interfaces/apiTypes';
import { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import endpoints from 'constants/endpoints';
import webinarUtils from 'utils/webinarUtils';

import FilledButton from './FilledButton';
import BottomBar from './GenericGraph/Children/BottomBar';
import MutationMessage from './Messages/MutationMessage';

interface ICutVideo {
  webinar: Webinar;
}
const CutVideo: React.FC<ICutVideo> = ({ webinar }) => {
  const [start, setStart] = useState(webinar.videoStart);
  const queryClient = useQueryClient();

  const [end, setEnd] = useState(webinar.videoEnd);
  const updateWebinarMutation = useMutation(
    () => {
      return axiosInstance.patch(`/v1/${endpoints.webinars}/${webinar.id}`, {
        videoStart: start,
        videoEnd: end,
      });
    },
    {
      onSuccess: (response) => {
        console.log(response.data);
        queryClient.invalidateQueries();
      },
    }
  );

  return (
    <div className="space-y-4">
      <p className="text-xl mb-4">Cut Video</p>
      <div className="relative">
        <BottomBar
          value={start}
          roundedXmax={webinar.videoDuration}
          xmax={webinar.videoDuration}
          onChange={(v) => {
            if (v <= end) {
              setStart(v);
            }
          }}
        />
        <BottomBar
          value={end}
          roundedXmax={webinar.videoDuration}
          xmax={webinar.videoDuration}
          onChange={(v) => {
            if (v >= start) {
              setEnd(Math.min(v, webinar.videoDuration));
            }
          }}
        />
      </div>
      <div>
        <div>
          {webinarUtils.getFormattedTimer(start)} to{' '}
          {webinarUtils.getFormattedTimer(end)}
        </div>
      </div>
      <div>
        <FilledButton
          onClick={() => {
            updateWebinarMutation.mutate();
          }}
        >
          Confirm
        </FilledButton>
      </div>
      <MutationMessage mutation={updateWebinarMutation} />
    </div>
  );
};
export default CutVideo;
