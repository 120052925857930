import { useForm } from '@pankod/refine-react-hook-form';
import axiosInstance from 'axiosInstance';
import { useState, useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import FileUpload from 'components/FileUpload';
import InputErrorMessage from 'components/InputErrorMessage';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import MutationMessage from 'components/Messages/MutationMessage';
import CategoryPill from 'components/CategoryPill';
import { CrossIcon } from 'components/icons';

export const CreateBite: React.FC = () => {
  const bitesForm = useForm();
  const addBitesForm = useForm();
  const [uploadedVideo, setUploadedVideo] = useState<any>();
  const [selectedSeries, setSelectedSeries] = useState<string | null>(null);
  const [selectedBites, setSelectedBites] = useState<string[]>([]);


  useEffect(() => {
    if (uploadedVideo) {
      const { streams, video } = uploadedVideo;
      if (streams?.preferred?.hls?.url) {
        bitesForm.setValue('videoUrl', streams.preferred.hls.url);
      }
      if (streams?.thumbnails?.png) {
        bitesForm.setValue('thumbnailUrl', streams.thumbnails.png);
      }
      bitesForm.setValue('s3UrlPrefix', video.s3UrlPrefix);
    }
  }, [uploadedVideo, bitesForm]);

  const createBiteMutation = useMutation(
    () => {
      const { biteTitle, bitesS3UrlPrefix } = bitesForm.getValues();
      return axiosInstance.post(`/v1/series/bites`, {
        name: biteTitle,
        videoUuid: bitesS3UrlPrefix,
      });
    },
    {
      onSuccess: () => {
        bitesForm.reset();
      },
    }
  );

  const { data: seriesData } = useQuery('series', () =>
    axiosInstance.get<any[]>('/v1/series')
  );
  const series = seriesData?.data;

  const { data: bitesData } = useQuery('bites', () =>
    axiosInstance.get<any[]>('/v1/series/bites')
  );
  const bites = bitesData?.data;

  console.log(bites);

  const addBitesToSeriesMutation = useMutation(
    () => {
      // Convert seriesId to a number
      const seriesIdAsNumber = Number(selectedSeries);
  
      // Convert biteIds to numbers
      const biteIdsAsNumbers = selectedBites.map((id) => Number(id));
  
      return axiosInstance.post('/v1/series/add-bites', {
        seriesId: seriesIdAsNumber,
        biteIds: biteIdsAsNumbers,
      });
    },
    {
      onSuccess: () => {
        setSelectedSeries(null);
        setSelectedBites([]);
        addBitesForm.reset();
      },
      onError: (error) => {
        console.error('Failed to add bites to series:', error);
      },
    }
  );
  return (
    <OuterContainer>
      <h2 className="text-2xl font-bold text-gray-800 mb-4 pt-[10px]">Create New Bite</h2>
      <form
        onSubmit={bitesForm.handleSubmit(() => {
          createBiteMutation.mutate();
        })}
        className="mt-4 space-y-4"
      >
        <div>
          <label htmlFor="biteTitle" className="my-label">Bite Title</label>
          <input
            {...bitesForm.register('biteTitle', { required: true })}
            type="text"
            id="biteTitle"
            className="my-input"
            placeholder="Enter Bite Title"
            autoComplete="off"
          />
          {bitesForm.formState.errors.biteTitle && (
            <InputErrorMessage>
              <span className="font-medium">Oops!</span> This field is required
            </InputErrorMessage>
          )}
        </div>
        <div>
          <label htmlFor="bitesS3UrlPrefix" className="my-label">Video Prefix</label>
          <input
            {...bitesForm.register('bitesS3UrlPrefix', { required: true })}
            type="text"
            id="bitesS3UrlPrefix"
            className="my-input"
            placeholder="167ce2b0-50da-47c9-9f6a-37bbadaea18c"
            value={bitesForm.watch('bitesS3UrlPrefix') || ''}
            disabled
          />
        </div>
        <div>
          <label htmlFor="videoUpload" className="my-label">Upload Video</label>
          <FileUpload
            acceptedFileType="video"
            finalFileUrl=""
            setFinalFileUrl={(presignedPost) => {
              if (presignedPost) {
                const s3UrlPrefix = presignedPost.fields['Key'];
                bitesForm.setValue('bitesS3UrlPrefix', s3UrlPrefix);
              }
            }}
          />
        </div>
        <SaveButton type="submit">
          {createBiteMutation.isLoading ? 'Creating...' : 'Create Bite'}
        </SaveButton>
      </form>
      <MutationMessage mutation={createBiteMutation} />

      <br/>

      <div className="mt-12 border-t pt-8 mb-[200px]">      
      <h2 className="text-2xl font-bold text-gray-800 mb-4 pt-[10px]">Add Bite to Series</h2>
      <form
        onSubmit={addBitesForm.handleSubmit(() => {
          addBitesToSeriesMutation.mutate();
        })}
        className="mt-4 space-y-4"
      >
        <div>
          <label htmlFor="seriesSelect" className="my-label">Select Series</label>
          <select
            id="seriesSelect"
            className="my-input mt-[12px]"
            value={selectedSeries || ''}
            onChange={(e) => setSelectedSeries(e.target.value)}
          >
            <option value="" disabled>Please select</option>
            {series?.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}-{option.id}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="bitesSelect" className="my-label">
            Select Bites
          </label>
          <select
            id="bitesSelect"
            className="my-input mt-[12px]"
            value=""
            onChange={(e) => {
              const newBite = e.target.value;
              if (!selectedBites.includes(newBite)) {
                setSelectedBites([...selectedBites, newBite]);
              }
            }}
          >
            <option value="" disabled>
              Please select
            </option>
            {bites?.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}-{option.id}
              </option>
            ))}
          </select>

          <div className="flex gap-x-2 mt-2">
            {selectedBites.map((biteId) => (
              <CategoryPill key={biteId}>
                <p>{biteId}</p>
                <div
                  onClick={() =>
                    setSelectedBites((prev) => prev.filter((id) => id !== biteId))
                  }
                  className="w-[16px] cursor-pointer"
                >
                  {CrossIcon}
                </div>
              </CategoryPill>
            ))}
          </div>
        </div>
        <SaveButton type="submit">
          {addBitesToSeriesMutation.isLoading ? 'Adding...' : 'Add Bites to Series'}
        </SaveButton>
      </form>
      <MutationMessage mutation={addBitesToSeriesMutation} />
      </div>
    </OuterContainer>
  );
};