import { useDelete, useExport, useNavigation, useOne } from '@pankod/refine-core';
import { Column, useFilters, usePagination, useSortBy, useTable } from '@pankod/refine-react-table';

import React from 'react';

import ActionCell from 'components/ActionCell';
import FilledButton from 'components/FilledButton';
import FilterBlock from 'components/FilterBlock';
import FiltersContainer from 'components/FiltersContainer';
import GenericTable from 'components/GenericTable';
import { CreateIcon } from 'components/icons';
import ListContainer from 'components/OuterContainer';
import PageNavigation from 'components/PageNavigation';
import { Webinar } from 'interfaces/apiTypes';
import { Link, useSearchParams } from 'react-router-dom';

export const GuideList: React.FC = () => {
  const { show, edit, create } = useNavigation();
  const { mutate } = useDelete();
  const { triggerExport, isLoading: exportLoading } = useExport<any>();

  const columns: Array<Column> = React.useMemo(
    () => [
      {
        id: 'id',
        Header: 'ID',
        accessor: 'id',
      },
      {
        id: 'title',
        Header: 'title',
        accessor: 'title',
        filter: 'contains',
      },
      {
        id: 'action',
        Header: 'Action',
        accessor: 'id',
        Cell: ({ value }) => (
          <ActionCell
            edit={() => edit('guides', value)}
            show={() => show('guides', value)}
            remove={() => {
              const result = window.confirm(
                'Are you sure, you want to delete Guide with id ' + value
              );
              if (result) {
                mutate({ id: value, resource: 'guides' });
              }
            }}
          />
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    state: { pageIndex, pageSize, filters },
  } = useTable({ columns }, useFilters, useSortBy, usePagination);

  return (
    <ListContainer>
      <FiltersContainer>
      <FilterBlock
          filterId="id"
          label="Id"
          inputType="number"
          inputWidth={50}
          filters={filters}
          setFilter={setFilter}
        />
        <FilterBlock
          filterId="title"
          label="Title"
          inputType="text"
          filters={filters}
          setFilter={setFilter}
        />
        <div className="pl-2 flex">
          <FilledButton onClick={() => create('guides')}>
            {CreateIcon}
            <span>Create New Guide</span>
          </FilledButton>
          <FilledButton onClick={triggerExport}>
            {exportLoading ? 'Downloading' : 'Export'}
          </FilledButton>
          {/* <FilledButton onClick={() => edit('Coupons','')}>
            {CreateIcon}
            <span>Edit Coupon</span>
          </FilledButton> */}
        </div>
      </FiltersContainer>
      <GenericTable
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        page={page}
        prepareRow={prepareRow}
      />
      <PageNavigation
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
      /> 
      
    </ListContainer>
  );
};
