import { useForm  } from '@pankod/refine-react-hook-form';
import { useNavigation } from '@pankod/refine-core';
import axiosInstance from 'axiosInstance';
import { useMutation, useQueryClient } from 'react-query';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';

export const ResetAll: React.FC = () => {

  const navigation = useNavigation();

  const { register, handleSubmit, reset } = useForm();
  const url = window.location.href.split('/');
  const webinarId = url?.[url.length - 1];
  const queryClient = useQueryClient();

  const removeSubscription = useMutation(
    ({
      emails,
    }: {
      emails: string;
    }) => {
      return axiosInstance.post(`/v1/users/reset-user`, {
        emails,
      });
    },
    {
      onSuccess: async (data) => {
        navigation.push(`/UserManagement`);
      },
      onError: async () => {
        window.location.reload();
      },
    }
  );
  
  return (
    <OuterContainer>
      <form
        onSubmit={handleSubmit((values) => {
        removeSubscription.mutate(values as any);
        })}
      >
        <div className="mt-8 space-y-4">
          <div>
            <label className="my-label">User Emails ( Seperate by , )</label>
            <input
              {...register('emails', { required: true })}
              className="my-input"
              placeholder='sri@growthschool.io,bhargav@growthschool.io,hi@growthschool.io'
            />
          </div>
          <SaveButton type="submit">Reset Everything</SaveButton>
        </div>
      </form>
    </OuterContainer>
  );
};
