const foreignKeys = {
  webinarId: 'webinarId',
  webinarScheduleId: 'webinarScheduleId',
  webinarSignupId: 'webinarSignupId',
  pollId: 'pollId',
  pollOptionId: 'pollOptionId',
  messageId: 'messageId',
  resourceCtaId: 'resourceCtaId',
  offerCtaId: 'offerCtaId',
  disableMessagingEventId: 'disableMessagingEventId',
  livePeopleGraphId: 'livePeopleGraphId',
  graphId: 'graphId',
  pointId: 'pointId',
};
export default foreignKeys;
