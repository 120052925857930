import { useDelete, useExport, useNavigation, useOne } from '@pankod/refine-core';
import { Column, useFilters, usePagination, useSortBy, useTable } from '@pankod/refine-react-table';

import { Webinar, webinarScheduleType } from 'interfaces/apiTypes';
import React, { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import ActionCell from 'components/ActionCell';
import FilledButton from 'components/FilledButton';
import FilterBlock from 'components/FilterBlock';
import FiltersContainer from 'components/FiltersContainer';
import GenericTable from 'components/GenericTable';
import { CreateIcon } from 'components/icons';
import ListContainer from 'components/OuterContainer';
import PageNavigation from 'components/PageNavigation';
import foreignKeys from 'constants/foreignKeys';

export const WebinarScheduleList: React.FC = () => {
  const { show, edit, create } = useNavigation();
  const { mutate } = useDelete();
  const { triggerExport, isLoading: exportLoading } = useExport<any>();

  const columns: Array<Column> = React.useMemo(
    () => [
      {
        id: 'id',
        Header: 'ID',
        accessor: 'id',
      },
      {
        id: 'startTime',
        Header: 'startTime',
        accessor: 'startTime',
        filter: 'gte',
        Cell: ({ value }) => {
          return <p>{new Date(value).toLocaleString()}</p>;
        },
      },
      {
        id: 'type',
        Header: 'Type',
        accessor: 'type',
      },
      {
        id: 'expireAt',
        Header: 'Expire At',
        accessor: 'expireAt',
      },

      {
        id: 'webinarId',
        Header: 'webinar Id',
        accessor: 'webinarId',
        Cell: ({ cell }) => {
          const { data, isLoading } = useOne<Webinar>({
            resource: 'webinars',
            id: cell.value,
          });
          if (isLoading) {
            return <p>loading..</p>;
          }
          return (
            <Link to={'/webinars/show/' + cell.value}>
              id: {cell.value}, name: {data?.data.name}
            </Link>
          );
        },
      },

      {
        id: 'action',
        Header: 'Action',
        accessor: 'id',
        Cell: ({ value }) => (
          <ActionCell
            edit={() => edit('webinarSchedules', value)}
            show={() => show('webinarSchedules', value)}
            remove={() => mutate({ id: value, resource: 'webinarSchedules' })}
          />
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    state: { pageIndex, pageSize, filters },
  } = useTable({ columns }, useFilters, useSortBy, usePagination);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const it = searchParams.entries();
    let pair = it.next();
    while (!pair.done) {
      // console.log(pair);
      const [key, value] = pair.value;
      switch (key) {
        case foreignKeys.webinarId: {
          setFilter(foreignKeys.webinarId, value);
        }
      }
      pair = it.next();
    }
  }, [searchParams, setFilter]);

  return (
    <ListContainer>
      <FiltersContainer>
        <FilterBlock
          filterId="id"
          label="Id"
          inputType="number"
          inputWidth={50}
          filters={filters}
          setFilter={setFilter}
        />
        <FilterBlock
          filterId="webinarId"
          label="Webinar Id"
          inputType="number"
          inputWidth={50}
          inputPlaceholder="id"
          filters={filters}
          setFilter={setFilter}
        />
        <FilterBlock
          filterId="type"
          label="Type"
          inputType="dropdown"
          inputPlaceholder="type"
          filters={filters}
          setFilter={setFilter}
          options={Object.keys(webinarScheduleType)}
        />

        <div className="pl-2 flex">
          <FilledButton onClick={() => create('webinarSchedules')}>
            {CreateIcon}
            <span>Create Schedule</span>
          </FilledButton>

          <FilledButton onClick={() => edit('webinarSchedules','')}>
            {CreateIcon}
            <span>Create Auto Schedule</span>
          </FilledButton>

          <FilledButton onClick={triggerExport}>
            {exportLoading ? 'Downloading' : 'Export'}
          </FilledButton>
        </div>
      </FiltersContainer>
      <GenericTable
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        page={page}
        prepareRow={prepareRow}
      />
      <PageNavigation
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
      />
    </ListContainer>
  );
};
