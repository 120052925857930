import { useRef } from 'react';
import LeftTick from './LeftTick';

interface ILeftAxisProps {
  height: number;
  width: number;
  min: number;
  max: number;
  differencePerTick: number;
}
const LeftAxis: React.FC<ILeftAxisProps> = ({
  width,
  height,
  min,
  max,
  differencePerTick,
}) => {
  const numTicks = Math.floor((max - min) / differencePerTick);
  const heightPerTick = height / numTicks;
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef} className="absolute top-0 left-0 h-full">
      {Array(numTicks + 1)
        .fill(0)
        .map((_, i) => {
          return (
            <LeftTick
              key={i}
              offsetTop={heightPerTick * i}
              value={max - differencePerTick * i}
              showTick={i !== numTicks}
              width={width}
              showGrid={i !== numTicks}
            />
          );
        })}
    </div>
  );
};
export default LeftAxis;
