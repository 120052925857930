import { useNavigation } from '@pankod/refine-core';

import React from 'react';

import FilledButton from 'components/FilledButton';
import FiltersContainer from 'components/FiltersContainer';
import { CreateIcon } from 'components/icons';
import ListContainer from 'components/OuterContainer';

export const CloneWebinarList: React.FC = () => {
  const { create } = useNavigation();

  return (
    <ListContainer>
      <FiltersContainer>
        <div className="pl-2 flex">
          <FilledButton onClick={() => create('cloneWebinar')}>
            {CreateIcon}
            <span>Clone Webinar</span>
          </FilledButton>
        </div>

      </FiltersContainer>
    </ListContainer>
  );
};
