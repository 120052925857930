import classNames from 'classnames';

interface IIndicatorProps {
  variant: 'vertical' | 'horizontal';
  offset: number;
}
const Indicator: React.FC<IIndicatorProps> = ({ variant, offset }) => {
  return (
    <div
      className={classNames({
        'absolute bg-blue-500': true,
        'h-full w-[1px]': variant === 'vertical',
        'w-full h-[1px]': variant === 'horizontal',
      })}
      style={{
        left: variant === 'vertical' ? offset : 0,
        bottom: variant === 'horizontal' ? offset : 0,
      }}
    ></div>
  );
};
export default Indicator;
