import axiosInstance from 'axiosInstance';
import { User } from 'interfaces/apiTypes';
import { createContext, useCallback, useEffect, useState } from 'react';

import { authServer } from 'constants/environmentVars';

const useGlobalContextValue = () => {
  const [currentUser, setCurrentUser] = useState<User>();
  const populateCurrentUser = useCallback(async () => {
    try {
      const response = await fetch(`${authServer}/users/current`, {
        credentials: 'include',
      });
      if (response.status === 200) {
        const user = await response.json();
        setCurrentUser(user);
        const isAdminResponse = await axiosInstance(
          `${authServer}/users/is-webinar-administrator`
        );
        // console.log(isAdminResponse.data);
        setCurrentUser({ ...user, isAdmin: true });
      }
    } catch (err) {}
  }, []);
  useEffect(() => {
    populateCurrentUser();
  }, [populateCurrentUser]);
  return { currentUser, populateCurrentUser };
};
export const GlobalContext = createContext<
  ReturnType<typeof useGlobalContextValue>
>(undefined as any);
export const GlobalContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const globalContextValue = useGlobalContextValue();

  return (
    <GlobalContext.Provider value={globalContextValue}>
      {children}
    </GlobalContext.Provider>
  );
};
