import axiosInstance from 'axiosInstance';
import {
  RecurringSchedule,
  Webinar,
  WebinarSchedule,
  webinarScheduleType,
} from 'interfaces/apiTypes';
import { useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import endpoints from 'constants/endpoints';
import { DEFAULT_MILLISECONDS_AFTER_WHICH_WEBINAR_SCHEDULE_WILL_EXPIRE_AFTER_WEBINAR_STARTS } from 'constants/generalConstants';
import webinarUtils from 'utils/webinarUtils';

import FilledButton from './FilledButton';
import BottomBar from './GenericGraph/Children/BottomBar';
import MutationMessage from './Messages/MutationMessage';

interface ISetupRecurringSchedule {
  webinar: Webinar;
}
const SetupRecurringSchedule: React.FC<ISetupRecurringSchedule> = ({
  webinar,
}) => {
  const queryClient = useQueryClient();
  const [timeValue, setTimeValue] = useState<any>();
  const [barValue, setBarValue] = useState(
    DEFAULT_MILLISECONDS_AFTER_WHICH_WEBINAR_SCHEDULE_WILL_EXPIRE_AFTER_WEBINAR_STARTS
  );

  const expireAt = useMemo(() => {
    return webinarUtils.getFormattedTimer(barValue);
  }, [barValue]);
  const recurringSchedulesQuery = useQuery(
    [endpoints.recurringSchedules, 'webinarId=', webinar.id],
    () => {
      return axiosInstance.get(
        `${endpoints.recurringSchedules}?webinarId=${webinar.id}`
      );
    }
  );
  const recurringSchedules = recurringSchedulesQuery.data?.data;
  const createRecurringScheduleMutation = useMutation(
    () => {
      const currentDate = new Date();
      const currentYearMonthDate = `${currentDate.getFullYear()}:${currentDate.getMonth()}:${currentDate.getDate()}`;
      return axiosInstance.post(endpoints.recurringSchedules, {
        timeOfDay: timeValue,
        currentYearMonthDate: currentYearMonthDate,
        webinarId: webinar.id,
        scheduleExpiresAt: expireAt,
      });
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries([endpoints.recurringSchedules]);
      },
    }
  );

  return (
    <div className="space-y-4">
      {recurringSchedules && (
        <ExistingRecurringSchedules recurringSchedules={recurringSchedules} />
      )}
      <p>Create Recurring Schedule</p>
      <input
        data-test="RecurringScheduleTime"
        type="time"
        value={timeValue}
        onChange={(e) => {
          setTimeValue(e.target.value);
        }}
      />
      <p>Each Recurring Schedule will expire at: {expireAt}</p>

      <BottomBar
        value={barValue}
        onChange={(v) => setBarValue(v)}
        xmax={webinar.duration}
        roundedXmax={webinar.duration}
      />
      {timeValue && (
        <FilledButton data-test="ScheduleButton"
          onClick={() => {
            createRecurringScheduleMutation.mutate();
          }}
        >
          Schedule Webinar at&nbsp; {timeValue} in Webinar
        </FilledButton>
      )}

      <MutationMessage mutation={createRecurringScheduleMutation} />
    </div>
  );
};
export default SetupRecurringSchedule;
const getTodaysStartAndEndDates = () => {
  const currentDate = new Date();
  const startDate = new Date(currentDate.toDateString());
  currentDate.setDate(currentDate.getDate() + 1);
  const endDate = new Date(currentDate.toDateString());
  return {
    startDate,
    endDate,
  };
};

const { startDate, endDate } = getTodaysStartAndEndDates();

interface IExistingRecurringSchedules {
  recurringSchedules: RecurringSchedule[];
}
const ExistingRecurringSchedules: React.FC<IExistingRecurringSchedules> = ({
  recurringSchedules,
}) => {
  const queryClient = useQueryClient();

  const deleteRecurringScheduleMutation = useMutation(
    ({ timeOfDay, webinarId }: { timeOfDay: string; webinarId: number }) => {
      return axiosInstance.delete(
        `${endpoints.recurringSchedules}?timeOfDay=${timeOfDay}&webinarId=${webinarId}`
      );
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries([endpoints.recurringSchedules]);
      },
    }
  );
  const queryParams = useMemo(() => {
    return new URLSearchParams({
      webinarId: `${recurringSchedules[0]?.webinarId}`,
      type: webinarScheduleType.recurringSchedule,
      startTime_gte: startDate.toISOString(),
      startTime_lte: endDate.toISOString(),
      _sort: 'createdAt',
      _order: 'desc',
    }).toString();
  }, [recurringSchedules]);
  const webinarSchedulesQuery = useQuery(
    ['webinarSchedules', queryParams],
    () => {
      return axiosInstance.get(`${endpoints.webinarSchedules}?${queryParams}`);
    },
    {
      enabled: recurringSchedules.length > 0,
    }
  );
  const schedules = useMemo(() => {
    const schedulesWithoutDate = webinarSchedulesQuery.data
      ?.data as WebinarSchedule[];
    if (schedulesWithoutDate) {
      return schedulesWithoutDate.map((s) => ({
        ...s,
        startTime: new Date(s.startTime),
      }));
    }
    return [];
  }, [webinarSchedulesQuery.data?.data]);

  const recurringSchedulesTimeOfDayAndWebinarScheduleMap = useMemo(() => {
    const map: Record<string, WebinarSchedule[]> = {};
    for (let recurringSchedule of recurringSchedules) {
      const [strHours, strMins] = recurringSchedule.timeOfDay.split(':');
      const hours = Number(strHours);
      const mins = Number(strMins);
      const matchingWebinarSchedules = [];
      for (let schedule of schedules) {
        if (
          schedule.startTime.getHours() === hours &&
          schedule.startTime.getMinutes() === mins
        ) {
          matchingWebinarSchedules.push(schedule);
        }
      }
      map[recurringSchedule.timeOfDay] = matchingWebinarSchedules;
    }
    return map;
  }, [recurringSchedules, schedules]);

  return (
    <div>
      <div>
        <p>Existing Recurring Schedules: </p>
      </div>

      <div>
        {recurringSchedules.map((schedule) => (
          <div key={`${schedule.timeOfDay}+${schedule.webinarId}`}>
            <span>{schedule.timeOfDay}</span> &nbsp;
            <span
              className="text-red-500 hover:underline cursor-pointer"
              onClick={() => {
                deleteRecurringScheduleMutation.mutate({
                  timeOfDay: schedule.timeOfDay,
                  webinarId: schedule.webinarId,
                });
              }}
            >
              delete
            </span>
            &nbsp;{' '}
            <span>
              (
              {recurringSchedulesTimeOfDayAndWebinarScheduleMap[
                schedule.timeOfDay
              ]?.map((s) => `${s.id}, `)}
              )
            </span>
          </div>
        ))}
      </div>
      <div className="border border-t mt-3"></div>
    </div>
  );
};
