import { TPoint } from 'interfaces/web';

interface IPointIndicatorProps {
  point: TPoint;
}
export const PointIndicator: React.FC<IPointIndicatorProps> = ({ point }) => {
  return (
    <div
      className="absolute bg-black rounded-full w-3 h-3 -translate-x-1/2 -translate-y-1/2"
      style={{ left: point.left, top: point.top }}
    ></div>
  );
};
