import { useDelete, useExport, useNavigation } from '@pankod/refine-core';
import { Column, useFilters, usePagination, useSortBy, useTable } from '@pankod/refine-react-table';

import React from 'react';

import ActionCell from 'components/ActionCell';
import FilledButton from 'components/FilledButton';
import FilterBlock from 'components/FilterBlock';
import FiltersContainer from 'components/FiltersContainer';
import GenericTable from 'components/GenericTable';
import { CreateIcon } from 'components/icons';
import ListContainer from 'components/OuterContainer';
import PageNavigation from 'components/PageNavigation';

export const WebinarSlot: React.FC = () => {
  const { show, edit, create } = useNavigation();
  const { mutate } = useDelete();
  const { triggerExport, isLoading: exportLoading } = useExport<any>();

  const columns: Array<Column> = React.useMemo(
    () => [
      
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    state: { pageIndex, pageSize, filters },
  } = useTable({ columns }, useFilters, useSortBy, usePagination);

  return (
    <ListContainer>
      <FiltersContainer>
        
        <div className="pl-2 flex">
          <FilledButton onClick={() => create('WebinarSlots')}>
            {CreateIcon}
            <span>Create New Webinar Slots</span>
          </FilledButton>
        </div>
      </FiltersContainer>
     
      
    </ListContainer>
  );
};
