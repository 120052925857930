import { useForm } from '@pankod/refine-react-hook-form';
import axiosInstance from 'axiosInstance';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import FileUpload from 'components/FileUpload';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import InputErrorMessage from 'components/InputErrorMessage';
import MutationMessage from 'components/Messages/MutationMessage';
import { useEffect, useMemo, useRef, useState } from 'react';
import CategoryPill from 'components/CategoryPill';
import { CrossIcon , LoadingIcon } from 'components/icons';
import ItemsContainer from 'components/ItemsContainer';
import { useNavigation, useSelect } from '@pankod/refine-core';
import { AudioBook, AudioBookTopic } from 'interfaces/apiTypes';
import ThumbnailUpload from 'components/ThumbnailUpload';



export const CreateAudioBook: React.FC = () => {

  const { options } = useSelect({
    resource: 'authors',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });

  const {
    refineCore: { formLoading },
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();



  const AddNewAudioBookTopic = useRef<HTMLInputElement>(null);

  const url = window.location.href.split('/');
  const audioBookId = url?.[url.length - 1];

  const navigation = useNavigation();
  const formValues = watch();

  const [uploadedVideo, setUploadedVideo] = useState<any>();
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [showAddNewTopicsInput, setshowAddNewTopicsInput] = useState<any>(false);



  // To get AudioBook Data with Id

  const audioBooksQuery = useQuery(
    ['AudioBooks', audioBookId],
    () =>
      axiosInstance.get<AudioBook>(
        `/v1/audio-books/${audioBookId}`
      ),
    {
      enabled: !!audioBookId,
    }
  );
  const audioBook = audioBooksQuery?.data?.data;


  // To Get AudioBooks Topics to select topic while creating

  // const AudioBooksTopicsQuery = useQuery(['webinarTopics'], () =>
  //     axiosInstance.get<AudioBookTopic[]>(`/webinarTopics`)
  //   );
  //   const AudioBookTopics = AudioBooksTopicsQuery?.data?.data;


  // const addHostMutation = useMutation(
  //   ({
  //     name,
  //     pictureUrlBucket,
  //     pictureUrlPrefix,
  //     title,
  //     company,
  //     pictureUrl,
  //   }: {
  //     name: string;
  //     pictureUrlBucket: string;
  //     pictureUrlPrefix: string;
  //     title: string;
  //     company:string;
  //     pictureUrl: string;
  //   }) => {
  //     return axiosInstance.post(`/hosts`, {
  //       name,
  //       pictureUrlBucket,
  //       pictureUrlPrefix,
  //       title,
  //       company,
  //       pictureUrl,
  //     });
  //   },
  //   {
  //     onSuccess: (response) => {
  //       console.log(response.data);
  //       queryClient.invalidateQueries(['webinars', webinarId, 'hosts']);
  //       reset();
  //     },
  //   }
  // );

  const uploadThumbnailMutation = useMutation(
    ({
      thumbnailUrlBucket,
      thumbnailUrlPrefix,
      videoUuid,
    }: {
      thumbnailUrlBucket: string;
      thumbnailUrlPrefix: string;
      videoUuid: number;
    }) => {
      return axiosInstance.put(
        `${process.env.REACT_APP_CBC_SERVER}/videos/${videoUuid}/thumbnail`,
        {
          thumbnailUrlBucket,
          thumbnailUrlPrefix,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_CBC_TOKEN,
          },
        }
      );
    },
    {
      onSuccess: (response) => {
        //console.log(response.data);
        setUploadedVideo(response.data);
      },
    }
  );

  // All the Functions related to s3

  useEffect(() => {
    (async () => {
      if (formValues.videoUuid) {
        // in case of editing
        // with the video id we need to populate selected video
        const videoResult = await axiosInstance.get(
          `${process.env.REACT_APP_CBC_SERVER}/videos/${formValues.videoUuid}`,
          {
            headers: {
              Authorization: process.env.REACT_APP_CBC_TOKEN,
            },
          }
        );
        setUploadedVideo(videoResult.data);
      }
    })();
  }, [formValues.videoUuid]);

  const uploadVideoFromS3ToVideoContentProvidersMutation = useMutation(
    ({
      s3UrlBucket,
      s3UrlPrefix,
    }: {
      s3UrlBucket: string;
      s3UrlPrefix: string;
    }) => {
      return axiosInstance.post(
        `${process.env.REACT_APP_CBC_SERVER}/videos`,
        {
          s3UrlBucket,
          s3UrlPrefix,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_CBC_TOKEN,
          },
        }
      );
    },
    {
      onSuccess: (response) => {
        console.log("Video Upload Successful")
        console.log("Here is the ----------",response.data);
        setUploadedVideo(response.data);
      },
    }
  );

  useEffect(() => {
    if (uploadedVideo) {
      if (uploadedVideo?.streams?.preferred?.hls?.url) {
        setValue('videoUrl', uploadedVideo?.streams?.preferred?.hls?.url);
      }
      if (uploadedVideo.streams?.thumbnails?.png) {
        setValue('thumbnailUrl', uploadedVideo.streams.thumbnails.png);
      }
      setValue('s3UrlBucket', uploadedVideo.video.s3UrlBucket);
      setValue('s3UrlPrefix', uploadedVideo.video.s3UrlPrefix);
      setValue(
        'thumbnailUrlBucket',
        uploadedVideo.video.thumbnailUrlBucket ?? ''
      );
      setValue(
        'thumbnailUrlPrefix',
        uploadedVideo.video.thumbnailUrlPrefix ?? ''
      );
    }
  }, [setValue, uploadedVideo]); 


  //create new AudioBook Topics

  const [newAudioBookTopics, setNewAudioBookTopics] = useState<any>("");

  const createNewAudioBookTopics = useMutation(
    () => {
      console.log("------------------" , newAudioBookTopics , typeof(newAudioBookTopics))
      AddNewAudioBookTopic.current.value = ""
      setNewAudioBookTopics(newAudioBookTopics.sort())
      setNewAudioBookTopics(newAudioBookTopics.filter((item: any, index: any) => newAudioBookTopics.indexOf(item) === index))
      return axiosInstance.post(`/v1/audioBookTopics` , {
        newAudioBookTopics
      })
    },
    {
      onSuccess: async (data) => {
        window.location.reload();
      },
    }
  )

  const audioBookTopicsQuery = useQuery(['audioBookTopics'], () =>
    axiosInstance.get<AudioBookTopic[]>(`/v1/audioBookTopics`)
  );
  const audioBookTopics = audioBookTopicsQuery?.data?.data;

  const existingTopics = useMemo(() => {
    //@ts-ignore
    return audioBook?.audioBookTopics?.map((item: { name: any; }) => item.name);
    //@ts-ignore
  }, [audioBook?.audioBookTopics]);

  useEffect(() => {
    if (existingTopics) {
      setSelectedTopics(existingTopics);
    }
  }, [existingTopics]);

  // create a new AudioBook
  const createAudioBookMutation = useMutation(
    () => {
      const { title, description , thumbnailUrlBucket , thumbnailPrefix , authorId , learnings ,transcript , s3UrlBucket , s3UrlPrefix } = formValues;
      let audioBookAuthorId = Number(authorId)
      let audioBookLearnings = {}
      audioBookLearnings = learnings.split("|")
      let keys = ["data"]
      let learningsobject = {}
      for(let i = 0; i < keys.length; i++){
        learningsobject[keys[i]] = audioBookLearnings;
      }

      let thumbnailUrl =  `https://${thumbnailUrlBucket}/${thumbnailPrefix}`
    
      return axiosInstance.post(`/v1/audio-books`, {
        title,
        description,
        duration:uploadedVideo.video.durationInMilliseconds || 0,
        audioBookTopics: selectedTopics,
        videoDuration: uploadedVideo.video.durationInMilliseconds || undefined,
        videoUuid: uploadedVideo.video.uuid,
        thumbnailUrl:thumbnailUrl,
        thumbnailPrefix:thumbnailPrefix,
        thumbnailUrlBucket,
        authorId:audioBookAuthorId,
        learnings:learningsobject,
        transcript,
        s3UrlBucket,
        s3UrlPrefix
      });
    },
    {
      onSuccess: async (data) => {
        const audioBooks = data.data;
        navigation.push(`/audio-books/show/${audioBooks.id}`);
      },
      onError: async(err)=>{
        // window.location.reload();
        // navigation.push(`/audio-books/create`);
      }
    },
  );


  // Update an AudioBook
  const updateAudioBookMutation = useMutation(
    () => {
      const { title, description , thumbnailUrlBucket , thumbnailPrefix , authorId  ,transcript , s3UrlBucket , s3UrlPrefix , videoUuid } = formValues;
      let audioBookAuthorId = Number(authorId)

      let thumbnailUrl =  `https://${thumbnailUrlBucket}/${thumbnailPrefix}`
    
      return axiosInstance.patch(`/v1/audio-books/${audioBook.id}`, {
        title,
        description,
        // duration:uploadedVideo.video.durationInMilliseconds || 0,
        // videoDuration: uploadedVideo.video.durationInMilliseconds || undefined,
        // videoUuid: videoUuid,
        thumbnailUrl:thumbnailUrl,
        thumbnailPrefix:thumbnailPrefix,
        thumbnailUrlBucket,
        authorId:audioBookAuthorId,
        transcript,
      });
    },
    {
      onSuccess: async (data) => {
        const audioBooks = data.data;
        navigation.push(`/audio-books/show/${audioBooks.id}`);
      },
      onError: async(err)=>{
        // window.location.reload();
        // navigation.push(`/audio-books/create`);
      }
    },
  );


  
  return (
    <OuterContainer>
      <form autoComplete='off'
        onSubmit={handleSubmit(async (data) => {
          if (audioBook) {
            updateAudioBookMutation.mutate();
          } else {
            createAudioBookMutation.mutate();
          }
        })}
      >
        <ItemsContainer>
        <div className="mt-8 space-y-4">
          {!audioBook && <div>
            <label htmlFor="TopicInput" className="my-label">
                Select Audio Book Topics
              </label>
              <select
                className="my-input mt-[12px]"
                value={''}
                defaultValue=""
                onChange={(e) => {
                  const newTopic = e.target.value;
                  if (!selectedTopics.includes(newTopic)) {
                    setSelectedTopics([...selectedTopics, newTopic]);
                  }
                }}
              >
              <option value={''} disabled>
                Please select
              </option>
              {audioBookTopics?.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
              </select>
                <br/>
              <div className="flex gap-x-2">
                {selectedTopics?.map((item) => {
                  return (
                    <CategoryPill key={item}>
                      <p>{item}</p>
                      <div
                        onClick={() =>
                          setSelectedTopics((prev) => {
                            return prev.filter((i) => i !== item);
                          })
                        }
                        className="w-[16px] cursor-pointer"
                      >
                        {CrossIcon}
                      </div>
                    </CategoryPill>
                  );
                })}
              </div>

              <button
                    type="button"
                    className="px-1 py-2 my-6 border-2 border-purple-400 rounded-md hover:bg-purple-200" onClick={()=>{setshowAddNewTopicsInput(!showAddNewTopicsInput)}}
                  >
                    Create New Topics
              </button>

              {showAddNewTopicsInput  &&
               <div className='pt-[20px]'>
                <label htmlFor="preHeader" className="my-label">
                  Enter Topic Name
                </label>
                
                <input
                  ref={AddNewAudioBookTopic}
                  type="text"
                  id="webinarTopics"
                  className="my-input"
                  placeholder="Growth Hacking"
                  autoComplete='off'
                  onChange={(e)=>{
                    let newAudioBookTopics = e.target.value;
                    if(newAudioBookTopics.length <= 3){
                      newAudioBookTopics = newAudioBookTopics.toUpperCase()
                      let finalAudioBookTopics = []
                      finalAudioBookTopics = newAudioBookTopics.split("?")
                      setNewAudioBookTopics(finalAudioBookTopics)
                    }
                    else{
                      let newAudioBookTopicsArray = newAudioBookTopics.split(" ")
                      for(let i=0; i<newAudioBookTopicsArray.length; i++){
                        newAudioBookTopicsArray[i] = newAudioBookTopicsArray[i].charAt(0).toUpperCase()+ newAudioBookTopicsArray[i].slice(1);
                      }
                      let finalAudioBookTopics = []
                      finalAudioBookTopics = newAudioBookTopicsArray.toString().replaceAll(","," ").split("?")
                      setNewAudioBookTopics(finalAudioBookTopics)
                      }
                  }}
                />
                <button
                  type="button"
                  className="px-1 py-2 my-6 border-2 border-purple-400 rounded-md hover:bg-purple-200" 
                  onClick={()=>{createNewAudioBookTopics.mutate()}}
                >
                  Add Topics
                </button>
              </div>
             } 
          </div>}

          <div className="mt-8 space-y-4">
            <div>
              <label htmlFor="authorId" className="my-label">
                Select Author
              </label>
              <input
                id="authorId"
                list="authors"
                name="authorId"
                className="my-input"
                defaultValue={''}
                {...register('authorId', { required: true })}
              />
              <datalist id="authors">
                <option value={''} disabled>
                  Please select
                </option>
                {options?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </datalist>
              </div>
           </div>

          <div>
            <label htmlFor="title" className="my-label">Title</label>
            <input
              {...register('title', { required: true })}
              className="my-input"  type="text" id="title" placeholder='Enter Title'
            />
          </div>

          <div>
            <label className="my-label">Description</label>
            <input
              {...register('description', { required: true })}
              className="my-input"
            />
          </div>

          {!audioBook && <div>
            <label className="my-label">Enter Learnings (Minimum 3 - Seperate them with | )</label>
             <input
              {...register('learnings', { required: true })}
              className="my-input"
              placeholder='Master the Art of Advertising|Mastering Growth Hacking|Scale your Performance Marketing Campaigns'
            />
          </div>}

          <div>
            <label className="my-label">Enter Transcript</label>
            <input
              {...register('transcript', { required: true })}
              className="my-input"
              placeholder=''
            />
          </div>

          {/* Add Video Section Starts */}
            { !audioBook &&
            <>
            <div>
              <label htmlFor="s3UrlBucket" className="my-label">
                Video Bucket
              </label>
              <input
                {...register('s3UrlBucket', { required: true })}
                type="text"
                id="s3UrlBucket"
                className="my-input"
                placeholder="assets.growthschool.io"
              />
            </div>
            <div>
              <label htmlFor="s3UrlPrefix" className="my-label">
                Video Prefix
              </label>
              <input
                {...register('s3UrlPrefix', { required: true })}
                type="text"
                id="s3UrlPrefix"
                className="my-input"
                placeholder="167ce2b0-50da-47c9-9f6a-37bbadaea18c"
              />
               
            </div>
            
            <div>
              <p className="text-xl mt-4 text-center">OR</p>
              <div>
                <FileUpload
                  acceptedFileType="video"
                  finalFileUrl={formValues.videoUrl}
                  setFinalFileUrl={async (presignedPost) => {
                    if (presignedPost) {
                      setValue('s3UrlBucket', presignedPost.fields['bucket']);
                      setValue('s3UrlPrefix', presignedPost.fields['Key']);
                    }
                  }}
                />
              </div>
              {errors.videoUrl && (
                <InputErrorMessage>
                  <span className="font-medium">Oops!</span> This field is
                  required
                </InputErrorMessage>
              )}
            </div>
            <div>
              <button
                type="button"
                className="px-1 py-2 my-6 border-2 border-purple-400 rounded-md hover:bg-purple-200"
                onClick={() => {
                  const { s3UrlBucket, s3UrlPrefix } = formValues;
                  uploadVideoFromS3ToVideoContentProvidersMutation.mutate({
                    s3UrlBucket: s3UrlBucket,
                    s3UrlPrefix: s3UrlPrefix,
                  });
                }}
              >
                Confirm Video
              </button>
              <div>
                <MutationMessage
                  mutation={uploadVideoFromS3ToVideoContentProvidersMutation}
                />
              </div>
            </div> </>}
              
          {/* Add Video Section ends */}
          {!audioBook &&
          <>
            <label htmlFor="videoUuid" className="my-label">
              Video UUID
            </label>
            <input
              {...register('videoUuid', { required: true })}
              type="text"
              id="videoUuid"
              className="my-input"  
            />
          </>}
          <div>
            <label htmlFor="thumbnailUrlBucket" className="my-label">
              Thumbnail Bucket
            </label>
            <input
              {...register('thumbnailUrlBucket', { required: true })}
              type="text"
              id="thumbnailUrlBucket"
              className="my-input"
              placeholder="assets.growthschool.io"
            />
          </div>
          <div>
            <label htmlFor="thumbnailPrefix" className="my-label">
              Thumbnail Key
            </label>
            <input
              {...register('thumbnailPrefix', { required: true })}
              type="text"
              id="thumbnailPrefix"
              className="my-input"
              placeholder="167ce2b0-50da-47c9-9f6a-37bbadaea18c"
            />
          </div> 
          <div>
            <p className="text-xl mt-4 text-center">OR</p>
            <div>
              <ThumbnailUpload
                acceptedFileType="image"
                finalFileUrl={''}
                thumbnailType='audiobook-thumbnail'
                setFinalFileUrl={(presignedPost) => {
                  if (presignedPost) {
                    setValue(
                      'thumbnailUrlBucket',
                      presignedPost.fields['bucket']
                    );
                    setValue('thumbnailPrefix', presignedPost.fields['Key']);
                    setValue(
                      'pictureUrl',
                      `https://assets.growthschool.io/${presignedPost.fields['Key']}`
                    );
                  }
                }}
              />
            </div>
            {errors.thumbnailUrl && (
              <InputErrorMessage>
                <span className="font-medium">Oops!</span> This field is
                required
              </InputErrorMessage>
            )}
          </div>
          <div>
            <button
              type="button"
              className="px-1 py-2 my-6 border-2 border-purple-400 rounded-md hover:bg-purple-200"
              onClick={() => {
                const { thumbnailUrlBucket, thumbnailPrefix , videoUuid } = formValues;
                if (uploadedVideo?.video.id) {
                  uploadThumbnailMutation.mutate({
                    thumbnailUrlBucket: thumbnailUrlBucket,
                    thumbnailUrlPrefix: thumbnailPrefix,
                    videoUuid: uploadedVideo.video.uuid,
                  });
                }
                if(audioBook){
                  uploadThumbnailMutation.mutate({
                    thumbnailUrlBucket: thumbnailUrlBucket,
                    thumbnailUrlPrefix: thumbnailPrefix,
                    videoUuid: videoUuid,
                  });
                }
              }}
            >
              Confirm Thumbnail
            </button>
            <div>
              <MutationMessage mutation={uploadThumbnailMutation} />
            </div>
          </div>

          {/* Add Thumbnail Section Ends */} 

        </div>
        <br/>
          <SaveButton type="submit">
            {formLoading && LoadingIcon}
            <span>Save</span>
          </SaveButton>
      </ItemsContainer>
      </form>
      <MutationMessage mutation={createAudioBookMutation} />
      <MutationMessage mutation={updateAudioBookMutation} />
    </OuterContainer>
  );
};
