import { useRef } from 'react';
import BottomTick from './BottomTick';

interface IBottomAxisProps {
  width: number;
  height: number;
  min: number;
  max: number;
  differencePerTick: number;
}
const BottomAxis: React.FC<IBottomAxisProps> = ({
  min,
  max,
  differencePerTick,
  width,
  height,
}) => {
  const numTicks = Math.floor((max - min) / differencePerTick);
  const widthPerTick = width / numTicks;
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef} className="absolute top-full left-0 w-full">
      {Array(numTicks)
        .fill(0)
        .map((_, i) => {
          return (
            <BottomTick
              key={i}
              offsetLeft={widthPerTick * i}
              value={min + differencePerTick * i}
              showValue={i !== 0}
              showTick={i !== 0}
              showGrid={i !== 0}
              height={height}
            />
          );
        })}
    </div>
  );
};
export default BottomAxis;
