import IconButton from './IconButton';
import { ChevronLeftIcon, ChevronRightIcon, ChevronsLeftIcon, ChevronsRightIcon } from './icons';

const PageNavigation = ({
  canPreviousPage,
  canNextPage,
  pageOptions,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  pageIndex,
  pageSize,
}: any) => {
  return (
    <div className="mt-2 flex items-center justify-end gap-4">
      <div className="flex gap-1">
        <IconButton
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
          data-test="first-page-button"
        >
          {ChevronsLeftIcon}
        </IconButton>
        <IconButton
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          data-test="previous-page-button"
        >
          {ChevronLeftIcon}
        </IconButton>
        <IconButton
          onClick={() => nextPage()}
          disabled={!canNextPage}
          data-test="next-page-button"
        >
          {ChevronRightIcon}
        </IconButton>
        <IconButton
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
          data-test="last-page-button"
        >
          {ChevronsRightIcon}
        </IconButton>
      </div>
      <span>
        Page{' '}
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>
      </span>
      <span>
        Go to page:
        <input
          type="number"
          defaultValue={pageIndex + 1}
          onChange={(e) => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0;
            gotoPage(page);
          }}
          className="w-12 rounded border border-gray-200 p-1 text-gray-700"
        />
      </span>
      <select
        value={pageSize}
        onChange={(e) => {
          setPageSize(Number(e.target.value));
        }}
        className="rounded border border-gray-200 p-1 text-gray-700"
      >
        {[10, 20, 30, 40, 50].map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
    </div>
  );
};
export default PageNavigation;
