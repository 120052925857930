import { useForm } from '@pankod/refine-react-hook-form';
import moment from 'moment';
import {useState , useRef  } from 'react';
import { useMutation, useQuery } from 'react-query';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import Papa from "papaparse";
import axiosInstance from 'axiosInstance';
import { useSelect } from '@pankod/refine-core';
import { Host } from 'interfaces/apiTypes';

// Allowed input file
const allowedExtensions = ["csv"];

export const HostCorrectionScript: React.FC = () => {

  const [CreationStatus, setCreationStatus] = useState("");

    // This state will store the parsed data
    const [data, setData] = useState([]);
        
    // It state will contain the error when
    // correct file extension is not used
    const [error, setError] = useState("");
    
    // store the file uploaded by the user
    const [file, setFile] = useState();

    const handleFileChange = (e) => {
      setError("");
      
      // Check if user has entered the file
      if (e.target.files.length) {
          const inputFile = e.target.files[0];
          
          // Check the file extensions, if it is not in allowed format throw error
          const fileExtension = inputFile?.type.split("/")[1];
          if (!allowedExtensions.includes(fileExtension)) {
              setError("Please input a csv file");
              return;
          }

          // If input type is correct set the state
          setFile(inputFile);
      }
    };

    const HandleParse = () => {
        
        // If user clicks the parse button without a file we show a error
        if (!file) return setError("Enter a valid file");

        // Initialize a reader which allows user to read any file or blob.
        const reader = new FileReader();
        
        // Event listener on reader when the file loads, we parse it and set the data.
        reader.onload = async ({ target }) => {
            const csv = Papa.parse(target.result, { header: true });
            const parsedData = csv?.data;
            setData(parsedData);

              createScheduleMutation.mutate(parsedData)
        };
        
        reader.readAsText(file);
    };

    const createScheduleMutation = useMutation(
      (parsedData) => {
        console.log("i came in",parsedData)
        return axiosInstance.post("/v1/webinarSchedules/create-calender-invites", {
          parsedData
        });
      },
      {
        onSuccess: (response) => {
          setCreationStatus("SuccessFully Corrected Calender Events")
        },
        onError: async(error)=>{
          setCreationStatus("Calender Events Creation Failed Please check Data Once")    
          console.log(error)  
        }
      }
    );

    return (
    <OuterContainer>
          <div>
            <label htmlFor="csvInput" style={{ display: "block" }}>
                Upload CSV File For Creating Calender Event - 
            </label>
            <br/>
            <input
                onChange={handleFileChange}
                id="csvInput"
                name="file"
                type="File"
            />
            <br/><br/>
            <div>
                <SaveButton onClick={HandleParse}>Create Calender Events</SaveButton>
            </div>
            <br/>
            <h3 className='text-[25px]'>{CreationStatus}</h3>
        </div>
    </OuterContainer>
  );
};