import classNames from 'classnames';
import { Dispatch, SetStateAction } from 'react';
import {TPartialMessage,TPartialMessageWithReplies} from 'interfaces/apiTypes'

import ProfileOffset from './Profile/Children/ProfileOffset';
import Profile from './Profile/Profile';
import Message from './Message';
import { TFirebaseMessageReplies } from 'pages/webinarChat';

interface IChatMessageProps {
  message: TFirebaseMessageReplies;
  theme?: 'dark' | 'light';
  padded?: boolean;
  collapseMessage?: boolean;
  reply?: boolean;
  webinarSignupId?: number;
  onSelectionChange?: (isSelected: boolean) => void;
  onReplyClick?: (value:any) => void
}
const ChatMessage: React.FC<IChatMessageProps> = ({
  message,
  theme = 'light',
  padded = false,
  collapseMessage = false,
  reply = false,
  webinarSignupId,
  onSelectionChange,
  onReplyClick
}) => {
  return (
    <div className={message.isHighlighted ? 'bg-[#FDF3D3]' : ''}>
      <div className='flex gap-x-[16px] px-[16px] '>
        <div className='flex gap-x-[16px]'>
      {/* {message.showInChat && <div className="p-[8px] rounded-[4px] text-white bg-[#6466F1]">Published</div>  } */}

        {!reply && 
          (<div className='pt-[4px]'>
            <input
              type="checkbox"
              className="mr-[10px]"
              onChange={(e) => {
              if (onSelectionChange) {
                onSelectionChange(e.target.checked);
              }
            }}
          /></div>
          )}
          <div className="flex flex-col gap-y-[16px]">
            <div
              data-test="chat-message"
              className={classNames({
                'rounded-lg': true,
                'bg-[color:var(--text-main)]': theme === 'dark',
                'py-2 px-2.5': padded,
              })}
            >
                <Profile theme={theme} message={message} reply={reply} />
                <ProfileOffset reply={reply}>
                  <Message theme={theme} collapseMessage={collapseMessage}>
                    {message.text}
                  </Message>
                  {!reply && !message?.adminSignupId && 
                    <div
                      className="text-[12px] leading-[16.8px] underline cursor-pointer"
                      onClick={() => {
                        onReplyClick(message)
                      }}
                    >
                      Reply
                    </div>
                  }
                  {reply && (
                    <div
                      className="text-[12px] leading-[16.8px] underline cursor-pointer"
                      onClick={() => {
                        onReplyClick(message)
                      }}
                    >
                      Reply
                    </div>
                  )}
                </ProfileOffset>
            </div>
            <div className='pl-[15px]'>
              {message?.replies && (
                <div
                  className={`ml-[48px] ${
                    message?.replies?.length > 0 ? 'flex' : 'hidden'
                  } flex-col  gap-y-[16px]`}
                  // key={message?.docId}
                >
                  {message?.replies?.map((reply: any) => {
                    return (
                      <>
                        <ChatMessage
                          key={reply?.text}
                          message={reply}
                          theme={theme}
                          collapseMessage={collapseMessage}
                          padded={false}
                          reply={true}
                          webinarSignupId={webinarSignupId}
                          onReplyClick={onReplyClick}
                        />
                      </>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          </div>
          {message.showInChat && <div className="p-[8px] text-center flex items-center  rounded-[4px] text-white h-fit text-[12px] leading-[16.8px] bg-[#6466F1]">Published</div>  }
      </div>
    </div>
  );
};
export default ChatMessage;