import { TCoordPoint } from 'interfaces/web';

import { millisecondsInOne } from 'constants/generalConstants';
import { TWebinarSchedule, Webinar, WebinarSchedule } from 'interfaces/apiTypes';

const getYAtXFromGraphPoints = (points: TCoordPoint[], currentX: number) => {
  if (points.length === 0) return 0;
  let p1: TCoordPoint | undefined;
  let p2: TCoordPoint | undefined;
  for (let i = 1; i < points.length; i++) {
    if (points[i].x >= currentX) {
      p2 = points[i];
      p1 = points[i - 1];
      break;
    }
  }
  if (p1 && p2) {
    const y = ((p2.y - p1.y) * (currentX - p1.x)) / (p2.x - p1.x) + p1.y;
    return Math.max(0, y);
  }
  return points[points.length - 1].y;
};
const getTimeDifference = (timeDiff: number) => {
  let negative = false;
  if (timeDiff < 0) {
    negative = true;
    timeDiff = Math.abs(timeDiff);
  }
  const days = Math.floor(timeDiff / millisecondsInOne.day);
  timeDiff -= days * millisecondsInOne.day;
  const hours = Math.floor(timeDiff / millisecondsInOne.hour);
  timeDiff -= hours * millisecondsInOne.hour;
  const minutes = Math.floor(timeDiff / millisecondsInOne.minute);
  timeDiff -= minutes * millisecondsInOne.minute;
  const seconds = Math.floor(timeDiff / millisecondsInOne.second);
  return { days, hours, minutes, seconds, negative };
};
const getFormattedTimer = (
  timeDiff: number,
  startFrom: 'days' | 'hours' | 'minutes' | 'seconds' = 'minutes'
) => {
  const values = getTimeDifference(timeDiff);
  const { days, hours, minutes, seconds, negative } = values;
  let timerString = '';
  if (days || ['days'].includes(startFrom)) {
    timerString += String(days ?? '').padStart(2, '0');
    timerString += ':';
  }
  if (days || hours || ['days', 'hours'].includes(startFrom)) {
    timerString += String(hours ?? '').padStart(2, '0');
    timerString += ':';
  }
  if (
    days ||
    hours ||
    minutes ||
    ['days', 'hours', 'minutes'].includes(startFrom)
  ) {
    timerString += String(minutes ?? '').padStart(2, '0');
    timerString += ':';
  }
  if (
    days ||
    hours ||
    minutes ||
    seconds ||
    ['days', 'hours', 'minutes', 'seconds'].includes(startFrom)
  ) {
    timerString += String(seconds ?? '').padStart(2, '0');
  }
  if (negative) {
    return `-${timerString}`;
  }
  return timerString;
};
/**
 *
 * @param time string timeStamp eg- 00:04:47
 */
const timeStampToMilliseconds = (time: string) => {
  let negative = false;
  if (time.startsWith('-')) {
    negative = true;
    time = time.slice(1, time.length);
  }
  const splitted = time.split(':');
  const values = splitted.map((s) => +s);
  values.reverse();
  const [seconds, minutes, hours, days] = values;
  let milliseconds = 0;
  if (seconds) {
    milliseconds += seconds * millisecondsInOne.second;
  }
  if (minutes) {
    milliseconds += minutes * millisecondsInOne.minute;
  }
  if (hours) {
    milliseconds += hours * millisecondsInOne.hour;
  }
  if (days) {
    milliseconds += days * millisecondsInOne.day;
  }
  if (negative) {
    return -milliseconds;
  }
  return milliseconds;
};

const getColorPalette = (paletteId : any) => {
  let cardColor,cardMentorColor,mentorDetails,webinarDetails;
  let colorPalette = []
      if (paletteId !== null) {
        if (paletteId == 1) {
          cardColor = 'bg-[#F6C94B]';
          cardMentorColor = 'bg-[#EAAF09]';
          webinarDetails = 'text-[#421F03]';
          mentorDetails = 'text-[#030A21]';
          colorPalette.push({
            cardColor: cardColor,
            cardMentorColor: cardMentorColor,
            webinarDetails: webinarDetails,
            mentorDetails: mentorDetails,
          });
        }
        if (paletteId == 2) {
          cardColor = 'bg-[#63CCA7]';
          cardMentorColor = 'bg-[#1F9668]';
          webinarDetails = 'text-[#0A4732]';
          mentorDetails = 'text-[#030A21]';
          colorPalette.push({
            cardColor: cardColor,
            cardMentorColor: cardMentorColor,
            webinarDetails: webinarDetails,
            mentorDetails: mentorDetails,
          });
        }
        if (paletteId == 3) {
          cardColor = 'bg-[#E14F8E]';
          cardMentorColor = 'bg-[#CB326B]';
          webinarDetails = 'text-[#FFFFFF]';
          mentorDetails = 'text-[#FFFFFF]';
          colorPalette.push({
            cardColor: cardColor,
            cardMentorColor: cardMentorColor,
            webinarDetails: webinarDetails,
            mentorDetails: mentorDetails,
          });
        }
        if (paletteId == 4) {
          cardColor = 'bg-[#8D4CD8]';
          cardMentorColor = 'bg-[#6724B2]';
          webinarDetails = 'text-[#FFFFFF]';
          mentorDetails = 'text-[#FFFFFF]';
          colorPalette.push({
            cardColor: cardColor,
            cardMentorColor: cardMentorColor,
            webinarDetails: webinarDetails,
            mentorDetails: mentorDetails,
          });
        }
        if (paletteId == 5) {
          cardColor = 'bg-[#5554ED]';
          cardMentorColor = 'bg-[#4646B1]';
          webinarDetails = 'text-[#FFFFFF]';
          mentorDetails = 'text-[#FFFFFF]';
          colorPalette.push({
            cardColor: cardColor,
            cardMentorColor: cardMentorColor,
            webinarDetails: webinarDetails,
            mentorDetails: mentorDetails,
          });
        }
        if (paletteId == 6) {
          cardColor = 'bg-[#EDA24C]';
          cardMentorColor = 'bg-[#DF7D2F]';
          webinarDetails = 'text-[#421F03]';
          mentorDetails = 'text-[#030A21]';
          colorPalette.push({
            cardColor: cardColor,
            cardMentorColor: cardMentorColor,
            webinarDetails: webinarDetails,
            mentorDetails: mentorDetails,
          });
        }
        if (paletteId == 7) {
          cardColor = 'bg-[#FCEFC7]';
          cardMentorColor = 'bg-[#F6C94B]';
          webinarDetails = 'text-[#421F03]';
          mentorDetails = 'text-[#030A21]';
          colorPalette.push({
            cardColor: cardColor,
            cardMentorColor: cardMentorColor,
            webinarDetails: webinarDetails,
            mentorDetails: mentorDetails,
          });
        }
        if (paletteId == 8) {
          cardColor = 'bg-[#FCEBD4]';
          cardMentorColor = 'bg-[#EDA24C]';
          webinarDetails = 'text-[#AA5009]';
          mentorDetails = 'text-[#030A21]';
          colorPalette.push({
            cardColor: cardColor,
            cardMentorColor: cardMentorColor,
            webinarDetails: webinarDetails,
            mentorDetails: mentorDetails,
          });
        }
        if (paletteId == 9) {
          cardColor = 'bg-[#F8D7E7]';
          cardMentorColor = 'bg-[#E15893]';
          webinarDetails = 'text-[#B9235B]';
          mentorDetails = 'text-[#030A21]';
          colorPalette.push({
            cardColor: cardColor,
            cardMentorColor: cardMentorColor,
            webinarDetails: webinarDetails,
            mentorDetails: mentorDetails,
          });
        }
        if (paletteId == 10) {
          cardColor = 'bg-[#E9D7FC]';
          cardMentorColor = 'bg-[#8D4CD8]';
          webinarDetails = 'text-[#6724B2]';
          mentorDetails = 'text-[#FFFFFF]';
          colorPalette.push({
            cardColor: cardColor,
            cardMentorColor: cardMentorColor,
            webinarDetails: webinarDetails,
            mentorDetails: mentorDetails,
          });
        }
        if (paletteId == 11) {
          cardColor = 'bg-[#D6D9FB]';
          cardMentorColor = 'bg-[#5554ED]';
          webinarDetails = 'text-[#4646B1]';
          mentorDetails = 'text-[#FFFFFF]';
          colorPalette.push({
            cardColor: cardColor,
            cardMentorColor: cardMentorColor,
            webinarDetails: webinarDetails,
            mentorDetails: mentorDetails,
          });
        }
        if (paletteId == 12) {
          cardColor = 'bg-[#CEF0E7]';
          cardMentorColor = 'bg-[#63CCA7]';
          webinarDetails = 'text-[#0B764D]';
          mentorDetails = 'text-[#030A21]';
          colorPalette.push({
            cardColor: cardColor,
            cardMentorColor: cardMentorColor,
            webinarDetails: webinarDetails,
            mentorDetails: mentorDetails,
          });
        }
      } else {
        cardColor = 'bg-[#63CCA7]';
        cardMentorColor = 'bg-[#1F9668]';
        webinarDetails = 'text-[#421F03]';
        mentorDetails = 'text-[#030A21]';
        colorPalette.push({
          cardColor: cardColor,
          cardMentorColor: cardMentorColor,
          webinarDetails: webinarDetails,
          mentorDetails: mentorDetails,
        });
      }
        return colorPalette;
}

const getElapsedTimeForWebinar = (webinarSchedule: TWebinarSchedule) => {
  return Date.now() - new Date(webinarSchedule?.startTime)?.getTime();
};

const millisecondsToTimeStamp = (
  timeDiff: number,
  startFrom: 'days' | 'hours' | 'minutes' | 'seconds' = 'hours'
) => {
  const values = getTimeDifference(timeDiff);
  const { days, hours, minutes, seconds, negative } = values;
  let timerString = '';
  if (days || ['days'].includes(startFrom)) {
    timerString += formatTimeUnit(days);
    timerString += ':';
  }
  if (days || hours || ['days', 'hours'].includes(startFrom)) {
    timerString += formatTimeUnit(hours);
    timerString += ':';
  }
  if (
    days ||
    hours ||
    minutes ||
    ['days', 'hours', 'minutes'].includes(startFrom)
  ) {
    timerString += formatTimeUnit(minutes);
    timerString += ':';
  }
  if (
    days ||
    hours ||
    minutes ||
    seconds ||
    ['days', 'hours', 'minutes', 'seconds'].includes(startFrom)
  ) {
    timerString += formatTimeUnit(seconds);
  }
  if (negative) {
    return `-${timerString}`;
  }
  return timerString;
};

export const formatTimeUnit = (n: number | string = '') => {
  const str = String(n);
  const withoutIntialZeroes = removeInitialZeroesFromString(str);
  const result = withoutIntialZeroes.padStart(2, '0');
  return result;
};

const removeInitialZeroesFromString = (str: string) => {
  let i = 0;
  for (; i < str.length; i++) {
    if (str[i] !== '0') {
      break;
    }
  }
  return str.slice(i, str.length);
};

const getWebinarPlaybackDetails = (webinar: Webinar , webinarSchedule:WebinarSchedule) => {
  const elapsedTime = getCappedElapsedTimeForWebinar(
    webinar,
    webinarSchedule,
  );
  return {
    webinar_playback_timestamp:
      webinarUtils.millisecondsToTimeStamp(elapsedTime),
    // this is capped but in milliseconds
    elapsed_time: elapsedTime,
  };
};

const getCappedElapsedTimeForWebinar = (
  webinar:Webinar,
  webinarSchedule:WebinarSchedule ) => {
  const elapsedTime = getElapsedTimeForWebinar(webinarSchedule);
  return getValueWithInRange(elapsedTime, {
    min: 0,
    max: webinar.duration,
  });
};

const getValueWithInRange = (
  value: number,
  { min, max }: { min: number; max: number }
) => {
  return Math.min(max, Math.max(min, value));
};


const webinarUtils = {
  getYAtXFromGraphPoints,
  getFormattedTimer,
  timeStampToMilliseconds,
  getColorPalette,
  millisecondsToTimeStamp,
  getElapsedTimeForWebinar,
  getWebinarPlaybackDetails,
  getCappedElapsedTimeForWebinar,
  getValueWithInRange
};
export default webinarUtils;
