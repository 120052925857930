import axiosInstance from 'axiosInstance';
import { WebinarScheduleType } from 'interfaces/apiTypes';
import { useMutation, useQueryClient } from 'react-query';

import endpoints from 'constants/endpoints';

const useCreateWebinarScheduleMutation = ({
  startTime,
  expireAt,
  webinarId,
  type,
}: {
  startTime: Date;
  expireAt: string;
  type: WebinarScheduleType;
  webinarId: number;
}) => {
  const queryClient = useQueryClient();
  const createScheduleMutation = useMutation(
    () => {
      return axiosInstance.post(`${endpoints.webinarSchedules}`, {
        startTime: startTime.toISOString(),
        webinarId,
        expireAt: expireAt,
        type,
      });
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries([
          endpoints.webinarSchedules,
          'webinarId=',
          webinarId,
        ]);
      },
    }
  );
  return createScheduleMutation;
};
export default useCreateWebinarScheduleMutation;
