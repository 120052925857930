import axiosInstance from 'axiosInstance';
import { Webinar } from 'interfaces/apiTypes';
import { TPoint } from 'interfaces/web';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import GenericGraph from 'components/GenericGraph/GenericGraph';
import MutationMessage from 'components/Messages/MutationMessage';
import { millisecondsInOne } from 'constants/generalConstants';

interface ILivePeopleGraphProps {
  webinar: Webinar;
}

const LivePeopleGraph: React.FC<ILivePeopleGraphProps> = ({ webinar }) => {
  const videoDurationInMinutes = Math.floor(
    (webinar.duration ?? 0) / millisecondsInOne.minute
  );
  const [maxPeople, setMaxPeople] = useState(500);
  const queryClient = useQueryClient();
  const livePeopleGraphsQuery = useQuery(
    ['livePeopleGraphs', 'webinarId=' + webinar.id],
    () => axiosInstance.get(`/v1/livePeopleGraphs?webinarId=${webinar.id}`)
  );
  const graph = livePeopleGraphsQuery.data?.data[0];
  // console.log({ graph });
  const pointsQuery = useQuery(
    ['points', 'graphId=' + graph?.graphId],
    () => axiosInstance.get(`/v1/points?graphId=${graph.graphId}`),
    {
      enabled: !!graph?.graphId,
    }
  );
  const points = pointsQuery.data?.data;

  useEffect(() => {
    if (points && points.length) {
      let ymaxAmongAllPoints = points[0].y;
      for (let i = 1; i < points.length; i++) {
        ymaxAmongAllPoints = Math.max(points[i].y, ymaxAmongAllPoints);
      }
      const value = (Math.ceil(ymaxAmongAllPoints / 100) + 1) * 100;
      setMaxPeople(value);
    }
  }, [points]);
  const updateLivePeopleGraphMutation = useMutation(
    (
      points: {
        x: number;
        y: number;
      }[]
    ) =>
      axiosInstance.post(`/v1/webinars/${webinar.id}/livePeopleGraphs`, {
        points,
      }),
    {
      onSuccess: (response) => {
        console.log(response.data);
        queryClient.invalidateQueries([
          'livePeopleGraphs',
          'webinarId=' + webinar.id,
        ]);
      },
    }
  );
  const handleUpdateLivePeopleGraph = async (points: TPoint[]) => {
    const pointsData = points.map((p) => {
      return {
        x: p.x,
        y: p.y,
      };
    });
    updateLivePeopleGraphMutation.mutate(pointsData);
  };

  return (
    <div>
      <div className="mb-6 flex items-center space-x-3">
        <label className="whitespace-nowrap">Max People: </label>
        <input
          type="number"
          className="my-input max-w-[100px]"
          value={maxPeople}
          onChange={(e) => {
            const value = +e.target.value;
            setMaxPeople(value);
          }}
        />
      </div>
      {videoDurationInMinutes > 0 && maxPeople > 0 && (
        <GenericGraph
          confirm={(points) => {
            handleUpdateLivePeopleGraph(points);
          }}
          height={300}
          width={700}
          xmax={videoDurationInMinutes}
          initialPoints={points}
          xAxisDifferencePerTick={10}
          yAxisDifferencePerTick={100}
          ymax={maxPeople}
          xlabel="Minutes"
          ylabel="People Live"
        />
      )}
      <div className="flex-center mt-4">
        <MutationMessage mutation={updateLivePeopleGraphMutation} />
      </div>
    </div>
  );
};
export default LivePeopleGraph;
