interface IBarLineProps {
  value: number;
  min: number;
  max: number;
  width: number;
}
const BarLine: React.FC<IBarLineProps> = ({ value, min, max, width }) => {
  return (
    <div
      className="absolute h-full top-0 w-[1px] bg-red-300"
      style={{ left: (value / (max - min)) * width }}
    ></div>
  );
};
export default BarLine;
