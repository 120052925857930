import useCreateWebinarScheduleMutation from 'apiHooks.ts/useCreateWebinarScheduleMutation';
import { useState } from 'react';

import {
  DEFAULT_MILLISECONDS_AFTER_WHICH_WEBINAR_SCHEDULE_WILL_EXPIRE_AFTER_WEBINAR_STARTS,
  millisecondsInOne,
} from 'constants/generalConstants';
import webinarUtils from 'utils/webinarUtils';

import FilledButton from './FilledButton';
import BottomBar from './GenericGraph/Children/BottomBar';
import MutationMessage from './Messages/MutationMessage';

interface IScheduleWebinarToParticularPoint {
  webinarDuration: number;
  webinarId: number;
}
const ScheduleWebinarToParticularPoint: React.FC<
  IScheduleWebinarToParticularPoint
> = ({ webinarDuration, webinarId }) => {
  const videoDurationInMinutes = Math.floor(
    (webinarDuration ?? 0) / millisecondsInOne.minute
  );
  const [barValue, setBarValue] = useState(0);
  const [expiryBarValue, setExpiryBarValue] = useState(
    DEFAULT_MILLISECONDS_AFTER_WHICH_WEBINAR_SCHEDULE_WILL_EXPIRE_AFTER_WEBINAR_STARTS
  );

  const createScheduleMutation = useCreateWebinarScheduleMutation({
    startTime: new Date(Date.now() - barValue * millisecondsInOne.minute),
    webinarId,
    expireAt: webinarUtils.getFormattedTimer(expiryBarValue),
    type: 'particularTime',
  });
  return (
    <div className="space-y-4">
      <p>
        Time:{' '}
        {webinarUtils.getFormattedTimer(barValue * millisecondsInOne.minute)}{' '}
      </p>
      <BottomBar
        value={barValue}
        roundedXmax={videoDurationInMinutes}
        xmax={videoDurationInMinutes}
        onChange={(v) => setBarValue(v)}
      />

      <p>
        Above Schedule will expire at:{' '}
        {webinarUtils.getFormattedTimer(expiryBarValue)}
      </p>
      <BottomBar
        value={expiryBarValue}
        xmax={webinarDuration}
        roundedXmax={webinarDuration}
        onChange={(v) => setExpiryBarValue(v)}
      />
      <FilledButton
        onClick={() => {
          createScheduleMutation.mutate();
        }}
      >
        Schedule at&nbsp;
        {webinarUtils.getFormattedTimer(
          barValue * millisecondsInOne.minute
        )}{' '}
        point in Webinar
      </FilledButton>

      {createScheduleMutation.isSuccess && (
        <p>
          Schedule ID ({createScheduleMutation.data.data.id}) at{' '}
          {new Date(
            createScheduleMutation.data?.data.startTime
          ).toLocaleString()}
        </p>
      )}

      <div>
        <MutationMessage mutation={createScheduleMutation} />
      </div>
    </div>
  );
};
export default ScheduleWebinarToParticularPoint;
