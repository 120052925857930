import { TPoint } from 'interfaces/web';
import { useRef } from 'react';

interface IAxisCoordClickListenerProps {
  width: number;
  height: number;
  xmin: number;
  xmax: number;
  ymin: number;
  ymax: number;
  onPointClicked: (point: TPoint) => void;
}
const AxisCoordClickListener: React.FC<IAxisCoordClickListenerProps> = ({
  xmin,
  xmax,
  ymin,
  ymax,
  width,
  height,
  onPointClicked,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <div
      ref={containerRef}
      className="absolute-cover z-20"
      onClick={(e) => {
        if (containerRef.current) {
          const box = containerRef.current.getBoundingClientRect();
          const leftOffset = e.clientX - box.left;
          const horizontalRatio = leftOffset / width;
          const xCoord = (xmax - xmin) * horizontalRatio + xmin;
          const topOffset = e.clientY - box.top;
          const revertedTopOffset = height - topOffset;
          const verticalRatio = revertedTopOffset / height;
          const yCoord = (ymax - ymin) * verticalRatio + ymin;
          onPointClicked({
            x: xCoord,
            y: yCoord,
            left: leftOffset,
            top: topOffset,
          });
        }
      }}
    ></div>
  );
};
export default AxisCoordClickListener;
