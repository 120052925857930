import { useForm } from '@pankod/refine-react-hook-form';
import axiosInstance from 'axiosInstance';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import FileUpload from 'components/FileUpload';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import InputErrorMessage from 'components/InputErrorMessage';
import MutationMessage from 'components/Messages/MutationMessage';
import { useEffect, useMemo, useRef, useState } from 'react';
import CategoryPill from 'components/CategoryPill';
import { CrossIcon , LoadingIcon } from 'components/icons';
import ItemsContainer from 'components/ItemsContainer';
import { useNavigation, useSelect } from '@pankod/refine-core';
import { AudioBook, AudioBookTopic, WebinarCategory } from 'interfaces/apiTypes';
import ThumbnailUpload from 'components/ThumbnailUpload';


export const CreateGuide: React.FC = () => {

  const { options } = useSelect({
    resource: 'hosts',
    optionLabel: 'name',
    fetchSize: 100000000000000,
  });

  const {
    refineCore: { formLoading },
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm();



  const AddNewGuideTopic = useRef<HTMLInputElement>(null);

  const url = window.location.href.split('/');
  const guideId = url?.[url.length - 1];
  const navigation = useNavigation();
  const formValues = watch();
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [uploadedVideo, setUploadedVideo] = useState<any>();
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [showAddNewTopicsInput, setshowAddNewTopicsInput] = useState<any>(false);

  const [randomThemeColorId, setRandomThemeColorId] = useState<number>();

  useEffect(() => {
    setRandomThemeColorId(Math.floor(Math.random() * 10) + 1);
  }, []);

  let findThemeShape = Math.floor(Math.random() * 6) + 1;

  let guideThemeShape = `https://assets.growthschool.io/guides-svgs/Frame${randomThemeColorId}_${findThemeShape}.svg`

  // To get AudioBook Data with Id

  const guidesQuery = useQuery(
    ['Guides', guideId],
    () =>
      axiosInstance.get<AudioBook>(
        `/v1/guides/${guideId}`
      ),
    {
      enabled: !!guideId,
    }
  );
  const guide = guidesQuery?.data?.data;

  console.log("------------------" , guide)

  // To Get AudioBooks Topics to select topic while creating

  // const AudioBooksTopicsQuery = useQuery(['webinarTopics'], () =>
  //     axiosInstance.get<AudioBookTopic[]>(`/webinarTopics`)
  //   );
  //   const AudioBookTopics = AudioBooksTopicsQuery?.data?.data;


  // const addHostMutation = useMutation(
  //   ({
  //     name,
  //     pictureUrlBucket,
  //     pictureUrlPrefix,
  //     title,
  //     company,
  //     pictureUrl,
  //   }: {
  //     name: string;
  //     pictureUrlBucket: string;
  //     pictureUrlPrefix: string;
  //     title: string;
  //     company:string;
  //     pictureUrl: string;
  //   }) => {
  //     return axiosInstance.post(`/hosts`, {
  //       name,
  //       pictureUrlBucket,
  //       pictureUrlPrefix,
  //       title,
  //       company,
  //       pictureUrl,
  //     });
  //   },
  //   {
  //     onSuccess: (response) => {
  //       console.log(response.data);
  //       queryClient.invalidateQueries(['webinars', webinarId, 'hosts']);
  //       reset();
  //     },
  //   }
  // );

  const uploadThumbnailMutation = useMutation(
    ({
      thumbnailUrlBucket,
      thumbnailUrlPrefix,
      videoUuid,
    }: {
      thumbnailUrlBucket: string;
      thumbnailUrlPrefix: string;
      videoUuid: number;
    }) => {
      return axiosInstance.put(
        `${process.env.REACT_APP_CBC_SERVER}/videos/${videoUuid}/thumbnail`,
        {
          thumbnailUrlBucket,
          thumbnailUrlPrefix,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_CBC_TOKEN,
          },
        }
      );
    },
    {
      onSuccess: (response) => {
        //console.log(response.data);
        setUploadedVideo(response.data);
      },
    }
  );

  // All the Functions related to s3

  useEffect(() => {
    (async () => {
      if (formValues.videoUuid) {
        // in case of editing
        // with the video id we need to populate selected video
        const videoResult = await axiosInstance.get(
          `${process.env.REACT_APP_CBC_SERVER}/videos/${formValues.videoUuid}`,
          {
            headers: {
              Authorization: process.env.REACT_APP_CBC_TOKEN,
            },
          }
        );
        setUploadedVideo(videoResult.data);
      }
    })();
  }, [formValues.videoUuid]);

  const uploadVideoFromS3ToVideoContentProvidersMutation = useMutation(
    ({
      s3UrlBucket,
      s3UrlPrefix,
    }: {
      s3UrlBucket: string;
      s3UrlPrefix: string;
    }) => {
      return axiosInstance.post(
        `${process.env.REACT_APP_CBC_SERVER}/videos`,
        {
          s3UrlBucket,
          s3UrlPrefix,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_CBC_TOKEN,
          },
        }
      );
    },
    {
      onSuccess: (response) => {
        //console.log(response.data);
        setUploadedVideo(response.data);
      },
    }
  );

  useEffect(() => {
    if (uploadedVideo) {
      if (uploadedVideo?.streams?.preferred?.hls?.url) {
        setValue('videoUrl', uploadedVideo?.streams?.preferred?.hls?.url);
      }
      if (uploadedVideo.streams?.thumbnails?.png) {
        setValue('thumbnailUrl', uploadedVideo.streams.thumbnails.png);
      }
      setValue('s3UrlBucket', uploadedVideo.video.s3UrlBucket);
      setValue('s3UrlPrefix', uploadedVideo.video.s3UrlPrefix);
      setValue(
        'thumbnailUrlBucket',
        uploadedVideo.video.thumbnailUrlBucket ?? ''
      );
      setValue(
        'thumbnailUrlPrefix',
        uploadedVideo.video.thumbnailUrlPrefix ?? ''
      );
    }
  }, [setValue, uploadedVideo]); 


  //create new AudioBook Topics

  const [newGuideTopics, setNewGuideTopics] = useState<any>("");

  const createNewGuideTopics = useMutation(
    () => {
      AddNewGuideTopic.current.value = ""

      setNewGuideTopics(newGuideTopics.sort())
      setNewGuideTopics(newGuideTopics.filter((item: any, index: any) => newGuideTopics.indexOf(item) === index))
      return axiosInstance.post(`/v1/webinarTopics` , {
        newWebinarTopics:newGuideTopics
      })
    },
    {
      onSuccess: async (data) => {
        window.location.reload();
      },
    }
  )

  const guideTopicsQuery = useQuery(['guideTopics'], () =>
    axiosInstance.get<AudioBookTopic[]>(`/v1/webinarTopics`)
  );
  const guideTopics = guideTopicsQuery?.data?.data;
  
  const existingTopics = useMemo(() => {
    //@ts-ignore
    return guide?.guideTopics?.map((item: { name: any; }) => item.name);
    //@ts-ignore
  }, [guide?.guideTopics]);

  useEffect(() => {
    if (existingTopics) {
      setSelectedTopics(existingTopics);
    }
  }, [existingTopics]);

  const guidesCategoriesQuery = useQuery(['guideCategories'], () =>
    axiosInstance.get<WebinarCategory[]>(`/v1/webinarCategories`)
  );
  const guideCategories = guidesCategoriesQuery?.data?.data;

  const existingCategories = useMemo(() => {
    return guide?.guideCategories.map((item) => item.name);
  }, [guide?.guideCategories]);

  useEffect(() => {
    if (existingCategories) {
      setSelectedCategories(existingCategories);
    }
  }, [existingCategories]);



  // create a new Guide
  const createGuideMutation = useMutation(
    () => {
      const { modules , overview, title, description , pictureUrlBucket , pictureUrlPrefix ,pictureUrl , mentorId , learnings ,transcript , s3UrlBucket , s3UrlPrefix } = formValues;
      let GuideCuratorId = Number(mentorId)
      
      let GuideLearnings = {}
      GuideLearnings = learnings.split("|")
      let keys = ["data"]
      let learningsobject = {}
      for(let i = 0; i < keys.length; i++){
        learningsobject[keys[i]] = GuideLearnings;
      }

      let GuideModules = {}
      GuideModules = modules.split("|")
      let GuideModuleKey = ["modules"]
      let guideModulesObject:any = {}
      for(let i = 0; i < GuideModuleKey.length; i++){
        guideModulesObject[GuideModuleKey[i]] = GuideModules;
      }

      return axiosInstance.post(`/v1/guides`, {
        title,
        description,
        topics: selectedTopics,
        categories:selectedCategories,
        overview,
        learnings:learningsobject,
        // thumbnailUrl:pictureUrl,
        // thumbnailPrefix:pictureUrlPrefix,
        // thumbnailUrlBucket: pictureUrlBucket,
        curators: GuideCuratorId,
        modules:guideModulesObject.modules,
        guideThemeColorId:randomThemeColorId,
        guideThemeShape
      });
    },
    {
      onSuccess: async (data) => {
        const guide = data.data;
        navigation.push(`/guides/show/${guide.id}`);
      },
      onError: async()=>{
        // window.location.reload();
        // navigation.push(`/AudioBooks/create`);
      }
    },
  );

  // Update an existing AudioBook
  const updateGuideMutation = useMutation(
    () => {
      const { name, fbqPixelId , preHeader } = formValues;
      let webinarMetaDataContent:any;
      return axiosInstance.post(`/v1/webinars`, {
        name,
        preHeader:preHeader,
        fbqPixelId: fbqPixelId || undefined,
        videoDuration: uploadedVideo.video.durationInMilliseconds || undefined,
        videoUuid: uploadedVideo.video.uuid,
        webinarMetadata: webinarMetaDataContent,
      });
    },
    {
      onSuccess: async (data) => {
        const webinar = data.data;
        navigation.push(`/webinars/show/${webinar.id}`);
      },
      onError: async()=>{
        window.location.reload();
        navigation.push(`/webinars/create`);
      }
    },
  );

  
  return (
    <OuterContainer>
      <form autoComplete='off'
        onSubmit={handleSubmit(async (data) => {
          if (guide) {
            updateGuideMutation.mutate();
          } else {
            createGuideMutation.mutate();
          }
        })}
      >
        <ItemsContainer>
        <div className="mt-8 space-y-4">
          {!guide &&
            <>
            <div>
            <label htmlFor="TopicInput" className="my-label">
                Select Guide Topics
              </label>
              <select
                className="my-input mt-[12px]"
                value={''}
                defaultValue=""
                onChange={(e) => {
                  const newTopic = e.target.value;
                  if (!selectedTopics.includes(newTopic)) {
                    setSelectedTopics([...selectedTopics, newTopic]);
                  }
                }}
              >
              <option value={''} disabled>
                Please select
              </option>
              {guideTopics?.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
              </select>
                <br/>
              <div className="flex gap-x-2">
                {selectedTopics?.map((item) => {
                  return (
                    <CategoryPill key={item}>
                      <p>{item}</p>
                      <div
                        onClick={() =>
                          setSelectedTopics((prev) => {
                            return prev.filter((i) => i !== item);
                          })
                        }
                        className="w-[16px] cursor-pointer"
                      >
                        {CrossIcon}
                      </div>
                    </CategoryPill>
                  );
                })}
              </div>

              <button
                    type="button"
                    className="px-1 py-2 my-6 border-2 border-purple-400 rounded-md hover:bg-purple-200" onClick={()=>{setshowAddNewTopicsInput(!showAddNewTopicsInput)}}
                  >
                    Create New Topics
              </button>

              {showAddNewTopicsInput  &&
               <div className='pt-[20px]'>
                <label htmlFor="preHeader" className="my-label">
                  Enter Topics (Comma , seperate multiple topics)
                </label>
                
                <input
                  ref={AddNewGuideTopic}
                  type="text"
                  id="guideTopics"
                  className="my-input"
                  placeholder="Growth Hacking"
                  autoComplete='off'
                  onChange={(e)=>{
                    let newGuideTopics = e.target.value;
                    console.log(newGuideTopics)
                    if(newGuideTopics.length <= 3){
                      newGuideTopics = newGuideTopics.toUpperCase()
                      let finalGuideTopics = []
                      finalGuideTopics = newGuideTopics.split("?")
                      setNewGuideTopics(finalGuideTopics)
                    }
                    else{
                      let newGuideTopicsArray = newGuideTopics.split(" ")
                      for(let i=0; i<newGuideTopicsArray.length; i++){
                        newGuideTopicsArray[i] = newGuideTopicsArray[i].charAt(0).toUpperCase()+ newGuideTopicsArray[i].slice(1);
                      }
                      let finalGuideTopics = []
                      finalGuideTopics = newGuideTopicsArray.toString().replaceAll(","," ").split("?")
                      setNewGuideTopics(finalGuideTopics)
                      }
                  }}
                />
                <button
                  type="button"
                  className="px-1 py-2 my-6 border-2 border-purple-400 rounded-md hover:bg-purple-200" 
                  onClick={()=>{createNewGuideTopics.mutate()}}
                >
                  Add Topics
                </button>
              </div>
             } 
          </div>

          <div className="mt-8 space-y-4">
            <div>
              <label htmlFor="mentorId" className="my-label">
                Select Mentor
              </label>
              <input
                id="mentorId"
                list="mentors"
                name="mentorId"
                className="my-input"
                defaultValue={''}
                {...register('mentorId', { required: true })}
              />
              <datalist id="mentors">
                <option value={''} disabled>
                  Please select
                </option>
                {options?.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </datalist>
              </div>
           </div>
           </>
           }

           {/* Random Generation of Guide Theme Color & Guide Theme Shape */}
           <div>
            {/* <label htmlFor="title" className="my-label">Guide Theme Colors</label>

            <h3>{randomThemeColorId}</h3>
            <img src={guideThemeShape} alt="Guide Thumbnail" /> */}
            {/* <input
              {...register('title', { required: true })}
              className="my-input"  type="text" id="title" placeholder='Enter Title'
            /> */}
          </div>

          <div>
            <label htmlFor="title" className="my-label">Title</label>
            <input
              {...register('title', { required: true })}
              className="my-input"  type="text" id="title" placeholder='Enter Title'
            />
          </div>

          <div className=''>
            <label className="my-label">Description</label>
            <input
              {...register('description', { required: true })}
              className="my-input"
            />
          </div>

          <div>
            <label htmlFor="categoryInput" className="my-label">
              Guide Category
            </label>

            <div className="flex gap-x-2">
              {selectedCategories?.map((item) => {
                return (
                  <CategoryPill key={item}>
                    <p>{item}</p>
                    <div
                      onClick={() =>
                        setSelectedCategories((prev) => {
                          return prev.filter((i) => i !== item);
                        })
                      }
                      className="w-[16px] cursor-pointer"
                    >
                      {CrossIcon}
                    </div>
                  </CategoryPill>
                );
              })}
            </div>

            <select
              className="my-input mt-[12px]"
              value={''}
              defaultValue=""
              onChange={(e) => {
                const newCategory = e.target.value;
                if (!selectedCategories.includes(newCategory)) {
                  setSelectedCategories([...selectedCategories, newCategory]);
                }
              }}
            >
              <option value={''} disabled>
                Please select
              </option>
              {guideCategories?.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
            </select>
          </div>

          <div>
            <label className="my-label">Overview of Guide</label>
            <input
              {...register('overview', { required: true })}
              className="my-input"
            />
          </div>

          {!guide && <div>
            <label className="my-label">Enter Learnings (Minimum 3 - Seperate them with | )</label>
            <input
              {...register('learnings', { required: true })}
              className="my-input"
              placeholder='Master the Art of Advertising|Mastering Growth Hacking|Scale your Performance Marketing Campaigns'
            />
          </div>}

          <div>
            <label className="my-label">Create Modules (Seperate them with | )</label>
            <input
              {...register('modules', { required: true })}
              className="my-input"
              placeholder='Module 1|Module 2|Module 3'
            />
          </div>
        </div>
        <br/>
          <SaveButton type="submit">
            {formLoading && LoadingIcon}
            <span>Save</span>
          </SaveButton>
      </ItemsContainer>
      </form>
      <MutationMessage mutation={createGuideMutation} />
      <MutationMessage mutation={updateGuideMutation} />
    </OuterContainer>
  );
};
