import { useNavigation, useSelect } from '@pankod/refine-core';
import { useForm } from '@pankod/refine-react-hook-form';
import axiosInstance from 'axiosInstance';
import { SeriesBoosters, SeriesTopics } from 'interfaces/apiTypes';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import CategoryPill from 'components/CategoryPill';
import FileUpload from 'components/FileUpload';
import { CrossIcon, LoadingIcon } from 'components/icons';
import InputErrorMessage from 'components/InputErrorMessage';
import ItemsContainer from 'components/ItemsContainer';
import MutationMessage from 'components/Messages/MutationMessage';
import OuterContainer from 'components/OuterContainer';
import SaveButton from 'components/SaveButton';
import ThumbnailUpload from 'components/ThumbnailUpload';

export const CreateSeries: React.FC = () => {
  const {
    refineCore: { formLoading },
    register,
    handleSubmit: handleSeriesSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = useForm();

  const formValues = watch();

  const navigation = useNavigation();

  const collectionForm = useForm();
  const bitesForm = useForm();
  const [uploadedVideo, setUploadedVideo] = useState<any>();
  const [selectedBoosters, setSelectedBoosters] = useState<string[]>([]);
  const [selectedSeries, setSelectedSeries] = useState<string[]>([]);
  const [selectedBites, setSelectedBites] = useState<string[]>([]);
  const [selectedTopics, setSelectedTopics] = useState<string[]>([]);
  const [selectedNumber, setSelectedNumber] = useState<number>(1);
  const [uploadedVideos, setUploadedVideos] = useState<any[]>([]);
  const [showAddNewTopicInput, setShowAddNewTopicInput] = useState(false);
  const newTopicInputRef = useRef<HTMLInputElement>(null);
  const queryClient = useQueryClient();

  const seriesBoostersQuery = useQuery(['seriesBoosters'], () =>
    axiosInstance.get<SeriesBoosters[]>(`/v1/series-boosters`)
  );
  const seriesBoosters = seriesBoostersQuery?.data?.data;

  const seriesTopicsQuery = useQuery(['seriesTopics'], () =>
    axiosInstance.get<SeriesTopics[]>(`/v1/series-topics`)
  );
  const seriesTopics = seriesTopicsQuery?.data?.data;

  const seriesQuery = useQuery(['series'], () =>
    axiosInstance.get<SeriesTopics[]>(`/v1/series`)
  );
  const series = seriesQuery?.data?.data;

  const bitesQuery = useQuery(['bites'], () =>
    axiosInstance.get<SeriesTopics[]>(`/v1/series/bites`)
  );
  const bites = bitesQuery?.data?.data;

  useEffect(() => {
    (async () => {
      if (formValues.videoUuid) {
        // in case of editing
        // with the video id we need to populate selected video
        const videoResult = await axiosInstance.get(
          `${process.env.REACT_APP_CBC_SERVER}/videos/${formValues.videoUuid}`,
          {
            headers: {
              Authorization: process.env.REACT_APP_CBC_TOKEN,
            },
          }
        );
        setUploadedVideo(videoResult.data);
      }
    })();
  }, [formValues.videoUuid]);
  const uploadVideoFromS3ToVideoContentProvidersMutation = useMutation(
    ({
      s3UrlBucket,
      s3UrlPrefix,
      isMuxRequired,
      index,
    }: {
      s3UrlBucket: string;
      s3UrlPrefix: string;
      isMuxRequired: boolean;
      index: number;
    }) => {
      return axiosInstance.post(
        `${process.env.REACT_APP_CBC_SERVER}/videos`,
        {
          s3UrlBucket,
          s3UrlPrefix,
          isMuxRequired,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_CBC_TOKEN,
          },
        }
      );
    },
    {
      onSuccess: (response, variables) => {
        setUploadedVideos((prev) => {
          const newVideos = [...prev];
          newVideos[variables.index] = {
            ...response.data,
            title: watch(`title-${variables.index}`),
            sequenceNumber: variables.index + 1,
          };

          return newVideos;
        });
      },
    }
  );

  useEffect(() => {
    if (uploadedVideo) {
      if (uploadedVideo?.streams?.preferred?.hls?.url) {
        setValue('videoUrl', uploadedVideo?.streams?.preferred?.hls?.url);
      }
      if (uploadedVideo.streams?.thumbnails?.png) {
        setValue('thumbnailUrl', uploadedVideo.streams.thumbnails.png);
      }
      setValue('s3UrlBucket', uploadedVideo.video.s3UrlBucket);
      setValue('s3UrlPrefix', uploadedVideo.video.s3UrlPrefix);
      setValue(
        'thumbnailUrlBucket',
        uploadedVideo.video.thumbnailUrlBucket ?? ''
      );
      setValue(
        'thumbnailUrlPrefix',
        uploadedVideo.video.thumbnailUrlPrefix ?? ''
      );
    }
  }, [setValue, uploadedVideo]);

  //create new Webinar Topics
  const createNewTopicMutation = useMutation(
    (newTopic: string) =>
      axiosInstance.post('/v1/series-topics', { name: newTopic }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('seriesTopics');
        if (newTopicInputRef.current) {
          newTopicInputRef.current.value = '';
        }
        setShowAddNewTopicInput(false);
      },
    }
  );

  const handleCreateNewTopic = () => {
    const newTopic = newTopicInputRef.current?.value.trim();
    if (newTopic) {
      createNewTopicMutation.mutate(newTopic);
    }
  };

  // create a new series
  const createSeriesMutation = useMutation(
    () => {
      const { name, description, thumbnailUrlBucket, thumbnailPrefix } =
        formValues;

      let thumbnailUrlLink = `https://${thumbnailUrlBucket}/${thumbnailPrefix}`;

      return axiosInstance.post(`/v1/series`, {
        name,
        description,
        thumbnailUrl: thumbnailUrlLink,
        boosters: selectedBoosters,
        topics: selectedTopics,
        videos: uploadedVideos,
      });
    },
    {
      onSuccess: async (data) => {
        // Handle success
      },
      onError: async () => {
        // Handle error
      },
    }
  );

  const createCollectionMutation = useMutation(
    () => {
      const { name, description } = collectionForm.getValues();

      console.log(name, description, selectedSeries);

      return axiosInstance.post(`/v1/series/create-collection`, {
        name,
        description,
        series: selectedSeries,
      });
    },
    {
      onSuccess: async (data) => {
        // Handle success
      },
      onError: async () => {
        // Handle error
      },
    }
  );

  const createBiteMutation = useMutation(
    () => {
      const { biteTitle, bitesS3UrlPrefix } = bitesForm.getValues();

      return axiosInstance.post(`/v1/series/bites`, {
        name: biteTitle,
        videoUuid: bitesS3UrlPrefix,
      });
    },
    {
      onSuccess: async (data) => {},
      onError: async () => {},
    }
  );

  const isCreateCollectionDisabled =
    createCollectionMutation.isLoading || selectedSeries.length < 3;

  return (
    <OuterContainer>
      <form
        onSubmit={handleSeriesSubmit(async (data) => {
          const isValid = await trigger();
          if (isValid) {
            createSeriesMutation.mutate();
          }
        })}
      >
        <ItemsContainer>
          <div className="space-y-4">
            <h2 className="text-2xl font-bold text-gray-800">
              Create New Series
            </h2>

            <div>
              <label htmlFor="TopicInput" className="my-label">
                Select Series Topics
              </label>

              <select
                className="my-input mt-[12px]"
                value={''}
                defaultValue=""
                onChange={(e) => {
                  const newTopic = e.target.value;
                  if (!selectedTopics.includes(newTopic)) {
                    setSelectedTopics([...selectedTopics, newTopic]);
                  }
                }}
              >
                <option value={''} disabled>
                  Please select
                </option>
                {seriesTopics?.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </select>

              <br></br>

              <div className="flex gap-x-2">
                {selectedTopics?.map((item) => {
                  // Changed from seriesTopics to selectedTopics
                  return (
                    <CategoryPill key={item}>
                      <p>{item}</p>
                      <div
                        onClick={() =>
                          setSelectedTopics((prev) => {
                            return prev.filter((i) => i !== item);
                          })
                        }
                        className="w-[16px] cursor-pointer"
                      >
                        {CrossIcon}
                      </div>
                    </CategoryPill>
                  );
                })}
              </div>

              <button
                type="button"
                className="px-1 py-2 my-6 border-2 border-purple-400 rounded-md hover:bg-purple-200"
                onClick={() => setShowAddNewTopicInput(!showAddNewTopicInput)}
              >
                Create New Topic
              </button>

              {showAddNewTopicInput && (
                <div className="pt-[20px]">
                  <label htmlFor="newTopic" className="my-label">
                    Enter New Topic
                  </label>
                  <input
                    ref={newTopicInputRef}
                    type="text"
                    id="newTopic"
                    className="my-input"
                    placeholder="e.g., Growth Hacking"
                    autoComplete="off"
                  />
                  <button
                    type="button"
                    className="px-1 py-2 my-6 border-2 border-purple-400 rounded-md hover:bg-purple-200"
                    onClick={handleCreateNewTopic}
                    disabled={createNewTopicMutation.isLoading}
                  >
                    {createNewTopicMutation.isLoading
                      ? 'Adding...'
                      : 'Add Topic'}
                  </button>
                  {createNewTopicMutation.isError && (
                    <p className="text-red-500">
                      Error adding new topic. Please try again.
                    </p>
                  )}
                </div>
              )}
            </div>

            <label htmlFor="name" className="my-label">
              Series Name
            </label>
            <input
              {...register('name', { required: true })}
              type="text"
              id="name"
              className="my-input"
              placeholder="Name"
              autoComplete="off"
            />
            {errors.name && (
              <InputErrorMessage>
                <span className="font-medium">Oops!</span> This field is
                required
              </InputErrorMessage>
            )}
          </div>
          <div>
            <label htmlFor="description" className="my-label">
              Series Description
            </label>
            <input
              {...register('description', { required: true })}
              type="text"
              id="description"
              className="my-input"
              placeholder="Enter Series Description"
              autoComplete="off"
            />
          </div>

          <div>
            <div>
              <label htmlFor="boosterInput" className="my-label">
                Select Series Booster
              </label>

              <div className="flex gap-x-2">
                {selectedBoosters?.map((item) => {
                  return (
                    <CategoryPill key={item}>
                      <p>{item}</p>
                      <div
                        onClick={() =>
                          setSelectedBoosters((prev) => {
                            return prev.filter((i) => i !== item);
                          })
                        }
                        className="w-[16px] cursor-pointer"
                      >
                        {CrossIcon}
                      </div>
                    </CategoryPill>
                  );
                })}
              </div>

              <select
                className="my-input mt-[12px]"
                value={''}
                defaultValue=""
                onChange={(e) => {
                  const newBooster = e.target.value;
                  if (!selectedBoosters.includes(newBooster)) {
                    setSelectedBoosters([...selectedBoosters, newBooster]);
                  }
                }}
              >
                <option value={''} disabled>
                  Please select
                </option>
                {seriesBoosters?.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <label htmlFor="thumbnailUrlBucket" className="my-label">
              Thumbnail Bucket
            </label>
            <input
              {...register('thumbnailUrlBucket')}
              type="text"
              id="thumbnailUrlBucket"
              className="my-input"
              placeholder="assets.growthschool.io"
            />
          </div>
          <div>
            <label htmlFor="thumbnailPrefix" className="my-label">
              Thumbnail Key
            </label>
            <input
              {...register('thumbnailPrefix')}
              type="text"
              id="thumbnailPrefix"
              className="my-input"
              placeholder="167ce2b0-50da-47c9-9f6a-37bbadaea18c"
            />
          </div>
          <div>
            <p className="text-xl mt-4 text-center">OR</p>
            <div>
              <ThumbnailUpload
                acceptedFileType="image"
                finalFileUrl={''}
                thumbnailType="webinar-thumbnail"
                setFinalFileUrl={(presignedPost) => {
                  if (presignedPost) {
                    setValue(
                      'thumbnailUrlBucket',
                      presignedPost.fields['bucket']
                    );
                    setValue('thumbnailPrefix', presignedPost.fields['Key']);
                    setValue(
                      'pictureUrl',
                      `https://assets.growthschool.io/${presignedPost.fields['Key']}`
                    );
                  }
                }}
              />
            </div>
            {errors.thumbnailUrl && (
              <InputErrorMessage>
                <span className="font-medium">Oops!</span> This field is
                required
              </InputErrorMessage>
            )}
          </div>

          <div>
            <label htmlFor="numberSelector" className="my-label">
              Select Number of Bites
            </label>
            <select
              id="numberSelector"
              className="my-input"
              value={selectedNumber}
              onChange={(e) => {
                const newNumber = Number(e.target.value);
                setSelectedNumber(newNumber);
                // Initialize or trim uploadedVideos array based on new number
                setUploadedVideos((prev) => {
                  const newVideos = [...prev];
                  newVideos.length = newNumber;
                  return newVideos;
                });
              }}
            >
              {[...Array(51)].map((_, i) => (
                <option key={i} value={i}>
                  {i}
                </option>
              ))}
            </select>
          </div>

          {[...Array(selectedNumber)].map((_, index) => (
            <div key={index} className="border-t pt-4 mt-4">
              <h3 className="text-lg font-semibold mb-2">Bite {index + 1}</h3>
              <div className="space-y-4">
                {/* Updated field names with index */}
                <div>
                  <label htmlFor={`s3UrlBucket-${index}`} className="my-label">
                    Video Bucket
                  </label>
                  <input
                    {...register(`s3UrlBucket-${index}`, { required: true })}
                    type="text"
                    id={`s3UrlBucket-${index}`}
                    className="my-input"
                    placeholder="assets.growthschool.io"
                  />
                  {errors[`s3UrlBucket-${index}`] && (
                    <InputErrorMessage>
                      This field is required
                    </InputErrorMessage>
                  )}
                </div>
                <div>
                  <label htmlFor={`s3UrlPrefix-${index}`} className="my-label">
                    Video Prefix
                  </label>
                  <input
                    {...register(`s3UrlPrefix-${index}`, { required: true })}
                    type="text"
                    id={`s3UrlPrefix-${index}`}
                    className="my-input"
                    placeholder="167ce2b0-50da-47c9-9f6a-37bbadaea18c"
                  />
                  {errors[`s3UrlPrefix-${index}`] && (
                    <InputErrorMessage>
                      This field is required
                    </InputErrorMessage>
                  )}
                </div>

                <div>
                  <FileUpload
                    acceptedFileType="video"
                    finalFileUrl={formValues.videoUrl}
                    setFinalFileUrl={async (presignedPost) => {
                      console.log('presignedPost', presignedPost);
                      if (presignedPost) {
                        // Update the corresponding indexed fields
                        setValue(
                          `s3UrlBucket-${index}`,
                          presignedPost.fields['bucket']
                        );
                        setValue(
                          `s3UrlPrefix-${index}`,
                          presignedPost.fields['Key']
                        );
                      }
                    }}
                  />
                </div>
                <div>
                  <button
                    type="button"
                    className="px-1 py-2 my-6 border-2 border-purple-400 rounded-md hover:bg-purple-200"
                    onClick={() => {
                      const s3UrlBucket = watch(`s3UrlBucket-${index}`);
                      const s3UrlPrefix = watch(`s3UrlPrefix-${index}`);
                      uploadVideoFromS3ToVideoContentProvidersMutation.mutate({
                        s3UrlBucket,
                        s3UrlPrefix,
                        isMuxRequired: false,
                        index,
                      });
                    }}
                  >
                    Confirm Video
                  </button>
                  <MutationMessage
                    mutation={uploadVideoFromS3ToVideoContentProvidersMutation}
                  />
                </div>
                <div>
                  <label htmlFor={`title-${index}`} className="my-label">
                    Bite Title
                  </label>
                  <input
                    {...register(`title-${index}`, {
                      required: 'Bite title is required',
                      onChange: (e) => {
                        setUploadedVideos((prev) => {
                          const newVideos = [...prev];
                          if (newVideos[index]) {
                            newVideos[index].title = e.target.value;
                          }
                          return newVideos;
                        });
                      },
                    })}
                    type="text"
                    id={`title-${index}`}
                    className="my-input"
                    placeholder="Enter video title"
                  />
                  {errors[`title-${index}`] && (
                    <InputErrorMessage>
                      {errors[`title-${index}`].message}
                    </InputErrorMessage>
                  )}
                </div>
                <div>
                  <label htmlFor={`sequence-${index}`} className="my-label">
                    Bite Sequence Number
                  </label>
                  <input
                    {...register(`sequence-${index}`)}
                    type="number"
                    id={`sequence-${index}`}
                    className="my-input"
                    value={index + 1}
                    readOnly
                  />
                </div>
              </div>
            </div>
          ))}

          <SaveButton type="submit">
            {formLoading && LoadingIcon}
            <span>Save</span>
          </SaveButton>
        </ItemsContainer>
      </form>
      <MutationMessage mutation={createSeriesMutation} />
      {/* <MutationMessage mutation={updateWebinarMutation} /> */}

      {/* Collection Creation Form */}

      <div className="mt-12 border-t pt-8">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          Create New Collection
        </h2>

        <form
          onSubmit={collectionForm.handleSubmit((data) => {
            createCollectionMutation.mutate();
          })}
          className="mt-4 space-y-4"
        >
          <div>
            <label htmlFor="name" className="my-label">
              Collection Name
            </label>
            <input
              {...collectionForm.register('name', { required: true })}
              type="text"
              id="name"
              className="my-input"
              placeholder="Name"
              autoComplete="off"
            />
            {collectionForm.formState.errors.name && (
              <InputErrorMessage>
                <span className="font-medium">Oops!</span> This field is
                required
              </InputErrorMessage>
            )}
          </div>

          <div>
            <label htmlFor="description" className="my-label">
              Collection Description
            </label>
            <input
              {...collectionForm.register('description', { required: true })}
              type="text"
              id="description"
              className="my-input"
              placeholder="Enter Collection Description"
              autoComplete="off"
            />
            {collectionForm.formState.errors.description && (
              <InputErrorMessage>
                <span className="font-medium">Oops!</span> This field is
                required
              </InputErrorMessage>
            )}
          </div>

          <div>
            <label htmlFor="TopicInput" className="my-label">
              Select Series
            </label>
            <select
              className="my-input mt-[12px]"
              value={''}
              defaultValue=""
              onChange={(e) => {
                const newSeries = e.target.value;
                if (!selectedSeries.includes(newSeries)) {
                  setSelectedSeries([...selectedSeries, newSeries]);
                }
              }}
            >
              <option value={''} disabled>
                Please select
              </option>
              {series?.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}-{option.id}
                </option>
              ))}
            </select>

            <br />

            <div className="flex gap-x-2">
              {selectedSeries?.map((item) => {
                return (
                  <CategoryPill key={item}>
                    <p>{item}</p>
                    <div
                      onClick={() =>
                        setSelectedSeries((prev) => {
                          return prev.filter((i) => i !== item);
                        })
                      }
                      className="w-[16px] cursor-pointer"
                    >
                      {CrossIcon}
                    </div>
                  </CategoryPill>
                );
              })}
            </div>
          </div>

          {/* Conditional Message */}
          {selectedSeries.length < 3 && (
            <p className="text-red-500">
              A collection should have a minimum of 3 series.
            </p>
          )}

          {/* Updated SaveButton with Conditional Styling */}
          <SaveButton
            type="submit"
            disabled={isCreateCollectionDisabled}
            className={
              isCreateCollectionDisabled
                ? 'flex w-full rounded-lg bg-gray-500 px-5 py-2.5 text-center text-white sm:w-auto'
                : 'flex w-full items-center rounded-lg bg-indigo-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-indigo-600 sm:w-auto'
            }
          >
            {createCollectionMutation.isLoading
              ? 'Creating...'
              : 'Create Collection'}
          </SaveButton>
        </form>
        <MutationMessage mutation={createCollectionMutation} />
      </div>
    </OuterContainer>
  );
};
